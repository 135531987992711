import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

function SuccessModals({ successModal, showSuccessModal, order_id }) {
  const { ID } = useParams();
  const navigate = useNavigate();

  const handleHome = () => {
    sessionStorage.removeItem('cartItems');
    sessionStorage.removeItem('order_details');
    sessionStorage.removeItem('orderPostData');
    sessionStorage.removeItem('DineInSkip');
    sessionStorage.removeItem('currentOrderID');
    sessionStorage.removeItem('razorpayOrderDetails');
    navigate(`/restaurant/${ID}`);
  };

  const handleTrack = () => {
    showSuccessModal(false);
    sessionStorage.removeItem('cartItems');
    sessionStorage.removeItem('order_details');
    sessionStorage.removeItem('orderPostData');
    sessionStorage.removeItem('DineInSkip');
    sessionStorage.removeItem('currentOrderID');
    sessionStorage.removeItem('razorpayOrderDetails');
    navigate(`/restaurant/${ID}/ordertrack/${order_id}`);
  };

  return (
    <Dialog
      open={successModal}
      aria-labelledby="order-success-dialog-title"
      aria-describedby="order-success-dialog-description"
    >
      <DialogTitle id="order-success-dialog-title" sx={{ textAlign: 'center' }}>
        Your order has been successfully created.
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', p: 0 }}>
        <div className="d-flex justify-content-around p-4">
          <button
            className="btn btn-outline-secondary px-1"
            style={{ minWidth: '100px', borderRadius: '18px' }}
            onClick={handleHome}
          >
            Home
          </button>
          <button
            className="btn btn-danger px-1"
            style={{ minWidth: '100px', borderRadius: '18px' }}
            onClick={handleTrack}
          >
            Track
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessModals;
