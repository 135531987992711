import { Box, Button, Divider, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TitleSection from '../ReusableComponent/TitleSection';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { URL } from '../../common/api/urls';
import { useAxios } from '../../common/api/hooks/useAxios';
import { assets } from '../../assets/assets';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import CustomDatePicker from '../../components/datePickers/CustomDatePicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFormik } from 'formik';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  discountedGroupBook,
  getExperienceTaxes,
} from '../../common/utility/expFunctions';
import { isEmpty } from '../../common/utility/helperFunc';
import UserRegistration from '../ReusableComponent/UserRegistration';

const errorMessage =
  'Sorry! Unable to proceed with your booking as there are no time slots available for this day. Please try to book for another day.';
const errorMessageTwo = 'Maximum limit exceeded!';
// const errorMessageThree = "Seats are not available";
const errorMessageFour = 'No more seats are available';

function SelectPass({
  experienceData,
  formik,
  onIncrementHandler,
  onDecrementHandler,
  activeTab,
}) {
  return (
    <Box component="section" px={2} py={1}>
      <div className="d-flex align-items-center">
        <Image
          src={require('../../assets/images/ExperiencePageAssets/concert-ticket.png')}
          alt={'Ticket'}
          title="Ticket"
          width={28}
        />
        <div className="ml-2 f16-700">Select Pass </div>
      </div>
      <div className="d-flex f10-500 text-grey align-items-center my-2">
        <InfoOutlinedIcon sx={{ fontSize: '18px' }} /> The Kids ranging below 6
        years will be kept under the kids category
      </div>
      <div className="f12-500 my-2">
        Maximum No. of people allowed per ticket :{experienceData?.maxSeats}
      </div>
      <Box
        component="section"
        sx={{
          border: '1px solid #8771DF',
          borderRadius: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: assets.colors.backgroundPeach,
        }}
        p={1}
      >
        <Box className="d-flex">
          <Image
            src={require('../../assets/images/ExperiencePageAssets/family.png')}
            width={36}
            height={36}
            alt="adult"
            style={{ backgroundColor: 'whitesmoke', borderRadius: '50%' }}
            className="p-1"
          />
          <div className="px-2">
            <div className="f14-700"> Adult</div>
            <div className="f14-700 my-1">
              ₹ {experienceData?.adultPrice} /
              <span className="f12-500 text-grey"> per person</span>{' '}
            </div>
            <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
              <div
                className="col-4 f16-700 p-0"
                onClick={() => onDecrementHandler('Adult', formik.values)}
              >
                -
              </div>
              <div className="col-4 f14-700 p-0 text-center">
                {formik.values.adultCount || 0}
              </div>
              <div
                className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                onClick={() => onIncrementHandler('Adult', formik.values)}
              >
                +
              </div>
            </div>
          </div>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            borderColor: assets.colors.textIndigo,
          }}
        />
        <Box className="d-flex">
          <Image
            src={require('../../assets/images/ExperiencePageAssets/kids.png')}
            width={36}
            height={36}
            alt="adult"
            style={{ backgroundColor: 'whitesmoke', borderRadius: '50%' }}
            className="p-1"
          />
          <div className="px-2">
            <div className="f14-700"> Kids</div>
            <div className="f14-700 my-1">
              ₹ {experienceData?.kidsPrice} /
              <span className="f12-500 text-grey"> per person</span>{' '}
            </div>
            <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
              <div
                className="col-4 f16-700 p-0"
                onClick={() => onDecrementHandler('Kids', formik.values)}
              >
                -
              </div>
              <div className="col-4 f14-700 p-0 text-center">
                {formik.values.kidsCount}
              </div>
              <div
                className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                onClick={() => onIncrementHandler('Kids', formik.values)}
              >
                +
              </div>
            </div>
          </div>
        </Box>
      </Box>
      {formik.errors.adultCount && formik.touched.adultCount && (
        <div className="f12-500" style={{ color: assets.colors.primary }}>
          {formik.errors.adultCount}
        </div>
      )}
      {activeTab === 1 && (
        <div className="f12-500" style={{ color: assets.colors.primary }}>
          You cannot combine packages with the single pass
        </div>
      )}
      {discountedGroupBook(experienceData) && (
        <div className="f12-500 my-2">
          {' '}
          If your party size is more than the alowed limit per ticket , we
          recommend you to proceed with the package offered
        </div>
      )}
    </Box>
  );
}

function Packages({
  experienceData,
  formik,
  packageData,
  onIncrementHandler,
  onDecrementHandler,
  displayPackage,
  setDisplayPackage,
  extAdult,
  extKids,
  activeTab,
}) {
  return (
    <Box component="section" px={2}>
      <div className="d-flex align-items-center my-2">
        <Image
          src={require('../../assets/images/ExperiencePageAssets/concert-ticket.png')}
          alt={'Ticket'}
          title="Ticket"
          width={28}
        />
        <div className="ml-2 f16-700">Packages</div>
      </div>

      <Box
        component="section"
        sx={{
          border: '1px solid #8771DF',
          borderRadius: '16px',
          //display: 'flex',
          backgroundColor: assets.colors.backgroundPeach,
        }}
        p={1}
      >
        <Box className="d-flex">
          <div className="mr-3">
            <Image
              src={require('../../assets/images/ExperiencePageAssets/family.png')}
              width={36}
              height={36}
              alt="adult"
              style={{ backgroundColor: 'whitesmoke', borderRadius: '50%' }}
              className="p-1"
            />
          </div>
          <div className="mx-2 w-100">
            <div className="f14-700 row"> {packageData?.name}</div>
            <div className="f12-500  text-grey my-1 row">
              No of people included in the package : {packageData?.adults}{' '}
              Adults,
              {packageData?.kids} kids
            </div>
            <div className="d-flex row my-2">
              <div className="f14-700 col-7 my-1 nowrap">
                {packageData?.discountedPrice && (
                  <span
                    className="px-2"
                    style={{ textDecoration: 'line-through', opacity: 0.5 }}
                  >
                    {' '}
                    ₹ {packageData?.price}
                  </span>
                )}
                ₹{' '}
                {packageData?.discountedPrice
                  ? packageData?.discountedPrice
                  : packageData?.price}{' '}
                /<span className="f10-500 text-grey"> per Package</span>{' '}
              </div>
              <div className="col-5">
                <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
                  <div
                    className="col-4 f16-700 p-0"
                    onClick={() =>
                      onDecrementHandler('package', formik.values, packageData)
                    }
                  >
                    -
                  </div>
                  <div className="col-4 f14-700 p-0 text-center">
                    {packageData.name === formik.values?.package?.name
                      ? formik.values.package.count
                      : 0}
                  </div>
                  <div
                    className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                    onClick={() =>
                      onIncrementHandler('package', formik.values, packageData)
                    }
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            {formik.values.package?.count > 0 &&
              packageData.name === formik.values?.package?.name &&
              packageData?.seatCustomized && (
                <div>
                  <Divider
                    color={assets.colors.borderIndigo}
                    sx={{ my: 2, borderColor: assets.colors.borderIndigo }}
                  />
                  <div className="d-flex">
                    <div>
                      <AddCircleIcon
                        color="primary"
                        mx={2}
                        onClick={() => setDisplayPackage(!displayPackage)}
                      />
                    </div>
                    <div className="mx-2">
                      <div
                        className="f14-700"
                        style={{ color: assets.colors.defaultBlue }}
                        onClick={() => setDisplayPackage(!displayPackage)}
                      >
                        {' '}
                        Add More People to the package
                      </div>
                      <div className="f12-500 text-grey">
                        You can customise the PAX details
                      </div>
                    </div>
                  </div>
                  {displayPackage
                    ? packageData.name === formik.values?.package?.name && (
                        <>
                          <div className="d-flex my-2">
                            <div className="px-2">
                              <div className="f14-700"> Adult</div>
                              <div className="f14-700 my-1">
                                ₹ {experienceData?.adultPrice} /
                                <span className="f12-500 text-grey">
                                  {' '}
                                  per person
                                </span>{' '}
                              </div>
                              <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
                                <div
                                  className="col-4 f16-700 p-0"
                                  onClick={() =>
                                    onDecrementHandler(
                                      'PkgAdult',
                                      formik.values
                                    )
                                  }
                                >
                                  -
                                </div>
                                <div className="col-4 f14-700 p-0 text-center">
                                  {extAdult || 0}
                                </div>
                                <div
                                  className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                                  onClick={() =>
                                    onIncrementHandler(
                                      'PkgAdult',
                                      formik.values
                                    )
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{
                                borderColor: assets.colors.textIndigo,
                              }}
                            />
                            {!experienceData?.ageRestriction && (
                              <div className="px-3">
                                <div className="f14-700"> Kids</div>
                                <div className="f14-700 my-1">
                                  ₹ {experienceData?.kidsPrice} /
                                  <span className="f12-500 text-grey">
                                    {' '}
                                    per person
                                  </span>{' '}
                                </div>
                                <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
                                  <div
                                    className="col-4 f16-700 p-0"
                                    onClick={() =>
                                      onDecrementHandler(
                                        'PkgKids',
                                        formik.values
                                      )
                                    }
                                  >
                                    -
                                  </div>
                                  <div className="col-4 f14-700 p-0 text-center">
                                    {extKids}
                                  </div>
                                  <div
                                    className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                                    onClick={() =>
                                      onIncrementHandler(
                                        'PkgKids',
                                        formik.values
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )
                    : null}
                </div>
              )}
          </div>
        </Box>
      </Box>
      {formik.errors?.count && (
        <div className="f12-500" style={{ color: assets.colors.primary }}>
          {formik.errors?.count}
        </div>
      )}
      {activeTab === 2 && discountedGroupBook(experienceData) && (
        <div className="f12-500" style={{ color: assets.colors.primary }}>
          You cannot combine single pass with the packages
        </div>
      )}
    </Box>
  );
}

function Highchair({
  experienceData,
  formik,
  onIncrementHandler,
  onDecrementHandler,
  onToggleSwitch,
}) {
  return (
    <Box component={'section'} p={2}>
      <div className="flex-between">
        <div className="f16-500"> Need Handicap Access or a Highchair ?</div>
        <div>
          <Switch
            color="success"
            value={formik.values.highchairAccess}
            onChange={onToggleSwitch}
          />
        </div>
      </div>
      {formik.values.highchairAccess ? (
        <Box
          component="section"
          sx={{
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: assets.colors.backgroundPeach,
          }}
          p={1}
          my={2}
        >
          {' '}
          {experienceData?.handicapAccess && (
            <Box className="d-flex" py={1}>
              <Image
                src={require('../../assets/images/IconsandImages/handicap.png')}
                width={36}
                height={36}
                alt="adult"
                style={{ backgroundColor: 'whitesmoke', borderRadius: '50%' }}
                className="p-1"
              />

              <div className="px-2">
                <div className="f14-700 my-1"> Handicap</div>

                <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
                  <div
                    className="col-4 f16-700 p-0"
                    onClick={() =>
                      onDecrementHandler('Handicap', formik.values)
                    }
                  >
                    -
                  </div>
                  <div className="col-4 f14-700 p-0 text-center">
                    {formik.values.handicapCount}
                  </div>
                  <div
                    className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                    onClick={() =>
                      onIncrementHandler('Handicap', formik.values)
                    }
                  >
                    +
                  </div>
                </div>
              </div>
            </Box>
          )}
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{
              borderColor: assets.colors.textIndigo,
            }}
          />
          {experienceData?.highchairAccess && (
            <Box className="d-flex" py={1}>
              <Image
                src={require('../../assets/images/IconsandImages/highchair.png')}
                width={36}
                height={36}
                alt="adult"
                style={{ backgroundColor: 'whitesmoke', borderRadius: '50%' }}
                className="p-1"
              />
              <div className="px-2">
                <div className="f14-700 my-1"> Highchair</div>

                <div className="paxButtonExp row align-items-center justify-content-between m-0 px-2">
                  <div
                    className="col-4 f16-700 p-0"
                    onClick={() =>
                      onDecrementHandler('Highchair', formik.values)
                    }
                  >
                    -
                  </div>
                  <div className="col-4 f14-700 p-0 text-center">
                    {formik.values.highchairCount}
                  </div>
                  <div
                    className="col-4 f16-700 p-0 d-flex justify-content-end m-0"
                    onClick={() =>
                      onIncrementHandler('Highchair', formik.values)
                    }
                  >
                    +
                  </div>
                </div>
              </div>
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
}

function BookExperience() {
  // const { restaurantId, experienceId } = useParams();
  let getFilledExpData = JSON.parse(sessionStorage.getItem('expBookData'));
  const [expData, setExpData] = useState({});
  const { state } = useLocation();
  const { restaurantId, experienceId, actionBy } = useParams();
  // const [expData, setExpData] = useState({});
  const [bookDate, setBookDate] = useState(
    state?.actionBy === 'PAYMENT_SUMMARY'
      ? moment(getFilledExpData?.reservationDate)
      : moment()
  );
  // const [selectedTime, setSelectedTime] = useState("");
  const [displayPackage, setDisplayPackage] = useState(false);
  const [extAdult, setExtAdult] = useState(0);
  const [extKids, setExtKids] = useState(0);
  const [visible, setVisible] = React.useState(false);
  const [dialogBoxText, setDialogBoxText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [activeDateData, setActiveDateData] = useState();
  const [activeTimeInfo, setActiveTimeInfo] = useState('');
  const [loginModal, showLoginModal] = useState(false);

  const navigate = useNavigate();

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);
  // eslint-disable-next-line
  const [cancel, experienceData, error, loaded, reset, executeAPI] = useAxios();
  const [cancelTS, TSData, TSerror, TSloaded, TSreset, executeTSAPI] =
    useAxios();
  let userDetailsInfo = JSON.parse(sessionStorage.getItem('userDetails'));

  let todayDate =
    moment().format('YYYY-MM-DD') <
    moment(experienceData?.startDate).format('YYYY-MM-DD')
      ? moment(experienceData?.startDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  const [currantDate, setCurrentDate] = useState(
    state?.actionBy === 'PAYMENT_SUMMARY'
      ? getFilledExpData.reservationDate
      : todayDate
  );

  const datepickerDateAction = (date) => {
    let selectedDate = moment(date).format('YYYY-MM-DD');
    setCurrentDate(selectedDate);
    setBookDate(date);
  };

  const timeSlotsAction = (date) => {
    if (experienceData) {
      getTimeSlotsAction(date, experienceData?._id);
    }
  };

  useEffect(() => {
    let dateChangeFormat = moment(currantDate).format('DD-MM-YYYY');
    if (experienceData) {
      timeSlotsAction(currantDate);
      onClickPressDate(dateChangeFormat);
    }
  }, [currantDate, experienceData]);

  const getTimeSlotsAction = (date, expID) => {
    executeTSAPI(`${URL.TIMESLOTS}?date=${date}&expId=${expID}`, 'GET');
  };

  const onClickPressDate = (data) => {
    let timingsData = experienceData?.timings;
    let currentDate = timingsData[data];
    if (currentDate?.open) {
      setActiveDateData(currentDate?.multipleHours);
      setActiveTimeInfo(currentDate?.multipleHours?.[0]?.openingTime);
      let currentTime = moment(
        currentDate?.multipleHours?.[0]?.openingTime
      ).format('hh:mm A');
      formik.setFieldValue('reservationTime', currentTime);
    } else if (isEmpty(currentDate)) {
      let formData = {
        open: true,
        description: 'Not Available',
        closingTime: new Date(),
        openingTime: new Date(),
      };
      let formDataTwo = {
        open: false,
        multipleHours: [formData],
      };
      setActiveTimeInfo('Not Available');
      setActiveDateData(formDataTwo?.multipleHours);
    } else {
      let formData = {
        open: currentDate?.multipleHours?.[0]?.open,
        description: 'Not Available',
        closingTime: currentDate?.multipleHours?.[0]?.closingTime,
        openingTime: currentDate?.multipleHours?.[0]?.openingTime,
      };
      setActiveTimeInfo('Not Available');
      currentDate.multipleHours = [formData];
      setActiveDateData(currentDate?.multipleHours);
    }
  };

  const onSubTotalValue = (data) => {
    let formData = data;
    let subTotalAmount = 0;
    let totalAdultCount = formData.adultCount;
    let totalKidsCount = formData.kidsCount;
    let totalPkgCount = formData.package?.count;
    let pkgAmount = formData.package?.price;
    let tAdultAmount = totalAdultCount * experienceData?.adultPrice;
    let tKidsAmount = totalKidsCount * experienceData?.kidsPrice;
    let finalAmount = tAdultAmount + tKidsAmount;
    if (!formData.isPackagesActive) {
      subTotalAmount = finalAmount;
    } else {
      let getTotalPackageAmount = pkgAmount * totalPkgCount;
      subTotalAmount = finalAmount + getTotalPackageAmount;
    }
    return subTotalAmount;
  };

  const validate = (values) => {
    let minValue = experienceData?.minSeats;
    let totalAdultKidsCount =
      formik.values.adultCount + formik.values.kidsCount;
    const errors = {};
    if (values.reservationTime === '') {
      errors.reservationTime = 'Select time is required';
    } else if (values.reservationTime === 'Select time is required') {
      errors.reservationTime = 'Select time is required';
    }
    if (values.isPackagesActive) {
      if (experienceData?.expType === 'SPECIAL') {
        if (values.reservationTime === '') {
          errors.reservationTime = 'Select time is required';
        } else if (values.reservationTime === 'Select time is required') {
          errors.reservationTime = 'Select time is required';
        }
      }
      if (values.package.count === 0) {
        errors.count = 'At least one package is required';
      } else {
        formik.setTouched({}, false, false);
      }
    } else {
      // if (experienceData?.expType === "SPECIAL") {
      //   // if (getTimeSlots && getTimeSlots?.length <= 0) {
      //   //   errors.reservationTime =
      //   //     "There are no timing slots available for the selected date.";
      //   // } else {
      //   // if (values.reservationTime === "") {
      //   //   errors.reservationTime = "Select time is required 3";
      //   // } else if (values.reservationTime === "Select time is required") {
      //   //   errors.reservationTime = "Select time is required 4";
      //   // }
      //   // }
      // }
      if (values.adultCount === 0) {
        errors.adultCount = 'Adult pax is required';
      } else if (totalAdultKidsCount < minValue) {
        errors.adultCount = `Minimum pax limit is ${minValue}`;
      } else {
        formik.setTouched({}, false);
        // formik.setFieldTouched('date', false, false)
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      reservationDate: '',
      reservationTime: '',
      expType: '',
      adultCount: 0,
      adultPrice: 0,
      adultPriceChanged: false,
      kidsCount: 0,
      kidsPrice: 0,
      kidsPriceChanged: false,
      isPackagesActive: false,
      package: {
        name: '',
        count: 0,
        price: 0,
        adults: 0,
        kids: 0,
      },
      birthPkg: 0,
      birthPkgHandicap: 0,
      birthPkgHighchair: 0,
      highchairAccess: false,
      highchairCount: 0,
      handicapCount: 0,
      seatingPreference: '',
      occasion: '',
      subTotal: 0,
      error: '',
    },
    validate,
    onSubmit: (values) => {
      const formData = {
        experienceId: experienceData?._id,
        vendorId: experienceData?.vendorId,
        restaurantId: experienceData?.restaurantId?._id,
        userId: userDetailsInfo?._id,
        expType: experienceData?.expType,
        requestId: '',
        userDetails: {
          mobileNumber: userDetailsInfo?.mobile_number,
          countryCode: userDetailsInfo?.country_code,
          name: userDetailsInfo?.first_name + ' ' + userDetailsInfo?.last_name,
          fcmToken: userDetailsInfo?.FCMtoken,
          email: userDetailsInfo?.email,
        },
        reservationDate: currantDate,
        reservationTime: values.reservationTime,
        adultCount: values.isPackagesActive ? extAdult : values.adultCount,
        adultPrice: experienceData?.adultPrice,
        adultPriceChanged: values.adultPriceChanged,
        kidsCount: values.isPackagesActive ? extKids : values.kidsCount,
        kidsPrice: experienceData?.kidsPrice,
        kidsPriceChanged: values.kidsPriceChanged,
        isPackagesActive: values.isPackagesActive,
        package: values.package,
        highchairAccess: values.highchairAccess,
        highchairCount: values.highchairCount,
        handicapCount: values.handicapCount,
        seatingPreference: values.seatingPreference,
        occasion: values.occasion,
        donation: 0,
        tableNo: '',
        paymentData: {
          paymentMethod: 6,
          totalAmount: 0,
          amount: 0,
          donationAmount: 0,
        },
        taxes: {},
        taxesTotal: 0,
        surCharges: {},
        surChargesTotal: 0,
        pointOfEntry: 'WEBAPP',
        trackLink:
          window.location.protocol +
          '//' +
          window.location.hostname +
          `/restaurant/${restaurantId}/trackexp`,
      };
      if (
        experienceData?.expType === 'CURATED' &&
        activeTimeInfo === 'Not Available'
      ) {
        setDialogBoxText(errorMessage);
        showDialog();
      } else if (
        experienceData?.expType === 'SPECIAL' &&
        formik.values.reservationTime === ''
      ) {
        formik.setErrors({ reservationTime: 'Select time is required' });
      } else {
        const hasAccessToken = sessionStorage.getItem('accessToken');
        if (hasAccessToken) {
          onFinalSubmitAction(formData);
        } else {
          showLoginModal(true);
        }
      }
    },
  });

  const onFinalSubmitAction = (data) => {
    let formData = data;
    let taxesSurcharges = getExperienceTaxes(
      onSubTotalValue(formData),
      experienceData
    );
    formData.paymentData.amount = onSubTotalValue(formData);
    formData.paymentData.totalAmount =
      onSubTotalValue(formData) +
      +taxesSurcharges?.taxesTotal +
      +taxesSurcharges?.surChargesTotal;
    formData.taxes = taxesSurcharges?.taxes;
    formData.taxesTotal = +taxesSurcharges?.taxesTotal;
    formData.surCharges = taxesSurcharges?.surCharges;
    formData.surChargesTotal = +taxesSurcharges?.surChargesTotal;
    sessionStorage.setItem('expBookData', JSON.stringify(formData));
    navigate(
      `/restaurant/${restaurantId}/experience/${experienceId}/paymentSummary`
    );
    // store.dispatch({
    //   type: 'SET_ADD_EXPERIENCE_DATA',
    //   payload: formData,
    // });
    // props.navigation.navigate('PaymentDetailsInfo', {
    //   addBookingInfo: formData,
    // });
  };

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, 'GET');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);

  useEffect(() => {
    if (!isEmpty(getFilledExpData) && state?.actionBy === 'PAYMENT_SUMMARY') {
      formik.setValues({
        ...formik.values,
        reservationDate: getFilledExpData?.reservationDate,
        reservationTime: getFilledExpData?.reservationTime,
        expType: getFilledExpData?.expType,
        adultCount: getFilledExpData?.isPackagesActive
          ? 0
          : getFilledExpData?.adultCount,
        adultPrice: getFilledExpData?.adultPrice,
        adultPriceChanged: getFilledExpData?.adultPriceChanged,
        kidsCount: getFilledExpData?.isPackagesActive
          ? 0
          : getFilledExpData?.kidsCount,
        kidsPrice: getFilledExpData?.kidsPrice,
        kidsPriceChanged: getFilledExpData?.kidsPriceChanged,
        isPackagesActive: getFilledExpData?.isPackagesActive,
        package: getFilledExpData?.package,
        birthPkg: getFilledExpData?.birthPkg,
        birthPkgHandicap: getFilledExpData?.birthPkgHandicap,
        birthPkgHighchair: getFilledExpData?.birthPkgHighchair,
        highchairAccess: getFilledExpData?.highchairAccess,
        highchairCount: getFilledExpData?.highchairCount,
        handicapCount: getFilledExpData?.handicapCount,
        seatingPreference: getFilledExpData?.seatingPreference,
        occasion: getFilledExpData?.occasion,
        subTotal: getFilledExpData?.subTotal,
      });
    }
  }, [state?.actionBy]);

  const onToggleSwitch = () => {
    if (
      experienceData?.handicapAccess ||
      (experienceData?.highchairAccess && !experienceData?.ageRestriction)
    ) {
      formik.setFieldValue('highchairAccess', !formik.values.highchairAccess);
      formik.setFieldValue('highchairCount', 0);
      formik.setFieldValue('handicapCount', 0);
    }
  };

  const onIncrementHandler = (actionType, value, pkgData) => {
    let formikValues = value;
    let adultKidsValue = formikValues?.adultCount + formikValues?.kidsCount;
    let pkgAdultKids =
      +formik.values?.package?.adults +
      +formik.values?.package?.kids +
      +extAdult +
      +extKids;
    let currentCount = 0;
    let newPackData = {
      name: '',
      count: 0,
      price: 0,
      adults: 0,
      kids: 0,
    };
    if (actionType === 'Adult') {
      if (adultKidsValue < experienceData?.maxSeats) {
        if (adultKidsValue >= 0 && adultKidsValue < experienceData?.seatsLeft) {
          if (formik.values.adultCount === 0) {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              adultCount: formikValues.adultCount + 1,
              isPackagesActive: false,
              handicapCount: 0,
              highchairCount: 0,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          } else {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              adultCount: formikValues.adultCount + 1,
              isPackagesActive: false,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          }
        } else {
          // Alert.alert("Alert", "Seats not available");
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      } else {
        setDialogBoxText(errorMessageTwo);
        showDialog();
      }
    }
    if (actionType === 'Kids') {
      if (adultKidsValue < experienceData?.maxSeats) {
        if (adultKidsValue >= 0 && adultKidsValue < experienceData?.seatsLeft) {
          if (formik.values.kidsCount === 0) {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              kidsCount: formikValues.kidsCount + 1,
              isPackagesActive: false,
              handicapCount: 0,
              highchairCount: 0,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          } else {
            formik.setValues({
              ...formikValues,
              package: newPackData,
              kidsCount: formikValues.kidsCount + 1,
              isPackagesActive: false,
            });
            setDisplayPackage(false);
            setExtAdult(0);
            setExtKids(0);
            setActiveTab(2);
          }
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      } else {
        setDialogBoxText(errorMessageTwo);
        showDialog();
      }
    }

    if (actionType === 'package') {
      if (
        formikValues?.package?.count >= 0 &&
        formikValues?.package?.count < 1000
      ) {
        let newPack = {
          name: pkgData?.name,
          count: 0,
          price: pkgData?.discountedPrice || pkgData?.price,
          adults: 0,
          kids: 0,
        };
        if (
          +pkgAdultKids <= +experienceData?.seatsLeft &&
          pkgData?.name === formikValues.package.name
        ) {
          let totalCount = formik.values.package.count + 1;
          let adultCount = pkgData?.adults * totalCount;
          let kidsCount = pkgData?.kids * totalCount;
          currentCount = adultCount + kidsCount + +extAdult + +extKids;
          if (currentCount > +experienceData?.seatsLeft) {
            // let remainingSeats = +currentCount - +experienceData?.seatsLeft;
            let remainingSeats = +experienceData?.seatsLeft - pkgAdultKids;
            if (+experienceData?.seatsLeft === +pkgAdultKids) {
              setDialogBoxText(errorMessageFour);
              showDialog();
            } else {
              setDialogBoxText(
                `${remainingSeats} more ${
                  remainingSeats === 1
                    ? 'seat is available'
                    : 'seats are available'
                }, Please select "Add More people to the package" option`
              );
              showDialog();
            }
          } else {
            newPack.count = totalCount;
            newPack.adults = pkgData?.adults * totalCount;
            newPack.kids = pkgData?.kids * totalCount;
            formik.setValues({
              ...formikValues,
              package: newPack,
              adultCount: 0,
              kidsCount: 0,
              isPackagesActive: true,
            });
          }
        } else if (
          +pkgAdultKids <= +experienceData?.seatsLeft ||
          pkgData?.name !== formikValues.package.name
        ) {
          newPack.count = 1;
          newPack.adults = pkgData?.adults;
          newPack.kids = pkgData?.kids;
          setExtAdult(0);
          setExtKids(0);
          formik.setValues({
            ...formikValues,
            package: newPack,
            adultCount: 0,
            kidsCount: 0,
            isPackagesActive: true,
          });
          setDisplayPackage(false);
        } else {
          setDialogBoxText(errorMessageTwo);
          showDialog();
        }
        if (
          formikValues?.package?.count <= 0 ||
          pkgData?.name !== formikValues.package.name
        ) {
          formik.setFieldValue('handicapCount', 0);
          formik.setFieldValue('highchairCount', 0);
        }
        formik.setFieldValue('errors', {});
        setActiveTab(1);
      }
      //
    }
    if (actionType === 'PkgAdult') {
      if (
        formikValues?.package?.adults >= 0 &&
        formikValues?.package?.adults < 1000
      ) {
        if (+pkgAdultKids < +experienceData?.seatsLeft) {
          let adultCount = formik.values.package?.adults;
          let kidsCount = formik.values.package?.kids;
          setExtAdult(extAdult + 1);
          formik.setValues({
            ...formikValues,
            isPackagesActive: true,
            // handicapCount: 0,
          });
          currentCount = adultCount + kidsCount + extAdult;
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      }
    }
    if (actionType === 'PkgKids') {
      if (
        formikValues?.package?.kids >= 0 &&
        formikValues?.package?.kids < 1000
      ) {
        if (+pkgAdultKids < +experienceData?.seatsLeft) {
          let adultCount = formik.values.package?.adults;
          let kidsCount = formik.values.package?.kids;
          setExtKids(extKids + 1);
          formik.setValues({
            ...formikValues,
            isPackagesActive: true,
            // highchairCount: 0,
          });
          currentCount = adultCount + kidsCount + extKids;
        } else {
          setDialogBoxText(errorMessageFour);
          showDialog();
        }
      }
    }
    if (actionType === 'Handicap') {
      let totalAdultCount = formikValues?.package?.adults + extAdult;
      if (
        formikValues?.handicapCount >= 0 &&
        formikValues?.handicapCount < 1000
      ) {
        if (
          formikValues?.handicapCount < formikValues?.adultCount ||
          formikValues?.handicapCount < totalAdultCount
        ) {
          formik.setFieldValue(
            'handicapCount',
            formikValues?.handicapCount + 1
          );
        }
      }
    }
    if (actionType === 'Highchair') {
      let totalKidsCount = formikValues?.package?.kids + extKids;
      if (
        formikValues?.highchairCount >= 0 &&
        formikValues?.highchairCount < 1000
      ) {
        if (
          formikValues?.highchairCount < formikValues?.kidsCount ||
          formikValues?.highchairCount < totalKidsCount
        ) {
          formik.setFieldValue(
            'highchairCount',
            formikValues.highchairCount + 1
          );
        }
      }
    }
  };

  const onDecrementHandler = (actionType, value, pkgData) => {
    let formikValues = value;

    if (actionType === 'Adult') {
      if (formikValues.adultCount > 0) {
        formik.setFieldValue('adultCount', formikValues.adultCount - 1);
        formik.setFieldValue('handicapCount', 0);
      }
    }
    if (actionType === 'Kids') {
      if (formikValues.kidsCount > 0) {
        formik.setFieldValue('kidsCount', formikValues.kidsCount - 1);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'package') {
      let newPack = {
        name: pkgData.name,
        count: formik.values?.package?.count,
        price: pkgData?.discountedPrice || pkgData?.price,
        adults: formik.values?.package?.adults,
        kids: formik.values?.package?.kids,
      };
      if (formikValues?.package?.count >= 2) {
        let totalCount = formik.values.package.count - 1;
        newPack.count = totalCount;
        newPack.adults = pkgData?.adults * totalCount;
        newPack.kids = pkgData?.kids * totalCount;
      } else if (formikValues?.package?.count === 1) {
        let totalCount = formik.values.package.count - 1;
        newPack.count = totalCount;
        newPack.adults = pkgData?.adults * totalCount;
        newPack.kids = pkgData?.kids * totalCount;
        setDisplayPackage(false);
        setExtAdult(0);
        setExtKids(0);
        formik.setFieldValue('handicapCount', 0);
        formik.setFieldValue('highchairCount', 0);
      }
      if (newPack.count === 0) {
        newPack.name = '';
        newPack.adults = 0;
        newPack.kids = 0;
        formik.setValues({
          ...formik.values,
          package: newPack,
          adultCount: 0,
          adultPrice: experienceData?.adultPrice,
          kidsCount: 0,
          kidsPrice: experienceData?.kidsPrice,
          handicapCount: 0,
          highchairCount: 0,
        });
      } else {
        formik.setFieldValue('package', newPack);
        formik.setFieldValue('handicapCount', 0);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'PkgAdult') {
      if (extAdult > 0 && extAdult < 100) {
        setExtAdult(extAdult - 1);
        formik.setFieldValue('handicapCount', 0);
      }
    }
    if (actionType === 'PkgKids') {
      if (extKids > 0 && extKids < 100) {
        setExtKids(extKids - 1);
        formik.setFieldValue('highchairCount', 0);
      }
    }
    if (actionType === 'Handicap') {
      if (formikValues.handicapCount > 0) {
        formik.setFieldValue('handicapCount', formikValues.handicapCount - 1);
      }
    }
    if (actionType === 'Highchair') {
      if (formikValues.highchairCount > 0) {
        formik.setFieldValue('highchairCount', formikValues.highchairCount - 1);
      }
    }
  };
  // const todat = moment().format("DD MMM YYYY");

  const handleSave = () => {
    formik.handleSubmit();
  };

  const logincallBack = (data) => {
    userDetailsInfo = data;
  };

  return (
    <Box>
      <TitleSection
        bg={'#FFFFFF'}
        title={experienceData?.restaurantName || 'Experience'}
        backRoute={`/restaurant/${restaurantId}/experience/${experienceId}`}
        className={'box-shadow-down'}
        resticon={experienceData?.restaurantId?.logo_url_thumbnails}
        subtitle={`${experienceData?.name} | Experience ID : ${experienceData?.expId}`}
        subtitlecolor={assets.colors.borderIndigo}
      />
      <Box sx={{ backgroundColor: assets.colors.backgrounddarkSkyBlue }} p={2}>
        <Box
          sx={{ border: '1px solid #8771DF', borderRadius: '16px' }}
          className="flex-between"
          p={2}
          mb={2}
        >
          <div className="d-flex">
            <div>
              <Image
                src={require('../../assets/images/IconsandImages/schedule.png')}
                alt="icon"
                width="25px"
                height="25x"
                style={{ maxWidth: '100%' }}
                className="mx-1"
              />
            </div>
            <div style={{ color: assets.colors.textIndigo }}>
              <div className="f14-500">Start Date</div>
              <div className="f12-500">
                {moment(experienceData?.startDate).format('Do MMMM YYYY')}
              </div>
            </div>
          </div>
          <div className="f16-700">To</div>
          <div className="d-flex">
            <Image
              src={require('../../assets/images/IconsandImages/schedule.png')}
              alt="icon"
              width="25px"
              height="25x"
              style={{ maxWidth: '100%' }}
              className="mx-1"
            />
            <div style={{ color: assets.colors.textIndigo }}>
              <div className="f14-500"> End Date</div>
              <div className="f12-500">
                {moment(experienceData?.endDate).format('Do MMMM YYYY')}
              </div>
            </div>
          </div>
        </Box>
        <Divider variant="middle" my={2} color={'black'} />
        <Box component="section" my={2}>
          <div className="d-flex my-1">
            <Image
              src={require('../../assets/images/IconsandImages/schedule.png')}
              alt="icon"
              width="25px"
              height="25x"
              style={{ maxWidth: '100%' }}
              className="mx-1"
            />

            <div className="f16-700 mx-1">Select Date</div>
          </div>
          <div className="my-2 mx-1 row">
            <CustomDatePicker
              value={bookDate}
              defaultValue={
                state?.actionBy === 'PAYMENT_SUMMARY'
                  ? moment(getFilledExpData?.reservationDate).format(
                      'dddd DD MMMM'
                    )
                  : moment(getFilledExpData?.reservationDate).format(
                      'dddd DD MMMM'
                    )
              }
              // minDate={moment(experienceData?.startDate)}
              //maxDate={moment(experienceData?.endDate)}
              onChange={(val) => datepickerDateAction(val)}
              borderRadius={2}
              //label={todat}
              restStyle={{ width: '100%' }}
              showEditIcon={true}
            />
          </div>
        </Box>
        <Box component="section" my={1}>
          <div className="d-flex my-1">
            <Image
              src={require('../../assets/images/IconsandImages/clock.png')}
              alt="icon"
              width="24px"
              height="25x"
              style={{ maxWidth: '100%' }}
              className="mx-1"
            />

            <div className="f16-700 mx-1">Time</div>
          </div>
          {experienceData?.expType === 'SPECIAL' ? (
            <>
              <div
                className="d-flex align-items-center"
                style={{ maxWidth: '100vw', overflowX: 'auto' }}
              >
                {TSData?.map((data) => (
                  <div className="col-4 my-2" key={data}>
                    <div
                      key={data}
                      onClick={() =>
                        formik.setFieldValue('reservationTime', data)
                      }
                    >
                      <div
                        className={`${
                          data === formik?.values?.reservationTime &&
                          'select-button'
                        } f12-400 button px-2 mx-2 py-2 text-center`}
                      >
                        {data}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {TSData && TSData?.length <= 0 ? (
                <div>
                  There are no timing slots available for the selected date.
                </div>
              ) : !isEmpty(formik.errors.reservationTime) &&
                formik.errors.reservationTime ? (
                <div
                  className="f12-500 mt-2"
                  style={{ color: assets.colors.primary }}
                >
                  {formik.errors.reservationTime}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div>
                {activeDateData?.map((dataTwo) => (
                  <div
                    className="f14-500 shadow my-2  p-3"
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid white',
                      borderRadius: '16px',
                    }}
                  >
                    {!isEmpty(dataTwo)
                      ? dataTwo.description === 'Not Available'
                        ? 'Not Available'
                        : moment(dataTwo?.openingTime).format('hh:mm A') +
                          ' - ' +
                          moment(dataTwo?.closingTime).format('hh:mm A')
                      : 'Not Available'}
                  </div>
                ))}
              </div>
              {formik.errors.reservationTime && (
                <div
                  className="f12-500"
                  style={{ color: assets.colors.primary }}
                >
                  {formik.errors.reservationTime}
                </div>
              )}
            </>
          )}

          {/* <div className="my-2">
            <TextField
              type="time"
              value={formik.values.reservationTime}
              onChange={formik.handleChange('reservationTime')}
              fullWidth
              onBlur={formik.handleBlur('reservationTime')}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
                border: 'none',
              }}
            />
          </div> */}
        </Box>
      </Box>
      <Image
        src={require('../../assets/images/ExperiencePageAssets/design_1.png')}
        width={'100%'}
        style={{ marginTop: '-20px' }}
      />
      <div className="f14-500 px-2" style={{ color: assets.colors.primary }}>
        (Seat Selection is not available at this moment, will be assigned at the
        restaurant.)
      </div>
      <hr />
      {experienceData?.allowSingleBooking && (
        <SelectPass
          experienceData={experienceData}
          formik={formik}
          onIncrementHandler={onIncrementHandler}
          onDecrementHandler={onDecrementHandler}
          activeTab={activeTab}
        />
      )}
      {experienceData?.isPackagesProvided &&
        experienceData?.packages.map((packageData) => {
          return (
            <Packages
              experienceData={experienceData}
              formik={formik}
              packageData={packageData}
              onIncrementHandler={onIncrementHandler}
              onDecrementHandler={onDecrementHandler}
              displayPackage={displayPackage}
              setDisplayPackage={setDisplayPackage}
              extAdult={extAdult}
              extKids={extKids}
              activeTab={activeTab}
            />
          );
        })}

      <hr />
      {experienceData?.handicapAccess ||
      (experienceData?.highchairAccess && !experienceData?.ageRestriction) ? (
        <Highchair
          experienceData={experienceData}
          formik={formik}
          onIncrementHandler={onIncrementHandler}
          onDecrementHandler={onDecrementHandler}
          onToggleSwitch={onToggleSwitch}
        />
      ) : null}

      <hr />
      {experienceData?.seatingPreference.length !== 0 ? (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-1">
              <Image
                src={require('../../assets/images/IconsandImages/chair.png')}
                width="25px"
              />
            </div>
            <div className="col-11 f14-500">Seating Preference</div>
          </div>
          <div className="col-10 f12-400 mt-2">
            Your preference will be considered. Seating is a subject to
            availability.*
          </div>
          <div className="container mt-3">
            <div className="row align-items-center">
              {experienceData?.seatingPreference?.map((pref) => (
                <div className="col-4 mb-3" key={pref}>
                  <div
                    key={pref}
                    onClick={() =>
                      formik.setFieldValue('seatingPreference', pref)
                    }
                  >
                    <div
                      className={`${
                        pref === formik?.values?.seatingPreference &&
                        'select-button'
                      } f12-400 button px-2 py-2 text-center`}
                    >
                      {pref}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      <hr />
      <Box component={'section'} p={1} mb={7}>
        <div className="my-1">
          <div className="d-flex my-2">
            <Image
              src={require('../../assets/images/ExperiencePageAssets/concert-ticket.png')}
              alt={'Ticket'}
              title="Ticket"
              width={28}
            />
            <div className="ml-2 f16-700">Special Occasion </div>
          </div>
          <div>
            <TextField
              placeholder="Write Here e.g wedding Anniversary "
              minRows={4}
              multiline={true}
              sx={{ borderRadius: '16px' }}
              fullWidth
              value={formik.values.occasion}
              maxLength={250}
              name="occasion"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </Box>
      <hr />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          position: 'fixed',
          bottom: 0,
          width: '-webkit-fill-available',
          backgroundColor: assets.colors.white,
        }}
        p={2}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={() =>
            navigate(`/restaurant/${restaurantId}/experience/${experienceId}`)
          }
          style={{
            borderRadius: '15px',
            color: 'black',
            border: '1px solid #C4C4C4',
            textTransform: 'capitalize',
          }}
          className="bg-white mr-2"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: '15px',
            border: '1px solid #C4C4C4',
            color: 'white',
            backgroundColor: assets.colors.primary,
            textTransform: 'capitalize',
          }}
          // disabled={!(tableNumber || paxNum || paxNumber)}
          fullWidth
          onClick={handleSave}
        >
          Submit
        </Button>
      </Box>
      {loginModal ? (
        <UserRegistration
          open={loginModal}
          setshowOtpModal={(val) => showLoginModal(val)}
          logincallback={(val) => logincallBack(val)}
          //  setshowDineInModal={(val) => setshowDineInModal(val)}
        />
      ) : null}
    </Box>
  );
}

export default BookExperience;
