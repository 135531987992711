import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

function ExpsuccessModal({
  successModal,
  showSuccessModal,
  exp_id,
  restaurantId,
}) {
  const { ID, expID } = useParams();
  const navigate = useNavigate();

  const handleHome = () => {
    sessionStorage.removeItem('expBookData');
    sessionStorage.removeItem('order_details');
    navigate(`/restaurant/${restaurantId}`);
  };

  const handleTrack = () => {
    showSuccessModal(false);
    sessionStorage.removeItem('expBookData');
    sessionStorage.removeItem('order_details');
    navigate(`/restaurant/${restaurantId}/trackexp/${exp_id}`);
  };
  return (
    <Dialog
      open={successModal}
      aria-labelledby="order-success-dialog-title"
      aria-describedby="order-success-dialog-description"
    >
      <DialogTitle id="order-success-dialog-title" sx={{ textAlign: 'center' }}>
        Your Expirence has been successfully created.
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', p: 0 }}>
        <div className="d-flex justify-content-around p-4">
          <button
            className="btn btn-outline-secondary px-1"
            style={{ minWidth: '100px', borderRadius: '16px' }}
            onClick={handleHome}
          >
            Home
          </button>
          <button
            className="btn btn-danger px-1"
            style={{ minWidth: '100px', borderRadius: '16px' }}
            onClick={handleTrack}
          >
            Track
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ExpsuccessModal;
