import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
const RestaurantCard = (props) => {
  return (
    <>
      <div className="restaurant_title_card" style={{ position: 'relative' }}>
        <div>
          <div className="justify-content-between align-items-center d-flex first_layer">
            <div>
              <Image
                src={require(props?.restData?.vegOnly
                  ? `../../assets/images/IconsandImages/vegindicator.png`
                  : `../../assets/images/IconsandImages/nonveg_indicator.png`)}
                alt="Image"
                className="star_rating"
              />
            </div>
            {/* nonveg_indicator */}
            <div className="hygiene align-items-center justify-content-center  d-flex">
              <Image
                src={require('../../assets/images/IconsandImages/health.png')}
                alt="Image"
                className="star_rating"
              />
            </div>
          </div>
          {props?.restData?.restaurantTag ? (
            <span
              className=" py-1 px-2  mx-4 f10-500 merchant-tag-card"
              style={{
                backgroundColor: props?.restData?.restaurantTag?.hexColor || '',
                color: 'white',
              }}
            >
              {props?.restData?.restaurantTag?.title}
            </span>
          ) : null}
          <Row className="p-1 pb-3 m-0">
            <Col xs={4}>
              <Image
                src={props?.restData?.logo_url}
                alt="restaurantImage"
                className="resturant_card_image"
              />
            </Col>
            <Col xs={8}>
              <div className="pb-2">
                <div className="f16-700 d-flex justify-content-between">
                  {props?.restData?.restaurant_name}{' '}
                </div>
                <div className="f12-400 resturant_cusine_color">
                  {props?.restData?.selected_cusine?.length
                    ? `${props?.restData?.selected_cusine[0]?.title}, `
                    : ''}
                  {props?.restData?.selected_cusine?.length > 1
                    ? `${props?.restData?.selected_cusine[1]?.title} `
                    : ''}
                  {props?.restData?.selected_cusine?.length > 2
                    ? `, +${
                        parseInt(props?.restData?.selected_cusine?.length) - 2
                      }`
                    : ''}
                </div>
              </div>

              <Row className="pb-1">
                <Col xs={3}>
                  <div className="f14-500 d-flex align-items-center">
                    <Image
                      src={require('../../assets/images/landingPageAssets/star.png')}
                      alt="Image"
                      className="star_rating"
                    />
                    <div className="f14-600 star_color align-items-center pl-1">
                      {props?.restData?.rating}
                    </div>
                  </div>
                </Col>
                <Col xs={5}>
                  <div className="f14-500 d-flex align-items-center">
                    <Image
                      src={require('../../assets/images/landingPageAssets/direction_location.png')}
                      alt="Image"
                      className="direction_img"
                    />
                    <div className="f14-700  align-items-center pl-1">
                      {`${
                        typeof props?.restData?.userDistance === 'number'
                          ? (props?.restData?.userDistance / 1000).toFixed(1)
                          : 'N/A'
                      } KM`}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <div className="f14-500 d-flex align-items-center">
                    <Image
                      src={props?.restData?.primary_food_type?.icon}
                      alt="Image"
                      className="star_rating"
                    />
                    <div className="f14-600 star_color align-items-center pl-1">
                      {props?.restData?.primary_food_type?.title?.length > 7
                        ? props?.restData?.primary_food_type?.title.substr(
                            0,
                            7
                          ) + '..'
                        : props?.restData?.primary_food_type?.title}
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="f14-500 d-flex align-items-center">
                    <Image
                      src={props?.restData?.primary_restaurant_type?.icon}
                      alt="Image"
                      className="star_rating"
                    />
                    <div className="f14-600 star_color align-items-center pl-1">
                      {props?.restData?.primary_restaurant_type?.title?.length >
                      7
                        ? props?.restData?.primary_restaurant_type?.title.substr(
                            0,
                            7
                          ) + '..'
                        : props?.restData?.primary_restaurant_type?.title}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default RestaurantCard;
