import React, { useState } from 'react';
//import "./PersonlizationCustomization.css"; // Import the CSS file for styling

function CheckBoxWithText({ isselected, onChange }) {
  const [isChecked, setIsChecked] = useState(isselected);

  const handleCheckboxChange = () => {
    onChange();
    setIsChecked(!isChecked);
  };

  return (
    <div className="checkboxContainer" onClick={handleCheckboxChange}>
      <div className={isChecked ? 'checkbox checked' : 'checkbox'}>
        {isChecked && <span>✓</span>}
      </div>
      <span className="f14-700">Save it for future orders.</span>
    </div>
  );
}

export default CheckBoxWithText;
