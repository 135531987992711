import React from 'react';
import { Image } from 'react-bootstrap';
import ImageSlider from '../ReusableComponent/imageslider';

const ImageCardExperience = ({ data }) => {
  return (
    <div>
      <div className="image-card">
        <ImageSlider
          images={data?.photos}
          captionData={
            <div className="d-flex align-items-center position-absolute bottom-0 info-image-card rounded mx-2 my-4 px-3 py-1">
              <div className="border-right text-center">
                <Image
                  src={require('../../assets/images/ExperiencePageAssets/eye.png')}
                  className="icon"
                  alt="Views"
                  title="Views"
                />
                <div className="f9-700">{data?.expViews || 0} Views</div>
              </div>
              <div className="border-right text-center mx-2">
                <Image
                  src={require('../../assets/images/ExperiencePageAssets/heartColoured.png')}
                  className="icon"
                  alt="Views"
                  title="Views"
                />
                <div className="f9-700">{data?.expLikes || 0}</div>
              </div>
              <div className="text-center ml-2">
                <Image
                  src={require('../../assets/images/ExperiencePageAssets/star.png')}
                  className="icon"
                  alt="Views"
                  title="Views"
                />
                <div className="f9-700">
                  {parseFloat(data?.expReviewed).toFixed(1) || 0}
                </div>
              </div>
            </div>
          }
          shareLike={
            <div className="d-flex justify-content-between align-items-center mx-4">
              <div className="bg-white rounded-circle px-2 text-center">
                <Image
                  className="icon"
                  src={require('../../assets/images/ExperiencePageAssets/share.png')}
                  alt="Share"
                  title="Share"
                  style={{
                    marginTop: '-4px',
                    marginLeft: '-2px',
                  }}
                />
              </div>
              <div className="bg-white rounded-circle px-2 text-center">
                <Image
                  className="icon"
                  src={require('../../assets/images/ExperiencePageAssets/fav.png')}
                  alt="Favorite"
                  title="Favorite"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};
export default ImageCardExperience;
