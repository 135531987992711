import RestaurantHome from '../../screens/restaurant_home';
import '../../../src/index.scss';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import Experiences from '../../screens/experience/Experiences';
import ExperiencesDetails from '../../screens/experience-details/ExperiencesDetails';
import RestaurantDetails from '../../screens/restaurentDetails/RestaurantDetails';
import HealthAndsanitation from '../../screens/healthsanitation/healthsection';
import ViewMenu from '../../screens/viewmenu/ViewMenu';
import SeatingUserInfo from '../../screens/seating/SeatingUserInfo';
import MenuFilter from '../../screens/viewmenu/MenuFilter';
import TrackScreen from '../../screens/seating/TracksSreen';
import WriteReview from '../../screens/ReusableComponent/WriteReview';
import PrivacyPolicy from '../../screens/privacypolicy/PrivacyPolicy';
import CartPage from '../../screens/ordering/cartPage/CartPage';
import CheckOutPage from '../../screens/ordering/checkoutPage/CheckOutPage';
import OrderTrackScreen from '../../screens/ordering/trackScreen/OrderTrackScreen';
import CurbSideDetails from '../../screens/ordering/modals/CurbSideDetails';
import BookExperience from '../../screens/bookexperience/BookExperience';
import ExperienceCheckout from '../../screens/experience/ExperienceCheckout';
import ExpTrackScreen from '../../screens/bookexperience/ExpTrackScreen';
import Login from '../../screens/login/Login';
/**
 *
 * @returns component for the specific route w.r.tp the app base url
 */
const Routes = () => {
  return (
    <>
      <ReactRoutes>
        {/* public routes  */}
        {/* <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} /> */}

        {/* Protected routes accessible only when accessToken is present else redirect to login page */}
        {/* <Route element={<RequireAuth allowedRoles={ALLOWED_ROLES_ALL}/>}>
        <Route path="/" element={(<Navigate replace to="/home" />)} />
        </Route> */}

        {/* incase of url not matched redirect to page not found */}
        {/* <Route path="*" element={<Missing />} /> */}
        <Route path="/login" element={<Login />} />

        {/* Restaurant Routes */}
        <Route path="/restaurant/:ID" element={<RestaurantHome />} />
        <Route path="/restaurant/:ID/viewmenu" element={<ViewMenu />} />
        <Route
          path="/restaurant/:ID/restaurantdetails"
          element={<RestaurantDetails />}
        />
        <Route
          path="/restaurant/:ID/viewmenu/menufilter"
          element={<MenuFilter />}
        />
        <Route
          path="/restaurant/:ID/healthandsanitation"
          element={<HealthAndsanitation />}
        />

        {/* Experience Routes */}
        <Route
          path="/restaurant/:restaurantId/experience"
          element={<Experiences />}
        />
        <Route
          path="/restaurant/:restaurantId/experience/:experienceId"
          element={<ExperiencesDetails />}
        />

        <Route
          path="/restaurant/:restaurantId/experience/:experienceId/bookexp"
          element={<BookExperience />}
        />
        <Route
          path="/restaurant/:restaurantId/experience/:experienceId/paymentSummary"
          element={<ExperienceCheckout />}
        />
        <Route
          path="/restaurant/:restaurantId/trackexp/:trackId"
          element={<ExpTrackScreen />}
        />
        {/* Seating Routes */}
        <Route
          path="/restaurant/:restaurantId/seating"
          element={<SeatingUserInfo />}
        />
        <Route
          path="/restaurant/:restaurantId/reservation"
          element={<SeatingUserInfo />}
        />
        <Route
          path="/restaurant/:restaurantId/seating/writereview"
          element={<WriteReview />}
        />
        <Route
          path="/restaurant/:restaurantId/seating/track/:dinerId"
          element={<TrackScreen />}
        />

        {/* ordering Routes */}
        <Route path="/restaurant/:ID/cartpage" element={<CartPage />} />

        <Route
          path="/restaurant/:ID/checkout/:order_id"
          element={<CheckOutPage />}
        />

        <Route
          path="/restaurant/:ID/ordertrack/:order_id"
          element={<OrderTrackScreen />}
        />
        <Route
          path="/restaurant/:ID/curbside-details"
          element={<CurbSideDetails />}
        />

        {/* privacy-policy */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </ReactRoutes>
    </>
  );
};

export default Routes;
