import React from 'react';

import { Box, Button } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Image } from 'react-bootstrap';

function NowLaterBar() {
  return (
    <Box>
      <div
        style={{ backgroundColor: '#EEEAFE' }}
        className="p-1 d-flex justify-content-end"
      >
        <Button
          variant="outlined"
          startIcon={<AccessTimeIcon />}
          style={{
            borderRadius: 12,
            color: '#867AD9',
            backgroundColor: 'white',

            textTransform: 'none',
          }}
          className="m-2"
          size="small"
        >
          Now
        </Button>
        <Button
          variant="outlined"
          startIcon={<AccessTimeIcon />}
          style={{
            borderRadius: 12,
            color: '#023020',
            backgroundColor: 'white',

            textTransform: 'none',
          }}
          className="m-2"
          size="small"
        >
          Later
        </Button>
      </div>
      <div style={{ marginTop: '-10px' }}>
        <Image
          src={require('../../assets/images/ordermodalassets/blueStrip.png')}
          width={'100%'}
          className="m-0 p-0"
        />
      </div>
    </Box>
  );
}

export default NowLaterBar;
