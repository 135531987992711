import React, { useEffect, useState } from 'react';
import TitleSection from '../../ReusableComponent/TitleSection';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URL } from '../../../common/api/urls';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { Image, Table } from 'react-bootstrap';
import moment from 'moment';
import DottedHr from '../../reusableUiComponents/DottedHr';
import {
  calcFinalTotal,
  calcTotalOriginal,
} from '../calculations/OrderingCalculations';
import {
  NEW_PAYMENT_TYPE_METHOD,
  ORDER_TYPES,
  ORDER_TYPE_NAME,
} from '../../../common/enums/OrderEnums';
import SuccessModals from '../modals/SuccessModals';
import UserRegistration from '../../ReusableComponent/UserRegistration';
import DineInModal from '../modals/dineInModal';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { isEmpty } from '../../../common/utility/helperFunc';
import NotificationAlert from '../../ReusableComponent/Alert';
import { Checkbox, CircularProgress } from '@mui/material';
import { assets } from './../../../assets/assets';
import {
  ORDER_RAZORPAY_KEY,
  PAY_LATER_ARR,
  PAY_WITH_ARR,
  UPI_ARR,
} from '../../../common/constants/Payments';
import axios from 'axios';

import useAlerts from '../../../components/AlertMessages';
function CheckOutPage() {
  const { ID, order_id } = useParams();

  const navigate = useNavigate();

  const { showSuccessAlert, showErrorAlert, AlertComponent } = useAlerts();

  const location = useLocation();
  const orderData = JSON.parse(sessionStorage.getItem('orderPostData'));
  const orderType = sessionStorage.getItem('cartOrderType');
  const [visible, setVisible] = useState(false);
  const [dialogBoxText, setDialogBoxText] = useState('');
  const [token, setToken] = useState(sessionStorage.getItem('accessToken'));
  const [getLocation, setGetLocation] = useState('');
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [openAddMoneyModal, setOpenAddMoneyModal] = useState(''); 

  const [disable,setDisable] = useState(false)

  const [selectedSplitPaymentMethod, setSelectedSplitPaymentMethod] =
    useState(false);
  const [selectedSplitPaymentMethodText, setSelectedSplitPaymentMethodText] =
    useState('');

  const [walletCheck, setWalletCheck] = useState(false);

  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    restId: ID,
    openBankingModal: false,
    walletDetailsFinal: {},
    savedCardListArr: [],
    savedUpiListArr: [],
    currentOrderData: {},
    currentRestaurantInfo: {},
    currentTotalAmount: 0,
    currentOrderType: '',
    currentOrderId: '',
    currentActionBy: '',
    //************//
    setCustomerId: '',
    selectedUsedIdState: '',
    cardCvv: '',
    selectedPaymentMethod: '',
    netBankingPaymentMethod: '',
    netBankingPaymentKey: '',
  });

  const {
    isLoading,
    restId,
    openBankingModal,
    currentOrderData,
    walletDetailsFinal,
    savedCardListArr,
    currentRestaurantInfo,
    currentTotalAmount,
    currentOrderType,
    currentOrderId,
    currentActionBy,
    setCustomerId,
    selectedUsedIdState,
    cardCvv,
    selectedPaymentMethod,
    netBankingPaymentMethod,
    netBankingPaymentKey,
  } = state;

  const [storedItems, setStoredItems] = useState(
    JSON.parse(sessionStorage.getItem('cartItems')) || []
  );

  const [successModal, showSuccessModal] = useState(false);

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  // eslint-disable-next-line
  const [
    orderPostcancel,
    orderPostresponseData,
    orderPosterror,
    orderPostloaded,
    orderPostreset,
    orderPostexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    ordercancel,
    orderresponseData,
    ordererror,
    orderloaded,
    orderreset,
    orderexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    orderTrackcancel,
    ordertrackresponseData,
    orderTrackerror,
    orderTrackloaded,
    orderTrackreset,
    orderTrackexecuteAPI,
  ] = useAxios();

  // eslint-disable-next-line
  const [
    transactioncancel,
    transactionresponseData,
    transactionerror,
    transactionloaded,
    transactionreset,
    transactionexecuteAPI,
  ] = useAxios();

  //razor pay order records //
  const [
    razorpayOrderRecordCancel,
    razorpayOrderRecordresponseData,
    razorpayOrderRecorderror,
    razorpayOrderRecordloaded,
    razorpayOrderRecordreset,
    razorpayOrderRecordexecuteAPI,
  ] = useAxios();

  //razor pay customer records //
  const [
    razorpayPaymentCancel,
    razorpayPaymentresponseData,
    razorpayPaymenterror,
    razorpayPaymentloaded,
    razorpayPaymentreset,
    razorpayPaymentexecuteAPI,
  ] = useAxios();

  //wallet payments //
  const [
    walletPaymentCancel,
    walletPaymentresponseData,
    walletPaymenterror,
    walletPaymentloaded,
    walletPaymentreset,
    walletPaymentexecuteAPI,
  ] = useAxios();

  //wallet payments //
  const [
    walletAmountCancel,
    walletAmountresponseData,
    walletAmounterror,
    walletAmountloaded,
    walletAmountreset,
    walletAmountexecuteAPI,
  ] = useAxios();

  let UserData = JSON.parse(sessionStorage.getItem('userDetails'));

  // let OrderData = JSON.parse(sessionStorage.getItem("order_details"));

  const subscriptionData = state?.restData?.subscription?.casual_dining
    ? state?.restData?.subscription?.casual_dining_status
    : state?.restData?.subscription?.fast_food_dining
    ? state?.restData?.subscription?.fast_food_dining_status
    : state?.restData?.subscription?.multi_service_business
    ? state?.restData?.subscription?.multi_service_business_status
    : {};

  // let summary = calcFinalTotal(storedItems, UserData, '', subscriptionData);

  const [showOtpModal, setshowOtpModal] = useState(false);
  const [showDineInModal, setshowDineInModal] = useState(false);

  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        'GET',
        null
      );
      walletAmountexecuteAPI(
        `${URL.WALLETAMOUNT}/${UserData?.wallet_id}`,
        'GET',
        null,
        {
          Authorization: sessionStorage.getItem('accessToken'),
        }
      );
    }
    // if (!sessionStorage.getItem("accessToken")) setshowOtpModal(true);
    // else if (!JSON.parse(sessionStorage.getItem("order_details"))?.proceed) {
    //   setshowDineInModal(true);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(orderData)) {
      navigate(`/restaurant/${restId}/`);
    }
  }, [orderData]);

  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (orderresponseData) {
      showSuccessModal(true);
      orderTrackexecuteAPI(
        `${URL.USER_ORDER}/${orderresponseData?._id}`,
        'GET',
        null,
        {
          Authorization: sessionStorage.getItem('accessToken'),
        }
      );
    }
  }, [orderresponseData]);

  const naviageViewMenu = () => {
    navigate(`/restaurant/${ID}/viewmenu`);
  };

  const handleProceed = () => {
    if (sessionStorage.getItem('accessToken')) {
      let formData = JSON.parse(sessionStorage.getItem('orderPostData'));
      const order_details = JSON.parse(sessionStorage.getItem('order_details'));
      let user_details = JSON.parse(sessionStorage.getItem('userDetails'));
      formData = { ...formData, ...order_details };
      formData.user_details = {
        first_name: user_details?.first_name,
        last_name: user_details?.last_name,
        country_code: user_details?.country_code,
        mobile_number: user_details?.mobile_number,
        user_id: user_details?._id,
        email: user_details?.email,
        token: user_details?.FCMtoken,
      };
      formData.total_amount = orderData?.total_amount;
      formData.token = user_details?.FCMtoken;
      formData.user_id = user_details?._id;
      formData.payment_method = 6;
      formData.payment_status = 0;
      formData.user_location = {
        lat: user_details?.lastLocation.coordinates[1],
        long: user_details?.lastLocation.coordinates[0],
      };
      formData.totalDinePax = order_details?.totalDinePax;

      formData['trackLink'] =
        window.location.protocol +
        '//' +
        window.location.hostname +
        `/restaurant/${ID}/ordertrack`;
      orderPostexecuteAPI(`${URL.USER_ORDER}`, 'POST', formData, {
        Authorization: sessionStorage.getItem('accessToken'),
      });
    }
  };

  const getOrderData = () => {
    if (sessionStorage.getItem('accessToken')) {
      let formData = JSON.parse(sessionStorage.getItem('orderPostData'));
      const order_details = JSON.parse(sessionStorage.getItem('order_details'));
      let user_details = JSON.parse(sessionStorage.getItem('userDetails'));
      formData = { ...formData, ...order_details };
      formData.user_details = {
        first_name: user_details?.first_name,
        last_name: user_details?.last_name,
        country_code: user_details?.country_code,
        mobile_number: user_details?.mobile_number,
        user_id: user_details?._id,
        email: user_details?.email,
        token: user_details?.FCMtoken,
      };
      formData.total_amount = orderData?.total_amount;
      formData.token = user_details?.FCMtoken;
      formData.user_id = user_details?._id;

      formData.user_location = {
        lat: user_details?.lastLocation.coordinates[1],
        long: user_details?.lastLocation.coordinates[0],
      };
      formData.totalDinePax = order_details?.totalDinePax;

      formData['trackLink'] =
        window.location.protocol +
        '//' +
        window.location.hostname +
        `/restaurant/${ID}/ordertrack`;

      return formData;
    }
    return null;
  };

  const walletSelected = () => {
    if (!walletCheck) {
      setState({
        ...state,
        selectedPaymentMethod: 'WALLET',
      });
    } else if (walletCheck) {
      setState({
        ...state,
        selectedPaymentMethod: '',
      });
    }
    setWalletCheck(!walletCheck);
  };

  // useEffect(() => {
  //   if (orderPostresponseData?._id) {
  //     orderexecuteAPI(
  //       `${URL.USER_ORDER}/${orderPostresponseData?._id}`,
  //       'PATCH',
  //       {
  //         order_status: 0,
  //         currentStatusText: 'Pending',
  //       },
  //       {
  //         Authorization: sessionStorage.getItem('accessToken'),
  //       }
  //     );
  //     transactionexecuteAPI(
  //       `${URL.UPDATE_TRANSACTION}`,
  //       'POST',
  //       {
  //         paid: false,
  //         method: 'PAYLATER',
  //         amount: orderPostresponseData?.base_amount,
  //         type: 'PAYLATER',
  //         date: moment().valueOf(),
  //         paymentType: 6,
  //         id: orderPostresponseData?._id,
  //       },
  //       {
  //         Authorization: sessionStorage.getItem('accessToken'),
  //       }
  //     );
  //   }
  // }, [orderPostresponseData]);

  const backButton = () => {
    navigate(`/restaurant/${ID}/cartpage`);
  };

  /**************************************
   * @DESC PAYMENT METHOD SELECTION FUNC
   *************************************/
  const onButtonClickAction = (infoData) => {
    let upperTitle = infoData.title.split(' ').join('').trim().toUpperCase();
    if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) >=
        +currentTotalAmount.toFixed(2) &&
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) + +currentTotalAmount.toFixed(2) >
        10000
    ) {
      setVisible(true);
      setDialogBoxText(
        'You have sufficient wallet balance for this order, Please use your wallet for placing this order'
      );
    } else if (
      walletDetailsFinal?.balance &&
      +walletDetailsFinal?.balance.toFixed(2) <
        +currentTotalAmount.toFixed(2) &&
      selectedPaymentMethod.toLowerCase() === 'wallet' &&
      (infoData?.title.toLowerCase() === 'bhim upi' ||
        infoData?.title.toLowerCase() === 'debit card' ||
        infoData?.title.toLowerCase() === 'credit card' ||
        upperTitle.toLowerCase() === 'netbanking' ||
        // infoData?.title.toLowerCase() === "pay at site" ||
        infoData?.title.toLowerCase() === 'upi_default')
    ) {
      if (!isEmpty(sessionStorage.getItem('accessToken'))) {
        setSelectedSplitPaymentMethod(true);
        onClickCheckSplitPayment(infoData);
      } else {
        // props.navigation.navigate('Login', {
        //   fromAppFlow: true,
        // });
      }
    } else {
      if (!isEmpty(sessionStorage.getItem('accessToken')))
        if (infoData.isOpenModal && infoData.linkType === 'MODAL') {
          setUseWalletAmount(false);
          if (upperTitle === 'NETBANKING') {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: true,
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: upperTitle,
              openBankingModal: false,
            });
          }
        } else {
          setUseWalletAmount(false);
          if (selectedPaymentMethod === infoData?.title) {
            setState({
              ...state,
              selectedPaymentMethod: '',
              netBankingPaymentMethod: '',
              netBankingPaymentKey: '',
            });
          } else {
            setState({
              ...state,
              selectedPaymentMethod: infoData?.title,
              netBankingPaymentMethod: '',
              netBankingPaymentKey: '',
            });
          }
        }
      else {
      }
    }
  };

  ///check split payment //

  const onClickCheckSplitPayment = (selectedInfo) => {
    let upperTitle = selectedInfo.title
      .split(' ')
      .join('')
      .trim()
      .toUpperCase();
    if (
      selectedPaymentMethod === 'WALLET' &&
      selectedSplitPaymentMethodText.toLowerCase() !==
        selectedInfo?.title.toLowerCase()
    ) {
      setSelectedSplitPaymentMethod(true);
      if (upperTitle.toLowerCase() === 'netbanking') {
        setSelectedSplitPaymentMethodText('NETBANKING');
      } else {
        setSelectedSplitPaymentMethodText(selectedInfo?.title);
      }
    } else {
      setSelectedSplitPaymentMethod(false);
      setSelectedSplitPaymentMethodText('');
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Function to handle input change
  const setDisableBtn = (e) => {
    setDisable(false)
  };

  const debouncedHandleInputChange = debounce(setDisableBtn, 4000);

  /******************************************************
   * @DESC ON CLICK PROCEED ACTION CALL
   *****************************************************/
  const onClickProceedAction = () => {
    setDisable(true)
    debouncedHandleInputChange()
    if (selectedPaymentMethod === 'WALLET') {
      if (walletAmountresponseData?.balance > orderData?.total_amount) {
        onClickPayByWalletAmount();
      } else {
        showErrorAlert(
          'Insufficient wallet amount, please select different payment method'
        );
      }
    } else if (
      selectedPaymentMethod === 'Debit Card' ||
      selectedPaymentMethod === 'Credit Card' ||
      selectedPaymentMethod === 'NETBANKING' ||
      selectedPaymentMethod === 'Pay At Site' ||
      selectedPaymentMethod === 'BHIM UPI' ||
      selectedPaymentMethod === 'UPI_DEFAULT'
    ) {
      onClickPayByWalletAmount();
    } else {
      
      setVisible(true);
      setDialogBoxText('Select at least one payment method');
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 1: CREATE ORDER
   ******************************************************/
  const onClickPayByWalletAmount = () => {
    setState({
      ...state,
      isLoading: true,
    });
    if (selectedPaymentMethod === 'Pay At Site') {
      let formData = getOrderData();
      formData.payment_method = 6;
      formData.payment_pending = false;
      formData.payment_status = 0;
      orderPostexecuteAPI(
        `${URL.USER_ORDER}`,
        'POST',
        formData,
        {
          Authorization: sessionStorage.getItem('accessToken'),
        },
        (resData) => razorPayPaymentSuccess(resData)
      );

      // dispatch(
      //   create_User_Order_Records(
      //     formData,
      //     (resData) => orderPayLaterSuccess(resData),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    } else {
      let orderData = getOrderData();
      orderData.payment_method = NEW_PAYMENT_TYPE_METHOD.indexOf(
        selectedPaymentMethod
      );
      orderData.payment_status = 0;
      if (orderData) {
        orderPostexecuteAPI(
          `${URL.USER_ORDER}`,
          'POST',
          orderData,
          {
            Authorization: sessionStorage.getItem('accessToken'),
          },
          (resData) => orderCallbackSuccess(resData.data)
        );
      }
      // dispatch(
      //   create_User_Order_Records(
      //     currentOrderData,
      //     (resData) => orderCallbackSuccess(resData),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 2: CREATE RAZOR PAY ORDER RECORDS
   *****************************************************/
  const orderCallbackSuccess = (orderSuccess) => {
    let orderID = orderSuccess?._id;

    sessionStorage.setItem('currentOrderID', orderID);
    let formData = {
      amount: selectedSplitPaymentMethod
        ? +orderSuccess?.pendingAmount
        : +orderSuccess?.total_amount,
      user_id: orderSuccess?.user_id,
      transaction_type: 'RAZORPAY',
      refModel: 'User',
      message: '',
      currency: 'INR',
      isEvent: false,
      restaurant_id: orderSuccess?.restaurant_id?._id,
      type: 'razorpay',
    };
    if (!isEmpty(orderID)) {
      razorpayOrderRecordexecuteAPI(
        `${URL.RAZORPAY_ORDER_RECORD}`,
        'POST',
        formData,
        {
          Authorization: sessionStorage.getItem('accessToken'),
        },
        (resData) => razorpayCallbackSuccess(resData.data)
      );
      // dispatch(
      //   razorpayOrderRecordexecuteAPI(
      //     formData,
      //     orderID,
      //     (resData, orderID) => razorpayCallbackSuccess(resData, orderID),
      //     (errData) => orderCallbackError(errData)
      //   )
      // );
    }
  };

  /******************************************************
   * @DESC PAYMENT STEP 3: CREATE RAZOR PAY CUSTOMER RECORDS
   *****************************************************/
  const razorpayCallbackSuccess = (prevApiResponse) => {
    sessionStorage.setItem(
      'razorpayOrderDetails',
      JSON.stringify(prevApiResponse)
    );
    let encodedString =
      'Basic cnpwX2xpdmVfM210WVZPOG5ST2I1VXE6QWVibE1ac2xWTjdhVjg5SXRnZXpEWGxB';
    if (selectedPaymentMethod === 'WALLET' && !selectedSplitPaymentMethod) {
      let formData = {
        user_id: prevApiResponse?.transactional?.user_id,
        transaction_type: 'WALLET',
        amount: prevApiResponse?.transactional?.amount,
        restaurant_id: prevApiResponse?.transactional?.restaurant_id,
        orderId: sessionStorage.getItem('currentOrderID'),
        transactional_id: prevApiResponse?.transactional?._id,
      };
      walletPaymentexecuteAPI(
        `${URL.WALLET_PAYMENT}`,
        'POST',
        formData,
        {
          Authorization: sessionStorage.getItem('accessToken'),
        },
        (resData) => razorPayPaymentSuccess(resData)
      );
    } else {
      let formData = {
        name: UserData?.first_name + ' ' + UserData?.last_name,
        contact: UserData?.mobile_number,
        email: UserData?.email,
        fail_existing: '0',
        notes: {
          notes_key_1: '',
          notes_key_2: '',
        },
      };

      // create_Razorpay_Customer_Records(
      //   formData,
      //   prevApiResponse,
      //   orderId,
      //   encodedString,
      //   (resData, prevApiResponse, orderID) =>
      //     razorpayCustomerSuccess(resData, prevApiResponse, orderID),
      //   (errData) => orderCallbackError(errData)
      // );
      razorpayCustomerSuccess(
        'cust_N3Ms9PumHZ9Gn2',
        prevApiResponse,
        sessionStorage.getItem('currentOrderID')
      );
    }
  };

  const orderCallbackError = (err) => {
    console.log(err);
  };

  // const razorpayCustomerSuccess = () => {};

  /******************************************************
   * @DESC PAYMENT STEP 4: WALLET PAYMENT API SUCCESS
   *****************************************************/
  const paymentApiSuccess = (resData) => {
    let formData = {
      payment_status: 1,
      payment_method: 7,
      transactional_id: resData?._id,
      razorpay_payment_id: '',
      transactional: {
        payment_captured: true,
      },
    };
    razorPayPaymentSuccess();
  };

  /******************************************************
   * @DESC PAYMENT STEP 4: OPEN RAZOR PAY MODAL
   *****************************************************/
  const razorpayCustomerSuccess = (
    customerInfo,
    prevResponse,
    orderID,
    razorPayPaymentApiSuccess,
    orderCallbackError
  ) => {
    const customerID = customerInfo;

    console.log(orderID);

    // Define options for the Razorpay modal
    const options = {
      key: 'rzp_test_7P1z0w07gah1Uv', // Replace with your Razorpay API key
      amount: prevResponse?.amount, // Amount in smallest currency unit (e.g., paise for INR)
      currency: 'INR', // Currency code (e.g., 'INR')
      name: 'Amealio',
      description: 'Amealio Ordering Payment',
      image: 'https://your-image-url.com/logo.png', // Optional: logo or image URL
      order_id: prevResponse?.transactional?.order_id,
      // customer_id: customerID,
      prefill: {
        name: UserData?.firstName,
        email: UserData?.email,
        contact: `${UserData?.country_code} ${UserData?.mobile_number}`,
      },
      method: {
        netbanking: selectedPaymentMethod === 'NETBANKING',
        card:
          selectedPaymentMethod === 'Debit Card' ||
          selectedPaymentMethod === 'Credit Card',
        wallet: false,
        upi: selectedPaymentMethod === 'BHIM UPI',
      },
      notes: {
        order_id: orderID,
      },

      handler: (response) => {
        if (response?.razorpay_payment_id) {
          const paymentDetails = {
            transactional_id: prevResponse?.transactional?._id,
            razorpay_payment_id: response?.razorpay_payment_id,
            isEvent: false,
            order_id: sessionStorage.getItem('currentOrderID'),
          };
          razorpayPaymentexecuteAPI(
            `${URL.RAZORpAY_PAYMENT_RECORD}`,
            'POST',
            paymentDetails,
            {
              Authorization: sessionStorage.getItem('accessToken'),
            },
            (resData) => razorPayPaymentSuccess(resData)
          );
          // Call the success callback function directly
          console.log('sucess');
        }
      },
      prefill: {
        name: UserData?.firstName,
        email: UserData?.email,
        contact: `${UserData?.country_code}${UserData?.mobile_number}`,
      },
    };

    // Additional options for payment methods
    if (
      selectedPaymentMethod.toUpperCase() === 'WALLET' &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText.toLowerCase() === 'netbanking') {
        options.bank = netBankingPaymentKey;
      }
    } else if (selectedPaymentMethod.toLowerCase() === 'netbanking') {
      options.bank = netBankingPaymentKey;
    }

    if (
      selectedPaymentMethod.toUpperCase() === 'WALLET' &&
      selectedSplitPaymentMethod
    ) {
      if (selectedSplitPaymentMethodText === 'UPI_DEFAULT') {
        options.vpa = selectedUsedIdState;
      }
    } else if (selectedPaymentMethod === 'UPI_DEFAULT') {
      options.vpa = selectedUsedIdState;
    }

    // const rzp1 = new Razorpay(options);

    // rzp1.on('payment.failed', function (response) {
    //   alert(response.error.code);
    //   alert(response.error.description);
    //   alert(response.error.source);
    //   alert(response.error.step);
    //   alert(response.error.reason);
    //   alert(response.error.metadata.order_id);
    //   alert(response.error.metadata.payment_id);
    // });

    // rzp1.open();

    displayRazorpay(options);
    // Create a new Razorpay instance
    // const rzp1 = new Razorpay(options);

    // Open the Razorpay modal
    // rzp1
    //   .open()
    //   .then((response) => {
    //     if (response?.razorpay_payment_id) {
    //       const paymentDetails = {
    //         transactional_id: prevResponse?.transactional?._id,
    //         razorpay_payment_id: response?.razorpay_payment_id,
    //         isEvent: false,
    //         order_id: orderId,
    //       };
    //       // Call the success callback function directly
    //       console.log('called modal success');
    //     }
    //   })
    //   .catch((error) => {
    //     setState({
    //       ...state,
    //       loading: false,
    //     });

    //     // Parse error message
    //     const errorMessage = JSON.parse(error?.description);

    //     // Handle error case
    //     orderCallbackError(
    //       errorMessage?.error?.description || error?.data?.message
    //     );
    //   });
  };

  /******************************************************
   * @DESC PAYMENT STEP 5: OPEN RAZOR PAY AFTER SUCCESSFULL PAYMENT
   *****************************************************/
  const razorPayPaymentSuccess = (data) => {
    try {
      if (data?.status === 201 || data?.status === 200) {
        if (selectedPaymentMethod === 'Pay At Site') {
          orderexecuteAPI(
            `${URL.USER_ORDER}/${data?.data?._id}`,
            'PATCH',
            {
              order_status: 0,
              currentStatusText: 'Pending',
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            }
          );
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            'POST',
            {
              paid: false,
              method: 'PAYLATER',
              amount: data?.data?.base_amount,
              type: 'PAYLATER',
              date: moment().valueOf(),
              paymentType: 6,
              id: data?.data?._id,
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            }
          );
        } else if (
          selectedPaymentMethod === 'Debit Card' ||
          selectedPaymentMethod === 'Credit Card' ||
          selectedPaymentMethod === 'NETBANKING' ||
          selectedPaymentMethod === 'BHIM UPI' ||
          selectedPaymentMethod === 'UPI_DEFAULT'
        ) {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            'POST',
            {
              paid: true,
              method: 'RAZORPAY',
              amount: orderPostresponseData?.base_amount,
              type: 'PAYMENT',
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem('currentOrderID'),
              transactionId:
                razorpayOrderRecordresponseData?.transactional?._id,
              txnId:
                razorpayOrderRecordresponseData?.transactional
                  ?.transactionNumber,
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            },
            () => {}
          );

          orderexecuteAPI(
            `${URL.USER_ORDER}/${sessionStorage.getItem('currentOrderID')}`,
            'PATCH',
            {
              order_status: 0,
              currentStatusText: 'Pending',
              payment_status: 1,
              transactional_id: JSON.parse(
                sessionStorage.getItem('razorpayOrderDetails')
              ).transactional?._id,
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            },
            () => {}
          );
        } else if (selectedPaymentMethod === 'WALLET') {
          transactionexecuteAPI(
            `${URL.UPDATE_TRANSACTION}`,
            'POST',
            {
              paid: true,
              method: 'WALLET',
              amount: orderPostresponseData?.base_amount,
              type: 'PAYMENT',
              date: moment().valueOf(),
              paymentType: NEW_PAYMENT_TYPE_METHOD.indexOf(
                selectedPaymentMethod
              ),
              id: sessionStorage.getItem('currentOrderID'),
              transactionId: walletPaymentresponseData?._id,
              txnId: '',
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            },
            () => {}
          );
          orderexecuteAPI(
            `${URL.USER_ORDER}/${sessionStorage.getItem('currentOrderID')}`,
            'PATCH',
            {
              order_status: 0,
              currentStatusText: 'Pending',
              payment_status: 1,
              transactional_id: JSON.parse(
                sessionStorage.getItem('razorpayOrderDetails')
              ).transactional?._id,
            },
            {
              Authorization: sessionStorage.getItem('accessToken'),
            },
            () => {}
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const displayRazorpay = async (options) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      console.log('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);
    rzp1.open();

    // If you want to retreive the chosen payment method.
    rzp1.on('payment.submit', (response) => {
      // paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on('payment.failed', (response) => {
      // paymentId.current = response.error.metadata.payment_id;
      console.log('payment failed ', response);
    });

    // to open razorpay checkout modal.
  };

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        console.log('razorpay loaded successfully');
        resolve(true);
      };
      script.onerror = () => {
        console.log('error in loading razorpay');
        resolve(false);
      };
      document.body.appendChild(script);
    });

  return (
    <>
      {/* {isLoading ? (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <CircularProgress sx={{ marginTop: '20%' }} />
        </div>
      ) : ( */}
      <div>
        {showOtpModal ? (
          <UserRegistration
            open={showOtpModal}
            setshowOtpModal={(val) => {
              setshowOtpModal(val);
            }}
            setshowDineInModal={
              !JSON.parse(sessionStorage.getItem('order_details'))?.proceed
                ? (val) => setshowDineInModal(val)
                : null
            }
            noNavigationOnSubmit={true}
          />
        ) : null}

        {showDineInModal ? (
          <DineInModal
            open={showDineInModal}
            paxRequired={true}
            setshowDineInModal={(val) => setshowDineInModal(val)}
          />
        ) : null}
        <TitleSection
          bg={'#FFFFFF'}
          title={state?.restData?.restaurant_name}
          backRoute={`/restaurant/${ID}/cartpage`}
          className={'box-shadow-down'}
          resticon={state?.restData?.logo_url_thumbnails}
        />
        <NotificationAlert
          alert={orderPosterror?.response?.data?.message}
          variant={'error'}
        />
        <div className="p-2" style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
          <div className="bg-indigo pl-3 pb-2   payment_screen my-2">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="f12-400">Total Amount To be paid</div>
              <div className="f16-700 order_bg">
                {ORDER_TYPE_NAME[ORDER_TYPES.indexOf(orderType)]}
              </div>
            </div>
            <div className="d-flex justify-content-between pr-3 align-items-end">
              <div className="f24-700">{`₹ ${
                orderData?.total_amount || 0
              }`}</div>
              <div className="f12-700 my-2">
                Order ID : {orderData?.order_id}
              </div>
            </div>
          </div>
          <div className="p-2">
            <div className="f16-700">Wallet</div>
            <div className="bg-indigo pl-3 pb-2   payment_screen my-2">
              <div className="d-flex  pr-3 align-items-end">
                <div>
                  <Checkbox
                    checked={walletCheck}
                    onChange={() => walletSelected(walletCheck)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
                <div
                  className="f24-700"
                  style={{ color: assets.colors.primary }}
                >{`₹ ${walletAmountresponseData?.balance || 0}`}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="f12-700 text-grey">current Balance</div>
              </div>
            </div>
          </div>
          <div className="f16-700 my-1 mt-2 pl-2">UPI</div>
          <div className="p-2 pt-0  border-radius">
            {UPI_ARR.map((data) => (
              <>
                <div
                  className={`my-3 p-2 shadow  border-radius flex-between ${
                    selectedPaymentMethod
                      .split(' ')
                      .join('')
                      .trim()
                      .toUpperCase() ===
                    data.title.split(' ').join('').trim().toUpperCase()
                      ? 'border-active'
                      : ''
                  }`}
                  onClick={() => onButtonClickAction(data)}
                >
                  <div className="d-flex">
                    <div>
                      <Image
                        src={require('../../../assets/images/IconsandImages/paylater.jpeg')}
                        width={24}
                      />
                    </div>
                    <div className="f14-700 flexCenter px-3">{data.title}</div>
                  </div>
                  <div>
                    <KeyboardArrowRightRoundedIcon />
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="f16-700 my-2 pl-2">Pay With</div>
          <div className="p-2 shadow border-radius">
            {PAY_WITH_ARR.map((data) => (
              <>
                <div
                  className={`my-3 p-2   border-radius flex-between ${
                    selectedPaymentMethod
                      .split(' ')
                      .join('')
                      .trim()
                      .toUpperCase() ===
                    data.title.split(' ').join('').trim().toUpperCase()
                      ? 'border-active'
                      : ''
                  }`}
                  onClick={() => onButtonClickAction(data)}
                >
                  <div className="d-flex">
                    <div>
                      <Image
                        src={require('../../../assets/images/IconsandImages/paylater.jpeg')}
                        width={24}
                      />
                    </div>
                    <div className="f14-700 flexCenter px-3">{data.title}</div>
                  </div>
                  <div>
                    <KeyboardArrowRightRoundedIcon />
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="p-2 mt-3" style={{ paddingBottom: '70px' }}>
            <div className="f16-700 pl-2">Pay Later</div>
            <div className="p-2 pt-0  border-radius">
              {PAY_LATER_ARR.map((data) => (
                <>
                  <div
                    className={`my-3 p-2 shadow  border-radius flex-between ${
                      selectedPaymentMethod
                        .split(' ')
                        .join('')
                        .trim()
                        .toUpperCase() ===
                      data.title.split(' ').join('').trim().toUpperCase()
                        ? 'border-active'
                        : ''
                    }`}
                    onClick={() => onButtonClickAction(data)}
                  >
                    <div className="d-flex">
                      <div>
                        <Image
                          src={require('../../../assets/images/IconsandImages/paylater.jpeg')}
                          width={24}
                        />
                      </div>
                      <div className="f14-700 flexCenter px-3">
                        {data.title}
                      </div>
                    </div>
                    <div>
                      <KeyboardArrowRightRoundedIcon />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: '100',
          }}
        >
          <div className="row w-100 m-0">
            <hr className="m-0 p-0" />
            <div className="col-12 col-md-6 col-lg-4 p-0">
              <div className="d-flex justify-content-between m-4">
                <button
                  className="btn btn-outline-secondary btn-lg"
                  style={{ minWidth: '150px', borderRadius: '18px' }}
                  onClick={backButton}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger btn-lg"
                  style={{ minWidth: '150px', borderRadius: '18px' }}
                  // onClick={handleProceed}
                  //  type={'submit'}
                  disabled={disable}
                  onClick={onClickProceedAction}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
        <SuccessModals
          order_id={orderPostresponseData?._id}
          successModal={successModal}
          showSuccessModal={(e) => showSuccessModal(e)}
        />
      </div>
      {/* )} */}
    </>
  );
}

export default CheckOutPage;

const create_Razorpay_Customer_Records = async (
  formData,
  previousResponse,
  orderID,
  orderToken,
  callback,
  callback2
) => {
  let headers = {
    'Content-Type': 'application/json',
  };

  if (orderToken) {
    headers.Authorization = orderToken;
  }

  try {
    let response = await axios({
      url: `https://api.razorpay.com/v1/customers`,
      method: 'POST',
      data: formData,
      headers,
      timeout: 120000,
    });

    // Check response status and data
    if (response.status === 201 || response.status === 200) {
      // Call the success callback function
      callback(response.data, previousResponse, orderID);
    }
  } catch (error) {
    // Call the error callback function
    callback2(error);
  }
};
