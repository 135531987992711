import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';

//will be uset to get query params from the url
export const useQuery = (param) => {
  return new URLSearchParams(useLocation().search).get(param);
};

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem('accessToken'));
};

export const setAccessToken = (token) => {
  localStorage.setItem('accessToken', JSON.stringify(token));
};

export const updateAccessToken = (token) => {
  let accessToken = JSON.parse(localStorage.getItem('accessToken'));
  accessToken = token;
  localStorage.setItem('accessToken', JSON.stringify(accessToken));
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const arrayToString = (array, separator, key) => {
  let returnString = '';
  for (let index = 0; index < array?.length; index++) {
    const element = array[index];
    if (index > 0) {
      returnString = returnString + separator + ' ' + element?.[key];
    } else {
      returnString = element?.[key];
    }
  }
  return returnString;
};

export const getMinimumAmountFromPackageExperience = (data) => {
  const arrData = data || [];
  let minimumAmount = 0;
  for (let i = 0; i < arrData.length; i++) {
    if (arrData?.[i]?.status && !isEmpty(arrData?.[i]?.discountedPrice)) {
      minimumAmount =
        arrData?.[i]?.discountedPrice === 0
          ? arrData?.[i]?.price
          : arrData?.[i]?.discountedPrice;
    } else {
      minimumAmount = arrData?.[i]?.price;
    }
  }
  return minimumAmount;
};

/**
 * GET EXPIRY TIME
 */
export const getExperienceTime = (data, name) => {
  // "12 PM-5 PM"
  let todayDays = moment().format('dddd');
  let todayDate = moment().format('DD-MM-YYYY');
  let formData = {};
  if (data?.days.length > 0) {
    for (let i = 0; i <= data?.days.length; i++) {
      if (data?.days?.[i] === todayDays) {
        let getParticularData = data[data?.days?.[i]];
        for (let l = 0; l <= getParticularData?.multipleHours.length; l++) {
          if (getParticularData?.multipleHours?.[l]?.open) {
            formData = getParticularData?.multipleHours?.[l];
          }
        }
      } else if (data?.days?.[i] === todayDate) {
        let getParticularDataTwo = data[data?.days?.[i]];
        for (let l = 0; l <= getParticularDataTwo?.multipleHours.length; l++) {
          if (getParticularDataTwo?.multipleHours?.[l]?.open) {
            formData = getParticularDataTwo?.multipleHours?.[l];
          }
        }
      }
    }
  } else {
    return 'N/A';
  }
  return (
    moment(formData?.openingTime).format('hh A') +
    ' - ' +
    moment(formData?.closingTime).format('hh A')
  );
};

/**
 * GET ACTIVITY TIMING
 */
export const getActivityTimings = (data) => {
  let todayDateFormat = moment().format('dddd');
  let todayDateFormatNum = moment().format('DD-MM-YYYY');
  let expStartDate = moment(data?.startDate).format('DD-MM-YYYY');
  let expEndDate = moment(data?.endDate).format('DD-MM-YYYY');
  let timingData = data?.timings;
  let formData = timingData?.[todayDateFormat];
  let formDataTwo = timingData?.[todayDateFormatNum];
  let formDataThree = timingData?.[expStartDate];
  let foundDateInRange = moment(todayDateFormatNum).isBetween(
    data?.startDate,
    data?.endDate
  );
  if (data?.expType === 'CURATED' && expStartDate === expEndDate) {
    if (timingData?.days?.includes(expStartDate && expStartDate)) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    }
  } else if (
    data?.expType === 'CURATED' &&
    expStartDate <= todayDateFormatNum
  ) {
    if (foundDateInRange) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    } else {
      if (timingData?.days?.includes(expStartDate && expStartDate)) {
        formDataThree.days = expStartDate && expStartDate;
        return formDataThree;
      } else {
        let formDataFour = {
          days: expStartDate,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: 'N/A',
              closingTime: 'N/A',
              description: 'N/A',
            },
          ],
        };
        return formDataFour;
      }
    }
  } else if (data?.expType === 'CURATED' && todayDateFormatNum <= expEndDate) {
    if (foundDateInRange) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    } else {
      if (timingData?.days?.includes(expStartDate)) {
        formDataThree.days = expStartDate;
        return formDataThree;
      } else if (timingData?.days?.includes(todayDateFormatNum)) {
        formDataTwo.days = todayDateFormatNum;
        return formDataTwo;
      } else {
        let formDataFour = {
          days: todayDateFormatNum || expStartDate,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: 'N/A',
              closingTime: 'N/A',
              description: 'N/A',
            },
          ],
        };
        return formDataFour;
      }
    }
  } else {
    if (foundDateInRange) {
      formDataThree.days = expStartDate && expStartDate;
      return formDataThree;
    } else {
      if (timingData?.days?.includes(todayDateFormat && todayDateFormat)) {
        formData.days = todayDateFormat && todayDateFormat;
        return formData;
      } else {
        let formDataFour = {
          days: todayDateFormat,
          open: false,
          multipleHours: [
            {
              open: true,
              openingTime: 'N/A',
              closingTime: 'N/A',
              description: 'N/A',
            },
          ],
        };
        return formDataFour;
      }
    }
  }
};

export const getStatusMessage = (data, key) => {
  const Msg = [
    {
      mainText: 'Order Requested',
      subHeading1: 'Request Sent',
    },
    {
      mainText: 'Order Confirmed',
      subHeading1: 'Your order has been confirmed by the restaurant.',
    },
    {
      mainText:
        data?.order_type == 0 &&
        data?.order_status === 2 &&
        data?.order_status === 3
          ? 'Food Being Prepared'
          : data?.order_type == 0 && data?.order_status == 1
          ? 'Food Being Prepared'
          : data?.order_type == 0 &&
            data?.order_status !== 2 &&
            data?.order_status !== 3
          ? 'Food Being Packed'
          : data?.order_type === 1 && data?.order_status === 4
          ? 'Food Being Packed'
          : data?.order_type === 1 && data?.order_status === 5
          ? 'Ready To Pick Up'
          : data?.order_type === 1 && data?.order_status === 6
          ? 'On The Way'
          : data?.order_type == 2 && data?.order_status === 3
          ? 'Order Confirmed'
          : data?.order_type == 2 &&
            (data?.order_status === 5 || data?.order_status === 6)
          ? 'Ready To Pick Up'
          : data?.order_type === 7 && data?.order_status === 4
          ? 'Food Being Packed'
          : data?.order_type === 7 && data?.order_status === 5
          ? 'Ready for Delivery'
          : data?.order_type === 7 && data?.order_status === 6
          ? 'Out for Delivery'
          : 'Food Being Prepared',
      subHeading1: 'Your Order is being prepared.',
    },
    {
      mainText:
        data?.order_type == 1 && data?.order_status === 3
          ? 'Food Being Packed'
          : data?.order_type == 1 && data?.order_status === 4
          ? 'Ready To Pick Up'
          : data?.order_type == 2 && data?.order_status === 2
          ? 'Ready To Pick Up'
          : data?.order_type == 2 && data?.order_status === 3
          ? 'Food Being Packed'
          : data?.order_type == 2 && data?.order_status === 5
          ? 'Food Picked Up'
          : data?.order_type == 2 && data?.order_status === 4
          ? 'Ready For Skip the line'
          : data?.order_type == 7 && data?.order_status === 2
          ? 'Food Being Packed'
          : data?.order_type == 7 && data?.order_status === 3
          ? 'Food Being Packed'
          : data?.order_type == 7 && data?.order_status === 4
          ? 'Ready for Delivery'
          : data?.order_type == 7 && data?.order_status === 5
          ? 'Out for Delivery'
          : data?.order_type == 7 && data?.order_status === 6
          ? 'Out for Delivery'
          : data?.order_type == 1 && data?.order_status === 5
          ? 'On The Way'
          : data?.order_type == 3
          ? 'Ready To Serve'
          : data?.order_type == 0 && data?.order_status === 2
          ? 'Food Being Packed'
          : data?.order_type == 0 && data?.order_status === 4
          ? 'Ready to Pick Up'
          : data?.order_type == 1 && data?.order_status === 6
          ? 'On The Way'
          : data?.order_type == 2 && data?.order_status === 6
          ? 'Ready to Pick Up'
          : 'Food Being Packed',
      subHeading1:
        data?.order_type == 7 && data?.order_status === 4
          ? 'Your Order is being ready for delivery'
          : data?.order_type == 7 && data?.order_status === 5
          ? 'Your Order is being prepared.'
          : // : `The order has been delivered to  ${data?.order_type == 2 || data?.order_type == 3
          //   ? "Table " + data?.dine_in_details?.table_number
          //   : data?.user_details?.first_name +
          //   " " +
          //   data?.user_details?.last_name
          // }.Thank you.`
          data?.order_type == 7 && data?.order_status === 6
          ? 'Your service has been completed Enjoy the service'
          : data?.order_type == 0 && data?.order_status === 4
          ? 'Your Order is being prepared.'
          : data?.order_type == 1 && data?.order_status === 4
          ? 'Your Order is prepared.'
          : data?.order_type == 1 && data?.order_status === 5
          ? ''
          : data?.order_type == 2 && data?.order_status === 4
          ? 'Your Order is prepared.'
          : data?.order_type == 2 && data?.order_status === 5
          ? `The Order has been delivered to Table ${
              data?.dine_in_details?.table_number
                ? data?.dine_in_details?.table_number
                : ''
            }`
          : 'Your Food is being packed.',
    },
    {
      mainText:
        data?.order_type == 2 && data?.order_status === 3
          ? 'Ready To Pick Up'
          : data?.order_type == 7 && data?.order_status === 4
          ? 'Out for Delivery'
          : data?.order_type == 7 && data?.order_status === 3
          ? 'Ready for Delivery'
          : data?.order_type == 7 && data?.order_status === 5
          ? 'Food Delivered'
          : data?.order_type == 3
          ? 'Food Being Served'
          : data?.order_type == 0 && data?.order_status === 3
          ? 'Ready to Pick Up '
          : data?.order_type == 1 && data?.order_status === 3
          ? 'Ready To Pick Up'
          : data?.order_type == 1 && data?.order_status === 4
          ? 'On The Way'
          : data?.order_type == 1 && data?.order_status === 5
          ? 'Food Delivered'
          : data?.order_type == 1 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 1 && data?.order_status === 7
          ? 'Food Delivered'
          : data?.order_type == 0 && data?.order_status === 4
          ? 'Ready to Pick Up'
          : data?.order_type == 0 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 0 && data?.order_status === 7
          ? 'Food Delivered'
          : data?.order_type == 2 && data?.order_status === 4
          ? 'Food Picked Up '
          : data?.order_type == 2 && data?.order_status === 5
          ? 'Order Completed'
          : data?.order_type == 2 && data?.order_status === 6
          ? 'Food Picked Up'
          : data?.order_type == 2 && data?.order_status === 7
          ? 'Food Picked Up'
          : data?.order_type == 7 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 7 && data?.order_status === 7
          ? 'Food Delivered'
          : 'Order Completed',
      subHeading1: `The order has been delivered to  ${
        data?.order_type == 2 || data?.order_type == 3
          ? 'Table ' + data?.dine_in_details?.table_number
          : data?.user_details?.first_name + ' ' + data?.user_details?.last_name
      }.Thank you.`,
      subHeading2: ``,
    },
    {
      mainText: 'On The way',
      subHeading1: 'Your service has been completed',
      subHeading2: `Enjoy the service`,
    },
    {
      mainText:
        data?.order_type == 2 && data?.order_status === 3
          ? 'Ready To Pick Up'
          : data?.order_type == 7 && data?.order_status === 4
          ? 'Out for Delivery'
          : data?.order_type == 7 && data?.order_status === 3
          ? 'Ready for Delivery'
          : data?.order_type == 7 && data?.order_status === 5
          ? 'Food Delivered'
          : data?.order_type == 3
          ? 'Food Being Served'
          : data?.order_type == 0 && data?.order_status === 3
          ? 'Ready to Pick Up '
          : data?.order_type == 1 && data?.order_status === 3
          ? 'Ready To Pick Up'
          : data?.order_type == 1 && data?.order_status === 4
          ? 'On The Way'
          : data?.order_type == 1 && data?.order_status === 5
          ? 'Food Delivered'
          : data?.order_type == 1 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 1 && data?.order_status === 7
          ? 'Food Delivered'
          : data?.order_type == 0 && data?.order_status === 4
          ? 'Food Delivered'
          : data?.order_type == 0 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 0 && data?.order_status === 7
          ? 'Food Delivered'
          : data?.order_type == 2 && data?.order_status === 4
          ? 'Food Picked Up '
          : data?.order_type == 2 && data?.order_status === 5
          ? 'Order Completed'
          : data?.order_type == 2 && data?.order_status === 6
          ? 'Food Picked Up'
          : data?.order_type == 2 && data?.order_status === 7
          ? 'Food Picked Up'
          : data?.order_type == 7 && data?.order_status === 6
          ? 'Food Delivered'
          : data?.order_type == 7 && data?.order_status === 7
          ? 'Food Delivered'
          : 'Order Completed',
      subHeading1: `The order has been delivered to  ${
        data?.order_type == 2 || data?.order_type == 3
          ? 'Table ' + data?.dine_in_details?.table_number
          : data?.user_details?.first_name + ' ' + data?.user_details?.last_name
      }.Thank you.`,
      subHeading2: ``,
    },
    {
      mainText: 'Order Completed',
      subHeading1: 'Your service has been completed',
      subHeading2: `Enjoy the service`,
    },
    {
      mainText: 'Order Cancelled',
      subHeading1: 'Your order has been cancelled',
      subHeading2: ``,
    },
  ];

  return Msg;
};
