import React, { useState } from "react";
import { useLocation } from "react-router";
import TitleSection from "../ReusableComponent/TitleSection";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";

const HealthAndsanitation = (props) => {
  const { ID } = useParams();
  const location = useLocation();
  const restdata = location?.state?.data;

  // eslint-disable-next-line
  const [selected, setselected] = useState(1);

  return (
    <>
      <div className="health-sanitation">
        <TitleSection bg={"#FFFFFF"} title={"Health & Sanitation"} />
        <hr className="m-0" style={{ opacity: "0.1" }} />
        <div className="my-2">
          <div className="p-3">
            <div className="f18-700 my-2">Health & Hygiene</div>
            <div className="f12-500 text-align">
              Key Points about you that Customer Must Be Aware Of:
              <br /> <br /> Wash your hands thoroughly before beginning the
              daily chores, handling the food, after every break, while handling
              raw foods or waste, etc. Discourage the use of sanitizers in the
              kitchen areas and wash hands with automatic soap dispensers
              instead.
              <br />
              <br /> Avoid unnecessary contact with the food. Do not allow your
              kitchen staff to handle it with bare hands. Ask them to wear
              single-use gloves and throw them away after each use.
              <br />
              <br /> Promote good hygiene by minimizing personal contact and
              disallowing tampering or breathing over food. Do not cough,
              sneeze, or spit near the food areas and avoid coming to the
              restaurant, if unwell. Make it a point to disinfect all the
              equipment, utensils, and clean the surfaces thoroughly. Follow all
              the specific food safety instructions set by the supervisors and
              health professionals and maintain a daily personal hygiene
              checklist.
            </div>
          </div>
        </div>
        <div className="d-flex overflow-scroll hidescrollbar nowrap py-3  px-2 shadow">
          {restdata?.healthAndSanitizeEntryPointCheck && (
            <div
              className={`${
                selected === 1 && "amealioactive f14-700"
              } f14-500 mx-2`}
              onClick={() => {
                setselected(1);
              }}
            >
              Entry Point
            </div>
          )}
          {restdata?.healthAndSanitizeFacilityCheck && (
            <div
              className={`${
                selected === 2 && "amealioactive f14-700"
              } f14-500 mx-2`}
              onClick={() => {
                setselected(2);
              }}
            >
              Inside Facility
            </div>
          )}
          {restdata?.healthAndSanitizePracticesCheck && (
            <div
              className={`${
                selected === 3 && "amealioactive f14-700"
              } f14-500 mx-2`}
              onClick={() => {
                setselected(3);
              }}
            >
              Food Handling
            </div>
          )}

          {restdata?.healthAndSanitizeEmployeesCheck && (
            <div
              className={`${
                selected === 4 && "amealioactive f14-700"
              } f14-500 mx-2`}
              onClick={() => {
                setselected(4);
              }}
            >
              For Employees
            </div>
          )}
          {restdata?.healthAndSanitizeServicesCheck && (
            <div
              className={`${
                selected === 5 && "amealioactive f14-700"
              }  f14-500 mx-2`}
              onClick={() => {
                setselected(5);
              }}
            >
              Services
            </div>
          )}
        </div>
        <div className="py-2">
          {selected === 1 && (
            <div>
              {restdata?.selected_health_and_sanitization_entrypoint
                ? restdata?.selected_health_and_sanitization_entrypoint.map(
                    (data) => {
                      return (
                        <div className="row m-0 my-4 px-4" key={data?._id}>
                          <div className="col-2">
                            <Image src={data?.icon} alt="Image" width="30px" />
                          </div>
                          <div className="col-8">
                            <div className="f14-700"> {data.title} </div>
                            <div className="f14-500"> {data.description} </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
          )}
          {selected === 2 && (
            <div>
              {restdata?.selected_health_and_sanitization_facility
                ? restdata?.selected_health_and_sanitization_facility.map(
                    (data) => {
                      return (
                        <div className="row m-0 my-4 px-4" key={data?._id}>
                          <div className="col-2">
                            <Image src={data?.icon} alt="Image" width="30px" />
                          </div>
                          <div className="col-8">
                            <div className="f14-700"> {data.title} </div>
                            <div className="f14-500"> {data.description} </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
          )}
          {selected === 5 && (
            <div>
              {restdata?.selected_health_and_sanitization_health_safety
                ? restdata?.selected_health_and_sanitization_health_safety.map(
                    (data) => {
                      return (
                        <div className="row m-0 my-4 px-4" key={data?._id}>
                          <div className="col-2">
                            <Image src={data?.icon} alt="Image" width="30px" />
                          </div>
                          <div className="col-8">
                            <div className="f14-700"> {data.title} </div>
                            <div className="f14-500"> {data.description} </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
          )}
          {selected === 4 && (
            <div>
              {restdata?.selected_health_and_sanitization_employees
                ? restdata?.selected_health_and_sanitization_employees.map(
                    (data) => {
                      return (
                        <div className="row m-0 my-4 px-4" key={data?._id}>
                          <div className="col-2">
                            <Image src={data?.icon} alt="Image" width="30px" />
                          </div>
                          <div className="col-8">
                            <div className="f14-700"> {data.title} </div>
                            <div className="f14-500"> {data.description} </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
          )}
          {selected === 3 && (
            <div>
              {restdata?.selected_health_and_sanitization_food_handling
                ? restdata?.selected_health_and_sanitization_food_handling.map(
                    (data) => {
                      return (
                        <div className="row m-0 my-4 px-4" key={data?._id}>
                          <div className="col-2">
                            <Image src={data?.icon} alt="Image" width="30px" />
                          </div>
                          <div className="col-8">
                            <div className="f14-700"> {data.title} </div>
                            <div className="f14-500"> {data.description} </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default HealthAndsanitation;
