import React, { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

const useAlerts = () => {
  const [alertData, setAlertData] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' or 'error'
  });

  // Function to display a success alert
  const showSuccessAlert = (message) => {
    setAlertData({
      open: true,
      message,
      severity: 'success',
    });
  };

  // Function to display an error alert
  const showErrorAlert = (message) => {
    setAlertData({
      open: true,
      message,
      severity: 'error',
    });
  };

  // Function to handle the close event for the Snackbar
  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  // Render the Snackbar and Alert components
  const AlertComponent = (
    <Snackbar
      open={alertData.open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={alertData.severity}>
        {alertData.message}
      </Alert>
    </Snackbar>
  );

  return { showSuccessAlert, showErrorAlert, AlertComponent };
};

export default useAlerts;
