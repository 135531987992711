import React, { useState, useEffect } from 'react';
import TitleSection from '../ReusableComponent/TitleSection';
import { Image, Form } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { Checkbox, Slider, Stack, styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import CustomSwitch from '../ReusableComponent/Switch';
import { useFormik } from 'formik';
import Radio from '@mui/material/Radio';

const marks = [
  {
    value: 0,
    label: '0 ',
  },
  {
    value: 20,
    label: '1 ',
  },
  {
    value: 40,
    label: '2 ',
  },
  {
    value: 60,
    label: '3 ',
  },
  {
    value: 80,
    label: '4 ',
  },
  {
    value: 100,
    label: '5 ',
  },
];

const sortBy = [
  {
    title: 'Relevence',
    value: 0,
  },
  {
    title: 'Preparation Time',
    value: 1,
  },
  {
    title: 'Ratings',
    value: 2,
  },
  {
    title: 'Price',
    value: 3,
  },
];

const Personalisation = [
  { title: 'Salt Level', value: false, key: 'salt_level' },
  { title: 'Sugar Level', value: false, key: 'sugar_level' },
  { title: 'Spice Level', value: false, key: 'spice_level' },
  { title: 'Fat/Oil Level', value: false, key: 'fat_level' },
  { title: 'Meat Doneness', value: false, key: 'meat_cook_level' },
  { title: 'Egg Style', value: false, key: 'egg_cook_level' },
];

const Availability = [
  { title: 'All Day', value: false },
  { title: 'BreakFast', value: false },
  { title: 'Lunch', value: false },
  { title: 'Dinner', value: false },
  { title: 'Meat Doneness', value: false },
  { title: 'Supper', value: false },
];

const MenuFilter = ({ handleClose, applyFilter, payloadData, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const restdata = props?.data;
  const [selected, setSelected] = useState(1);
  const [foodTypes, setFoodTypes] = useState([]);
  const [cusines, setCusines] = useState([]);
  useEffect(() => {
    if (restdata !== 'undefined' || restdata !== null) {
      let newarr = [];
      for (let i of restdata?.selected_food_type) {
        newarr.push(i.title);
      }
      setFoodTypes(newarr);

      newarr = [];
      for (let i of restdata?.selected_cusine) {
        newarr.push(i.title);
      }
      setCusines(newarr);
    }
  }, [restdata]);

  // eslint-disable-next-line
  const [
    cancelFilter,
    responseDataFilter,
    errorFilter,
    loadedFilter,
    resetFilter,
    executeFilterAPI,
  ] = useAxios();

  useEffect(() => {
    if (restdata !== undefined && restdata !== null) {
      executeFilterAPI(
        `${URL.MENU_FILTER_DATA}?vendor_id=${restdata?.vendorId}&filter=true`,
        'GET',
        null
      );
    }
  }, []);

  useEffect(() => {
    if (payloadData) {
      setFilterState(payloadData);
    }
  }, [payloadData]);

  function valuetext(value) {
    return `${value}stars`;
  }

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value);
  }

  const Ratingsslider = styled(Slider)({
    color: '#fc5a47',
  });

  const [filterState, setFilterState] = useState({
    sortBy: '',
    food_type: [],
    cuisin_type: [],
    allergy_tag: [],
    rating_range: '0-5',
    price_range: '1-250',
    personalization: {
      salt_level: false,
      spice_level: false,
      sugar_level: false,
      fat_level: false,
      meat_cook_level: false,
      egg_cook_level: false,
    },
    availability: [],
    exclide_item_not_available: false,
    search: '',
  });

  // const formik = useFormik({
  //   initialValues: {
  //     sortBy: 1,
  //     food_type: [],
  //     cuisin_type: [],
  //     allergy_tag: [],
  //     rating_range: "0-5",
  //     price_range: "1-250",
  //     personalization: {
  //       salt_level: false,
  //       spice_level: false,
  //       sugar_level: false,
  //       fat_level: false,
  //       meat_cook_level: false,
  //       egg_cook_level: false,
  //     },
  //     availability: [],
  //     exclide_item_not_available: false,
  //     search: "",
  //   },
  //   validationSchema:
  //     stage === 0 || stage === 2
  //       ? userDetailsValidationSchema
  //       : stage === 1
  //       ? otpValidationSchema
  //       : null,
  //   onSubmit: async (value) => {
  //     var formData = {
  //       country_code: value.country_code,
  //       mobile_number: value.mobile_number,
  //       first_name: value.mobile_number,
  //       last_name: value.last_name,
  //     };
  //     if (
  //       (stage === 2 && prevMobile == value?.mobile_number) ||
  //       (stage === 2 && prevCountryCode == value?.country_code)
  //     ) {
  //       setStage(1);
  //     } else if (stage === 1) {
  //       otpExecuteAPI(
  //         `${URL.OTP_AUTHENTICATION}?user_id=${responseData?.user_id}&OTP=${value?.otp}`,
  //         "GET"
  //       );
  //     } else if (stage === 0) {
  //       await executeAPI(`${URL.OTP_AUTHENTICATION}`, "POST", formData);
  //     } else if (stage === 3) {
  //       const data = {
  //         vendor_id: restaurantData?.vendorId || restaurantData?.vendor_id?._id,
  //         kids_count: value.kids ? value.kids : 0,
  //         adult_count: value.adults ? value.adults : 0,
  //         handicap_chair: value.handicap ? value.handicap : 0,
  //         high_chair: value.highchair ? value.highchair : 0,

  //         seating_preference: value.seating_preference,
  //         occasion: value.occasion.replace(/[\r\n]+/gm, " "),
  //         user_details: {
  //           country_code: value.country_code,
  //           mobile_number: value.mobile_number,
  //           name: value.first_name + " " + value.last_name,
  //         },
  //         service_type: "SEATING",
  //         same_party_size: false,
  //         pointOfEntry: "USER",
  //         table_number: "",
  //         noLocationData: true,
  //       };
  //       await dinerExecuteAPI(`${URL.DINER}`, "POST", data);
  //     }
  //   },
  // });

  const changeHandler = (e) => {
    setFilterState({ ...filterState, [e.target.name]: e.target.value });
  };

  const [allergyNutrition, setAllergyNutrition] = useState({
    allergy: false,
    nutrition: false,
  });

  const handlePersonalization = (e) => {
    setFilterState({
      ...filterState,
      personalization: {
        ...filterState?.personalization,
        [e.target.name]: !filterState?.personalization?.[e.target.name],
      },
    });
  };

  const handleArrayChange = (event) => {
    try {
      let newArray =
        [...filterState[event?.target?.name], event?.target?.value] || [];
      if (filterState[event?.target?.name]?.includes(event?.target?.value)) {
        newArray = newArray.filter((id) => id !== event?.target?.value);
      }
      changeHandler({
        target: {
          name: event?.target?.name,
          value: newArray,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const ResetFilter = () => {
    setFilterState({
      sortBy: 0,
      food_type: [],
      cuisin_type: [],
      allergy_tag: [],
      rating_range: '0-5',
      price_range: '1-250',
      personalization: {
        salt_level: false,
        spice_level: false,
        sugar_level: false,
        fat_level: false,
        meat_cook_level: false,
        egg_cook_level: false,
      },
      availability: [],
      exclide_item_not_available: false,
      search: '',
    });
  };

  return (
    <>
      <TitleSection
        bg={'#FFFFFF'}
        title={'Sort & Filters'}
        backButton={handleClose}
        rightText="Reset"
        resetfun={ResetFilter}
      />
      <div className="row m-0" style={{ maxWidth: '480px' }}>
        <div
          className="col-5"
          style={{ boxShadow: '0px 0px 6px #00000029', height: '80vh' }}
        >
          <div
            className={`py-2 px-2 ${
              selected === 1 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(1);
            }}
          >
            Sort By
            <div className="f12-500 primary-color">
              {sortBy[filterState.sortBy]?.title}
            </div>
          </div>
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 2 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(2);
            }}
          >
            Food Type
          </div>
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 3 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(3);
            }}
          >
            Cuisine
          </div>
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 4 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(4);
            }}
          >
            Allergy / Nutrition Info
          </div>
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 5 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(5);
            }}
          >
            Ratings
          </div>
          {/* <div
            className={`f14-500 py-3 px-2 ${
              selected === 6 ? "selected-box f14-700" : "f14-500"
            }`}
            onClick={() => {
              setSelected(6);
            }}
          >
            Price
          </div> */}
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 7 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(7);
            }}
          >
            Personalization
          </div>
          <div
            className={`f14-500 py-3 px-2 ${
              selected === 8 ? 'selected-box f14-700' : 'f14-500'
            }`}
            onClick={() => {
              setSelected(8);
            }}
          >
            Availability
          </div>
        </div>{' '}
        <div className="col-7" style={{ height: '80vh', overflowY: 'scroll' }}>
          {selected === 1 && (
            <div className="py-4">
              <div className="f16-700 "> Sort By</div>
              <div className="my-2">
                {sortBy?.map((e) => (
                  <div className="my-4" key={e?.value}>
                    <Radio
                      id="sortBy"
                      onChange={changeHandler}
                      name="sortBy"
                      value={e.value}
                      label={e.title}
                      checked={filterState.sortBy == e.value}
                      sx={{
                        color: '#fc5a47',
                        '&.Mui-checked': {
                          color: '#fc5a47',
                        },
                      }}
                    />
                    <span className="f14-500">{e?.title || ''}</span>
                    {/* <label className="enRadioWithText small mr-3">
                      <span className="f14-500">{e?.title || ""}</span>
                      <input
                        id="sortBy"
                        type="radio"
                        name="sortBy"
                        value={filterState.sortBy || 0}
                        onChange={changeHandler}
                        checked={formik.values.itemSize === "SINGLE"}
                      />
                      <span className="checkmark"></span>
                    </label> */}
                  </div>
                ))}
              </div>
            </div>
          )}
          {selected === 2 && (
            <div className="py-4">
              <div className="f16-700 ">Food Type</div>
              <div className="my-3">
                {responseDataFilter?.[1]?.foodType?.map((data) => {
                  return (
                    <div key={data?.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleArrayChange}
                            style={{ color: '#EE3A23' }}
                            name={'food_type'}
                            value={data?.id}
                            checked={filterState?.food_type?.includes(data?.id)}
                          />
                        }
                        label={data?.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selected === 3 && (
            <div className="py-4">
              <div className="f16-700 ">Cuisine</div>
              <div className="my-3">
                {responseDataFilter?.[2]?.cuisinType?.map((data) => {
                  return (
                    <div key={data?.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: '#EE3A23' }}
                            onChange={handleArrayChange}
                            name={'cuisin_type'}
                            value={data?.id}
                            checked={filterState?.cuisin_type?.includes(
                              data?.id
                            )}
                          />
                        }
                        label={data?.name}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {selected === 4 && (
            <div className="py-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="f16-700">Allergy Info</div>
                <div className="my-3">
                  <CustomSwitch
                    value={allergyNutrition?.allergy}
                    onChange={() =>
                      setAllergyNutrition({
                        ...allergyNutrition,
                        allergy: !allergyNutrition?.allergy,
                      })
                    }
                  />
                </div>
              </div>
              <div className="f10-400">
                Exclude dishes which have following ingredients from below - I
                am allergic to them
              </div>

              <div className="my-3">
                {allergyNutrition?.allergy &&
                  responseDataFilter?.[4]?.allergy_tag?.map((data) => {
                    return (
                      <div key={data?.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ color: '#EE3A23' }}
                              onChange={handleArrayChange}
                              name={'allergy_tag'}
                              value={data?.id}
                              checked={filterState?.allergy_tag?.includes(
                                data?.id
                              )}
                            />
                          }
                          label={data?.name}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="mt-3 d-flex align-items-center justify-content-between">
                <div className="f16-700">Nutrition Info</div>
                <div className="my-3">
                  <CustomSwitch
                    value={allergyNutrition?.allergy}
                    onChange={() =>
                      setAllergyNutrition({
                        ...allergyNutrition,
                        allergy: !allergyNutrition?.allergy,
                      })
                    }
                  />
                </div>
              </div>
              <div className="f10-400">
                Show dishes which have nutrition info only{' '}
              </div>
            </div>
          )}
          {selected === 5 && (
            <div className="py-4">
              <div className="f16-700 ">Ratings & Reviews</div>
              <div className="f14-500 opacity-50">
                Shows items of rating as per your selection and above{' '}
              </div>
              <div className="my-3">
                <Stack sx={{ height: 300 }} spacing={1} direction="row">
                  <Ratingsslider
                    orientation="vertical"
                    aria-label="Restricted values"
                    defaultValue={20}
                    valueLabelFormat={valueLabelFormat}
                    getAriaValueText={valuetext}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={marks}
                    color="secondary"
                  />
                </Stack>
              </div>
            </div>
          )}
          {selected === 7 && (
            <div className="py-4">
              <div className="f16-700 ">Personalization</div>
              <div className="f14-500 opacity-50">
                Select an option as per your preference{' '}
              </div>
              <div className="my-3">
                {Personalisation?.map((data) => {
                  return (
                    <div key={data}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: '#EE3A23' }}
                            onChange={handlePersonalization}
                            name={data?.key}
                            value={data?.value}
                            checked={filterState?.personalization?.[data?.key]}
                          />
                        }
                        label={data?.title}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selected === 8 && (
            <div className="py-4">
              <div className="f16-700 ">Availability</div>
              <div className="f14-500 opacity-50">
                Show Items available per selection{' '}
              </div>
              <div className="my-3">
                {responseDataFilter?.[0]?.availability?.map((data) => {
                  return (
                    <div key={data}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleArrayChange}
                            name={'availability'}
                            value={data}
                            style={{ color: '#EE3A23' }}
                            checked={filterState?.availability?.includes(data)}
                          />
                        }
                        label={data}
                      />
                    </div>
                  );
                })}
              </div>
              <div></div>
            </div>
          )}
        </div>
      </div>
      <hr className="greyhr m-0 " />
      <div
        className="d-flex container"
        style={{ position: 'absolute', bottom: '0' }}
      >
        <button
          className="btn btn-danger py-2 my-2 text-center w-100 text-white f16-500"
          style={{ borderRadius: '12px' }}
          onClick={() => applyFilter(filterState)}
        >
          Apply Filters
        </button>
      </div>
    </>
  );
};
export default MenuFilter;
