import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { NOTAVAILABLEIMAGE } from '../../common/enums/GlobalEnums';
import ReactPlayer from 'react-player';
import AddButton from './Buttons';

const MenuCard = ({
  hide,
  viewType,
  display,
  item,
  storedItems,
  setStoredItems,
  orderSelected,
  restData,
  setPriceAlertModal,
}) => {
  return (
    <>
      {viewType === 3 && display ? null : (
        <div>
          <div className="menu-card">
            <div className="d-flex justify-content-between px-2">
              <div className="d-flex align-items-center justify-content-evenly">
                <Image
                  src={require(`../../assets/images/IconsandImages/${
                    item?.veg ? 'vegindicator.png' : 'nonveg_indicator.png'
                  }`)}
                  alt="Image"
                  className="star_rating"
                />
                {item?.primary_tag && item?.primary_tag.length ? (
                  <div className="mx-2">
                    <span
                      className="py-0 px-2  f10-500"
                      style={{
                        border: '0.5px solid #4CAF50',
                        borderRadius: '10px',
                        color: '#4CAF50',
                        backgroundColor: '#4CAF5026',
                      }}
                    >
                      {item?.primary_tag[0].title}
                    </span>
                  </div>
                ) : null}
              </div>
              {/* <div>
              <Image
                src={require("../../assets/images/IconsandImages/heart.png")}
                alt="Image"
                width="20px"  
                className="mx-1"
              />
              <Image
                src={require("../../assets/images/IconsandImages/videocam.png")}
                alt="Image"
                width="20px" 
                className="mx-1" 
                
              />
            </div> */}
            </div>
            <div className="row m-0">
              <div className="col-4 p-2 flexCenter">
                <Image
                  src={item?.images?.[0] || NOTAVAILABLEIMAGE}
                  alt="Image"
                  width="96px"
                  height="96px"
                  style={{
                    borderRadius: '20px',
                  }}
                />
              </div>
              <div className="col-8 d-flex px-2 align-items-center pt-2">
                <div className="col-12">
                  <div className="f16-700 ">{item?.name}</div>
                  <div className="d-flex">
                    <div className="d-flex ">
                      {item.rating ? (
                        <div className="pr-1">
                          <span>
                            <Image
                              src={require('../../assets/images/IconsandImages/warningstar.png')}
                              alt="Image"
                              width="12px"
                              fluid
                            />
                          </span>
                          <span className="f14-500 opacity- mx-1">
                            {item?.rating || 0}
                          </span>
                        </div>
                      ) : null}

                      <div className="px-1 ">
                        <span>
                          <Image
                            src={require('../../assets/images/IconsandImages/timer.png')}
                            alt="Image"
                            width="12px"
                            fluid
                          />
                        </span>
                        <span className="f14-500 opacity-50 mx-1">
                          {item?.prepTime || 0} min
                        </span>
                      </div>
                      {item?.spice_level?.value && (
                        <div className="px-1 ">
                          <span className="f14-500 opacity-50 mx-1">
                            {item?.spice_level?.level}
                          </span>
                          <span>
                            <Image
                              src={require('../../assets/images/IconsandImages/chili.png')}
                              alt="Image"
                              width="12px"
                              fluid
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="f16-700  d-flex align-items-center justify-content-between pt-2">
                    <div>₹ {item?.size?.[0]?.price || 0}</div>
                    <div>
                      {' '}
                      {hide ? null : (
                        <AddButton
                          item={item}
                          storedItems={storedItems}
                          setStoredItems={setStoredItems}
                          orderSelected={orderSelected}
                          restData={restData}
                          setPriceAlertModal={setPriceAlertModal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const MenuCardLarge = ({ item }) => {
  return (
    <div>
      <div className="menu-card-large">
        <div className="w-100">
          <Image
            src={item?.images[0] || NOTAVAILABLEIMAGE}
            alt="Image"
            //width="100%"
            style={{
              borderRadius: '12px',
              maxHeight: '200px',
              maxWidth: '100%',
              height: 'auto',
              width: '100%',
              objectFit: 'cover',
            }}
            className="text-center"
          />
          <div
            className="d-flex align-items-center justify-content-start px-3"
            style={{ marginTop: '-32px' }}
          >
            <Image
              src={require(`../../assets/images/IconsandImages/${
                item?.veg ? 'vegindicator.png' : 'nonveg_indicator.png'
              }`)}
              alt="Image"
              width="14px"
              height="14px"
              style={{ backgroundColor: 'white' }}
            />
            {/* {item?.primary_tag && item?.primary_tag.length ? (
                <div className="mx-2">
                  <span
                    className="py-0 px-2  f10-500"
                    style={{
                      border: "0.5px solid #4CAF50",
                      borderRadius: "10px",
                      color: "#4CAF50",
                      backgroundColor: "#4CAF5026",
                    }}
                  >
                    {item?.primary_tag[0].title}
                  </span>
                </div>
              ) : null} */}
          </div>
        </div>
        <div>
          <div className="p-3">
            <div className="f16-700 my-2">{item?.name}</div>
            <div className="d-flex my-2">
              <div className="d-flex ">
                <div className="pr-2">
                  <span>
                    <Image
                      src={require('../../assets/images/IconsandImages/warningstar.png')}
                      alt="Image"
                      width="12px"
                      fluid
                    />
                  </span>
                  <span className="f14-500 opacity- mx-1">
                    {item?.rating || 0}
                  </span>
                </div>
                <div className="px-1 ">
                  <span>
                    <Image
                      src={require('../../assets/images/IconsandImages/timer.png')}
                      alt="Image"
                      width="12px"
                      fluid
                    />
                  </span>
                  <span className="f14-500 opacity-50 mx-1">
                    {item?.prepTime || 0} min
                  </span>
                </div>
                {item?.spice_level?.value && (
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require('../../assets/images/IconsandImages/chili.png')}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.spice_level?.level}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="f14-700 my-2 pr-2">
                ₹ {item?.size.length ? item?.size[0].price : '0'}
              </div>
              <div className="px-1 ">
                <span>
                  <Image
                    src={require('../../assets/images/IconsandImages/servesprimary.png')}
                    alt="Image"
                    width="16px"
                    fluid
                  />
                </span>
                <span className="f14-500 opacity-50 mx-1">
                  Serves {item?.size.length ? item?.size[0].pax : '0'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FullcardView = ({ item }) => {
  return (
    <>
      <div>
        <div className="menu-card-large">
          <div
            className="w-100 full-screem-image-menu"
            style={{
              backgroundImage: `url(${item?.images[0] || NOTAVAILABLEIMAGE})`,
              height: '70vh',
              borderRadius: '12px',
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          >
            {item?.videos.length ? (
              // Render the video if a video URL is present
              // <ReactPlayer
              //   url={item.videos[0]}
              //   style={{
              //     width: "100%",
              //     height: "100%",
              //     objectFit: "cover",
              //   }}
              //   controls

              // />
              <video
                src={item.videos[0]}
                alt="Video"
                style={{
                  width: '100vw',
                  height: '100vh',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                //autoPlay
                controls
              />
            ) : (
              // Render the image if no video URL is present
              <img
                src={item?.images[0] || NOTAVAILABLEIMAGE}
                alt="Image"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            )}
          </div>
          <div>
            <div className="p-3">
              <div className="f16-700 my-2">{item?.name}</div>
              <div className="d-flex my-2">
                <div className="d-flex ">
                  <div className="pr-1">
                    <span>
                      <Image
                        src={require('../../assets/images/IconsandImages/warningstar.png')}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity- mx-1">
                      {item?.rating || 0}
                    </span>
                  </div>
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require('../../assets/images/IconsandImages/timer.png')}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.prepTime || 0} min
                    </span>
                  </div>
                  {item?.spice_level?.value && (
                    <div className="px-1 ">
                      <span className="f14-500 opacity-50 mx-1">
                        {item?.spice_level?.level}
                      </span>
                      <span>
                        <Image
                          src={require('../../assets/images/IconsandImages/chili.png')}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="f14-700 my-2">
                ₹ {item?.size.length ? item?.size[0].price : '0'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuCard;
