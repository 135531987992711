import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './setup/routes-manager';
import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import SocketContext from './setup/socket/SocketContext';
import { useEffect } from 'react';

function App() {
  const host = process.env.REACT_APP_BASE_URL;
  let socket = io(host, {
    jsonp: false,
    reconnection: true,
    reconnectionDelay: 100,
    reconnectionAttempts: 5000,
    forceNew: true,
    pingInterval: 10000,
    pingTimeout: 50000,
    transports: ['websocket'],
  });
  const data = JSON.parse(sessionStorage.getItem('userDetails'));
  let app = feathers();
  app.configure(socketio(socket));
  app.configure(
    feathers.authentication({
      storage: localStorage,
      path: 'authentication',
    })
  );

  const _reAuthenticate = async () => {
    if (data && data.accessToken) {
      app.authentication.setAccessToken(data.accessToken);
      // setAuthenticationToken(data.accessToken);
      app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log('Socket Re-Authenticated');
        })
        .catch((e) => {
          console.log('error', e);
        });
    } else {
      console.log('Feather Token Not Found');
    }
  };

  useEffect(() => {
    _reAuthenticate();
  }, [app]);

  return (
    <SocketContext.Provider value={app}>
      <div className="row w-100 m-0">
        <div className="col-12 col-md-3 col-lg-4 p"></div>
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <Router>
            <Routes />
          </Router>
        </div>
        <div className="col-12 col-md-3 col-lg-4 p-0"></div>
      </div>
    </SocketContext.Provider>
  );
}

export default App;
