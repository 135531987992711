import React, { useState, useEffect } from "react";
import TitleSection from "../ReusableComponent/TitleSection";
import { Image } from "react-bootstrap";
import Button from "@mui/material/Button";
import MenuCard from "../ReusableComponent/menuCard";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import CountDown from "../ReusableComponent/countdownTimer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WaitListDetails from "./WaitListDetails";
import SocketContext from "../../setup/socket/SocketContext";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import RecommendedFoodItems from "./RecommendedFoodItems";
import VideoPlayer from "./VideoPlayer";
import { ViewMenuBar } from "./../ReusableComponent/downloadar";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import TrackScreenQr from "./TrackScreenQr";
import Slide from "@mui/material/Slide";

const seatingReqIcons = {
  PENDING: require("../../assets/images/IconsandImages/reqpending.png"),
  NOTSEATED: require("../../assets/images/IconsandImages/reqconfirmed.png"),
  SEATED: require("../../assets/images/IconsandImages/reqconfirmed.png"),
  COMPLETED: require("../../assets/images/IconsandImages/completed.png"),
  CANCELLED: require("../../assets/images/IconsandImages/cancelled.png"),
  REJECTED: require("../../assets/images/IconsandImages/cancelled.png"),
};

 const RequestMsg = {
  PENDING: "Request Pending",
  NOTSEATED: "Your Seating Is Awaited",
  SEATED: "You Have Been Seated",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
 }

const TrackScreenMain = (props) => {
  const [data, setData] = useState([]);
  const { restaurantId, dinerId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [recFood, setRecFood] = useState([]);
  const [promoVideos, setPromoVidos] = useState([]);
  const [open, SetOpen] = useState(false);

  const [openPopup, setOpenPopup] = useState(false);
  const handleClickOpen = () => {
    setOpenPopup(true);
  };
  const handleClose = () => {
    setOpenPopup(false);
  };

  const[openQr,setQrmodal]= useState(false);
  const handleClickOpenQr = () => {
    setQrmodal(true);
  };
  const handleCloseQr = () => {
    setQrmodal(false);
  };

  useEffect(() => {
    setData(location?.state?.data || {});
  }, [location?.state?.data]);

  const dinerData = data ? data : [];

  const updatedTime = new Date(
    dinerData?.wait_time !== undefined
      ? dinerData?.wait_time_date
      : dinerData?.reservationTime
  );
  const currentTimeTwo = new Date().getTime();
  const updatedTimeNew = updatedTime.getTime();
  const waitTime =
    dinerData?.wait_time !== undefined ? dinerData?.wait_time * 60 * 1000 : 0;
  let updateWaitTime = updatedTimeNew + waitTime;
  let diff = updateWaitTime - currentTimeTwo;

  useEffect(() => {
    if (props?.socket) {
      props.socket.authentication.app.authentication.setAccessToken(
        sessionStorage.getItem("accessToken")
      );
      props.socket.authentication.app.authentication
        .reAuthenticate()
        .then((r) => {
          console.log("Socket Re-Authenticated");
        })
        .catch((e) => {
          console.log("error", e);
        });
    }
    props.socket.service("diner").on("diner_trigger", (_data) => {
      // if (_data?.diner_id == data.diner_id) {
      setData(_data);
      //}
    });
  }, []);

  const [restaurantData, setRestaurantData] = useState({});

  const [
    // eslint-disable-next-line
    restCancel,
    restResponseData,
    // eslint-disable-next-line
    restError,
    // eslint-disable-next-line
    restLoaded,
    // eslint-disable-next-line
    restReset,
    restExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (
      sessionStorage.getItem("restaurantData") &&
      restaurantId == JSON.parse(sessionStorage.getItem("restaurantData"))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem("restaurantData")));
    } else {
      restExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        "GET",
        null
      );
    }
    const isModalShown = sessionStorage.getItem(`isModalShown_${dinerId}`);
    if (!isModalShown) {
      setOpenPopup(true);
      sessionStorage.setItem(`isModalShown_${dinerId}`, true);
    }
  }, []);

  useEffect(() => {
    if (
      restaurantId !=
        JSON.parse(sessionStorage.getItem("restaurantData"))?._id &&
      restResponseData
    ) {
      sessionStorage.setItem(
        "restaurantData",
        JSON.stringify(restResponseData)
      );
      setRestaurantData(restResponseData);
    }
  }, [restResponseData]);

  const [
    // eslint-disable-next-line
    dinerCancel,
    dinerResponseData,
    // eslint-disable-next-line
    dinerError,
    // eslint-disable-next-line
    dinerLoaded,
    // eslint-disable-next-line
    dinerReset,
    dinerExecuteAPI,
  ] = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    if (dinerId) dinerExecuteAPI(`${URL.DINER}/${dinerId}`, "GET", null);
  }, [dinerId]);

  useEffect(() => {
    if (dinerId && dinerResponseData) setData(dinerResponseData);
  }, [dinerResponseData]);

  const [
    // eslint-disable-next-line
    recommendFoodCancel,
    recommendFoodResponseData,
    // eslint-disable-next-line
    recommendFoodError,
    // eslint-disable-next-line
    recommendFoodLoaded,
    // eslint-disable-next-line
    recommendFoodReset,
    recommendFoodExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (data.vendor_id)
      recommendFoodExecuteAPI(
        `${URL.RECOMMENDED_FOOD}?vendor_id=${data.vendor_id}`,
        "GET",
        null,
        {
          Authorization: sessionStorage.getItem("accessToken"),
        }
      );
  }, [data.vendor_id]);

  useEffect(() => {
    if (data.vendor_id && recommendFoodResponseData)
      setRecFood(recommendFoodResponseData);
  }, [recommendFoodResponseData]);

  const [
    // eslint-disable-next-line
    promotionalvideosCancel,
    promotionalvideosResponseData,
    // eslint-disable-next-line
    promotionalvideosError,
    // eslint-disable-next-line
    promotionalvideosLoaded,
    // eslint-disable-next-line
    promotionalvideosReset,
    promotionalvideosExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (data.vendor_id)
      promotionalvideosExecuteAPI(`${URL.PROMOTIONAL_VIDEOS}`, "GET", null, {
        Authorization: sessionStorage.getItem("accessToken"),
      });
  }, [data.vendor_id]);

  useEffect(() => {
    if (promotionalvideosResponseData) setLoading(false);
    setPromoVidos(promotionalvideosResponseData);
  }, [promotionalvideosResponseData]);

  // useEffect(() => {
  //   if (data?.diner_status === "NOTSEATED") {
  //     setOpenPopup(true);
  //   }
  // }, [data?.diner_status]);

  const shareData = {
    title: "Here is a best place I found on Amealio - Download and Explore",
    text: `I found this great place  on Amealio, with this app I save time and money at my convenience. You can Discover, explore, engage order and much more on this platform seamlessly. `,
    url: `iheiudhd`,
  };

  useEffect(() => {
    sessionStorage.setItem("TrackScreen", true);
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", width: "100%", height: "100%" }}>
          <CircularProgress sx={{ marginTop: "20%" }} />
        </div>
      ) : (
        <>
          <TitleSection
            title={restaurantData?.restaurant_name || ""}
            subtitle={`REQUEST ID: ${data?.diner_id}`}
            resticon={restaurantData?.logo_url_thumbnails}
            className={"box-shadow-down"}
            backRoute={`/restaurant/${restaurantId}`}
            //  shareIcon={require("../../assets/images/IconsandImages/share.png")}
            callicon={true}
            //  shareObj={shareData}
          />
          <div>
            <div className="row mx-0 bg-indigo py-2">
              <div className="col-6 d-flex justify-content-between">
                <div className="text-center">
                  <Image
                    src={require("../../assets/images/IconsandImages/schedule.png")}
                    alt="icon"
                    width="25px"
                    height="25x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f14-700">
                    {moment(data?.reservationTime).format("DD MMM YYYY")}
                  </div>
                </div>
                <div className="text-center">
                  <Image
                    src={require("../../assets/images/IconsandImages/clock.png")}
                    alt="icon"
                    width="25px"
                    height="25x"
                    style={{ maxWidth: "100%" }}
                  />
                  <div className="f14-700">
                    {moment(data?.reservationTime).format("hh:mm A")}
                  </div>
                </div>
              </div>
              <div className="col-6 flexCenter">
                <div className="f14-700 text-indigo">
                  {data?.diner_status !== "PENDING" && (
                    <>
                    <CountDown waitNew={diff > 0 ? diff : 0} />
                    </>
                  )}
                  
                </div>
              </div>
            </div>
            <div className="row mx-0 bg-p2 py-2">
              <div className="d-flex justify-content-between">
                <div className="text-start">
                  <div className="f14-700 opacity-50">Order References:</div>
                  <div className="f14-700">
                    {data?.service_type === "RESERVATION"
                      ? "Reservation"
                      : data?.isWalkIn
                      ? "Walk-in"
                      : "WaitList"}
                  </div>
                </div>
                <div onClick={handleClickOpenQr}>
                  <Image
                    src={require("../../assets/images/IconsandImages/viewqr.png")}
                    alt="icon"
                    width="52px"
                    // height="25x"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
            </div>
            <div className="m-3">
              <VideoPlayer videosData={promoVideos} />
            </div>
            {recFood.length > 0 ? (
              <>
                <div className="f18-700 mb-3 px-3">Recommended Food</div>
                <RecommendedFoodItems item={recFood} />
              </>
            ) : null}
            <div className="px-3">
              <WaitListDetails data={data} />
            </div>
            <div className="mt-3" style={{ marginBottom: "10rem" }}>
              {" "}
              <Image
                src={require("../../assets/images/IconsandImages/referearn.png")}
                fluid
                width="100%"
                style={{ maxWidth: "100%" }}
              />
              <div className="m-4"></div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                position: "fixed",
                bottom: 0,
                width: "100%",
                zIndex: "100",
              }}
            >
              <ViewMenuBar />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="p-2 primary-bg-color d-flex justify-content-between nestedjustify-between"
                  style={{
                    borderRadius: "8px 8px 0 0",
                    //   position:"fixed",bottom:"0"
                  }}
                >
                  <div className="row m-0 w-100">
                    <div className="col-8">
                    <div className="d-flex justify-content-start align-items-center">
                    <Image
                      src={seatingReqIcons[data.diner_status]}
                      alt="icon"
                      width="32px"
                      roundedCircle
                      className="p-1"
                      style={{ maxWidth: "100%", backgroundColor: "#ffffff" }}
                    />
                    <span className="f12-500 text-white mx-2 ">
                      {/* {data?.diner_status} */}
                      {RequestMsg[data?.diner_status]}
                    </span>
                  </div>
                    </div>
                    <div className="col-4 nowrap flexCenter">
                    <div
                      className="f12-500 text-white"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        SetOpen(!open);
                      }}
                    >
                      {" "}
                      View Updates
                    </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {data?.auditLogs ? (
                    <>
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                          },
                        }}
                      >
                        {data?.auditLogs.map((data) => {
                          return (
                            <TimelineItem key={data._id}>
                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <div className="f12-400">{data?.initiator}</div>
                                <div className="f14-700">{data?.message}</div>
                                <div className="f10-400">
                                  {" "}
                                  {moment(data?.timestamp).format(
                                    "DD MMM YYYY"
                                  )}{" "}
                                  {"|"}{" "}
                                  {moment(data?.timestamp).format("hh:mm A")}
                                </div>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    </>
                  ) : null}
                </AccordionDetails>
              </Accordion>
            </div>
            <ConfirmPopup
              openPopup={openPopup}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              data={data}
            />
            <TrackScreenQr 
             open={openQr}
             handleCloseQr={handleCloseQr}
             data={data}
             />
          </div>
        </>
      )}
    </>
  );
};

const ConfirmPopup = ({ openPopup, handleClickOpen, handleClose,data }) => {
  return (
    <>
      <Dialog
        open={openPopup}
        // TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div className="f16-700">
            {" "}
            {/* {data?.service_type === "SEATING" && !data?.isWalkIn
              ? "You Have Been Waitlisted!"
              : "Your Request Has Been Placed!"} */}
              Your Request Has Been Placed!
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="f14-500">
            Download Amealio to explore exciting features and experiences
          </div>
        </DialogContent>
        <DialogActions>
          <div className="row w-100 m-0">
            <div className="col-12 col-md-6 col-lg-4 p-0">
              <div className="d-flex justify-content-between m-2">
                <button
                  // className="primary-bg-color px-3 py-1 my-1 text-center w-100 text-white f10-500"
                  className="btn btn-outline-secondary f12-500 shadow nowrap mx-1 px-1"
                  style={{ minWidth: "120px", borderRadius: "12px" }}
                  onClick={handleClose}
                >
                  Track Request
                </button>

                <a href={URL.USER_APP_DOWNLOAD_LINK} targer="_blank">
                  <button
                    className="btn btn-danger primary-bg-color f12-500"
                    style={{ minWidth: "120px", borderRadius: "12px" }}
                    onClick={handleClose}
                  >
                    Download
                  </button>
                </a>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TrackScreen = (props) => (
  <SocketContext.Consumer>
    {(socket) => <TrackScreenMain {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default TrackScreen;
