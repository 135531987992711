import { FLAT_CHARGE_KEYS } from '../../../common/enums/OrderEnums';
import { isEmpty } from '../../../common/utility/helperFunc';
import { getFlatCharges } from '../calculations/getFlatCharges';
export const calcTotalOriginal = (data) => {
  let eData = data.flat();
  eData = eData.filter((ele) => !ele.isCancelled && !ele.isSubstituted);
  let price = 0;
  for (let i = 0; i < eData.length; i++) {
    price = eData[i].finalPrice * eData[i].quantity + price;
  }
  return price.toFixed(2);
};

export const getDiscountPrice = (data, orderData, appliedOfferObj) => {
  let itemPrice = [];
  let price = 0;
  let percent = [];
  let discount = 0;
  let discountValues = [];
  for (let i = 0; i < data.length; i++) {
    price =
      (data[i]?.finalPrice || data[i]?.size?.price) * data[i].quantity + price;
    itemPrice.push(
      (data[i]?.finalPrice || data[i]?.size?.price) * data[i].quantity
    );
  }

  if (orderData?.offerId && appliedOfferObj) {
    let totalPrice = itemPrice && itemPrice.reduce((pV, cV) => pV + cV);
    itemPrice.forEach((e) => {
      percent.push(((e / totalPrice) * 100).toFixed(2));
    });
    if (appliedOfferObj?.type === 'PERCENTAGE') {
      let totalPercentage = (
        (totalPrice * appliedOfferObj?.discount) /
        100
      ).toFixed(2);
      if (totalPercentage >= appliedOfferObj?.maximum_discount_applied) {
        discount = appliedOfferObj?.maximum_discount_applied;
      } else {
        discount = totalPercentage;
      }
      price = totalPrice - discount;
    } else {
      discount = orderData?.discountAmount;
      price = totalPrice - discount;
    }
    percent.forEach((e, i) => {
      discountValues.push(parseFloat(itemPrice[i]) - (discount * e) / 100);
    });
  } else {
    discountValues = itemPrice;
  }

  return discountValues;
};

export const splitTaxes = (data, orderData, subscription, appliedOfferObj) => {
  try {
    let taxData = subscription?.casual_dining
      ? subscription?.casual_dining_status?.ordering?.order_general?.tax_code
      : subscription?.fast_food_dining
      ? subscription?.fast_food_dining_status?.ordering?.order_general?.tax_code
      : subscription?.multi_service_business_status?.ordering?.order_general
          ?.tax_code;
    const existingCartData = data.flat();
    let formData = { order_items: existingCartData };
    let taxesArr = [];
    let itemPrice = getDiscountPrice(
      formData.order_items,
      orderData,
      appliedOfferObj
    );

    let orderKeys = [];
    let chargeKeys = [];
    formData.gstAmount = {};
    for (let i = 0; i < formData.order_items.length; i++) {
      let keys = [];
      if (formData.order_items[i].sur_charges != undefined) {
        for (let j = 0; j < formData.order_items[i].sur_charges.length; j++) {
          let name = formData.order_items[i].sur_charges[j].name;
          if (name.split('-').length === 1) {
            let tax = taxData;
            if (tax) {
              name = `${tax.code} - ${tax.description} ${tax.category}`;
            }
          }
          let split = name.split(' ');
          let type = split[split.length - 1];
          split.pop();
          name = split.join(' ');
          if (
            type.toLowerCase() !== 'tax' &&
            !chargeKeys.includes(name) &&
            type.toUpperCase() !== 'DELIVERY' &&
            orderData?.delivery_partner !== 2
          ) {
            chargeKeys.push(name);
          }
          keys.push(name);
          if (taxesArr.includes(name)) {
            if (formData.order_items[i].sur_charges[j].flag === 'PERCENTAGE') {
              formData.gstAmount[name] += Number.parseFloat(
                (
                  (itemPrice[i] *
                    formData.order_items[i].sur_charges[j].value) /
                  100
                ).toFixed(2)
              );
            } else {
              formData.gstAmount[name] += Number.parseFloat(
                (
                  formData.order_items[i].sur_charges[j].value *
                  formData.order_items[i].quantity
                ).toFixed(2)
              );
            }
          } else {
            taxesArr.push(name);
            if (formData.order_items[i].sur_charges[j].flag === 'PERCENTAGE') {
              formData.gstAmount[name] = Number.parseFloat(
                (
                  (itemPrice[i] *
                    formData.order_items[i].sur_charges[j].value) /
                  100
                ).toFixed(2)
              );
            } else {
              formData.gstAmount[name] = Number(
                (
                  formData.order_items[i].sur_charges[j].value *
                  formData.order_items[i].quantity
                ).toFixed(2)
              );
            }
          }
        }
      }
      orderKeys.push(keys);
    }

    let flatCharges = getFlatCharges(subscription, orderData?.order_type);

    let price = 0;
    for (let i = 0; i < data.length; i++) {
      price = data[i].finalPrice * data[i].quantity + price;
      if (data[i].sur_charges != undefined) {
        for (let charge of data[i].sur_charges) {
          if (
            flatCharges.keys.length &&
            flatCharges.keys.includes(charge.code || charge.name?.split('-'))
          ) {
          }
        }
      }
    }

    let sur_Charges = Number(
      (
        Number(flatCharges.value) +
        (Number(flatCharges.percentage) * price) / 100
      ).toFixed(2)
    );

    let surCharges = {};
    if (sur_Charges !== 0) {
      let chargesArr = [];
      let orderType = FLAT_CHARGE_KEYS[orderData.order_type];
      for (let i = 0; i < flatCharges.taxes.length; i++) {
        let split = flatCharges.taxes[i].tax_name.split(' ');
        let cat = split.pop();
        let name = split.join(' ');
        if (
          !(
            orderData?.delivery_partner == 2 && cat?.toUpperCase() == 'DELIVERY'
          )
        ) {
          if (chargesArr.includes(name)) {
            if (flatCharges.taxes[i][orderType]) {
              surCharges[name] += parseFloat(
                flatCharges.taxes[i][orderType]
              ).toFixed(2);
            }
          } else {
            if (flatCharges.taxes[i][orderType]) {
              chargesArr.push(name);
              surCharges[name] = parseFloat(
                flatCharges.taxes[i][orderType]
              ).toFixed(2);
            }
          }
        }
      }
    }

    let delKey = [];
    for (let sKey of flatCharges.keys) {
      let check = [];
      for (let oKey of orderKeys) {
        if (oKey.includes(sKey)) {
          check.push(true);
        } else {
          check.push(false);
        }
      }
      if (!check.includes(false)) {
        delKey.push(sKey);
        delete surCharges[sKey];
      }
    }

    for (let charge of chargeKeys) {
      if (Object.keys(surCharges).includes(charge)) {
        surCharges[charge] =
          parseFloat(surCharges[charge]) +
          parseFloat(formData.gstAmount[charge]);
        delete formData.gstAmount[charge];
      } else {
        surCharges[charge] = formData.gstAmount[charge];
        delete formData.gstAmount[charge];
      }
    }

    let gstTotal = Object.values(formData.gstAmount).reduce(
      (a, b) => a + Number(parseFloat(b)?.toFixed(2)),
      0
    );
    let surTotal = Object.values(surCharges).reduce(
      (a, b) => Number(a) + Number(parseFloat(b)?.toFixed(2)),
      0
    );

    return {
      gst: formData?.gstAmount || {},
      gstTotal: gstTotal,
      surCharges: surCharges,
      surTotal: surTotal,
    };
  } catch (err) {
    console.error(err);
  }
};

export const calcFinalTotal = (data, orderData, offerData, subscription) => {
  let itemPrice = [];
  let price = 0;
  let percent = [];
  let discount = 0;
  let deliveryDiscount = 0;
  let appliedOffer = {};
  appliedOffer = offerData?.find(
    (e) => e.coupon_code === orderData?.coupon_code
  );
  data = data.flat();
  let cartData = data.filter((ele) => !ele.isCancelled && !ele.isSubstituted);
  for (let i = 0; i < cartData.length; i++) {
    price =
      (cartData[i]?.finalPrice || cartData[i]?.size?.price) *
        cartData[i].quantity +
      price;
    itemPrice.push(
      (cartData[i].finalPrice || cartData[i]?.size?.price) *
        cartData[i].quantity
    );
  }

  if (orderData?.isOfferApplied && data.length) {
    if (orderData?.type !== 'DELIVERY') {
      let totalPrice = itemPrice && itemPrice.reduce((pV, cV) => pV + cV);
      itemPrice.forEach((e) => {
        percent.push(((e / totalPrice) * 100).toFixed(2));
      });
      if (orderData?.type === 'PERCENTAGE' && offerData) {
        let offerDiscount = offerData.find(
          (e) => e.coupon_code === orderData?.coupon_code
        );
        let totalPercentage = (
          (totalPrice * orderData?.discountAmount) /
          100
        ).toFixed(2);
        if (totalPercentage >= offerDiscount?.maximum_discount_applied) {
          discount = offerDiscount?.maximum_discount_applied;
        } else {
          discount = totalPercentage;
        }
      } else {
        discount = orderData?.discountAmount;
      }
    }
    if (
      orderData?.isDelivery &&
      orderData?.deliveryMinOrder <= calcTotalOriginal(data)
    ) {
      deliveryDiscount =
        orderData?.deliveryCharges?.user - orderData?.deliveryMaxDiscount > 0
          ? orderData?.deliveryMaxDiscount
          : orderData?.deliveryCharges?.user;
    }
  }

  let finalTotal = parseFloat(price);
  finalTotal = finalTotal - discount;
  console.log(finalTotal, discount);

  if (orderData?.tip?.amount) {
    finalTotal = finalTotal + orderData.tip.amount;
  }
  if (orderData?.donation?.amount) {
    finalTotal = finalTotal + orderData.donation.amount;
  }
  if (orderData?.deliveryCharges?.user) {
    finalTotal =
      finalTotal + (orderData?.deliveryCharges?.user - deliveryDiscount);
  }

  let taxes = splitTaxes(cartData, orderData, subscription, appliedOffer);

  finalTotal = finalTotal + Number(taxes?.gstTotal) + Number(taxes?.surTotal);

  return { total: finalTotal?.toFixed(2) || 0, taxes: taxes };
};

export const checkOfferValidOrNot = (
  offerArr,
  currentCouponCode,
  orderData
) => {
  let temp = offerArr.filter((item) => {
    return (
      !isEmpty(item) &&
      item?.coupon_code.toLowerCase() === currentCouponCode.toLowerCase() &&
      (item?.service_type.includes(
        orderData?.order_type === 0
          ? 'Takeaway'
          : orderData?.order_type === 1
          ? 'Curbside'
          : orderData?.order_type === 2
          ? 'Skip-The-Line'
          : orderData?.order_type === 7
          ? 'Delivery'
          : 'Dine-In'
      ) ||
        item?.service_type.includes('All'))
    );
  });
  return temp;
};

export const calcDiscount = (data, orderData, offerData) => {
  let price = 0;
  let discount = 0;
  data = data.flat();
  for (const element of data) {
    price =
      (element.finalPrice || element.size?.price) * element.quantity + price;
  }
  let finalTotal = price;
  if (orderData?.isOfferApplied) {
    if (orderData.type === 'PERCENTAGE') {
      let offerDiscount = offerData?.find(
        (e) => e.coupon_code === orderData.coupon_code
      );
      let totalPercentage = (
        (finalTotal * orderData.discountAmount) /
        100
      ).toFixed(2);
      if (totalPercentage >= offerDiscount?.maximum_discount_applied) {
        discount = offerDiscount?.maximum_discount_applied;
      } else {
        discount = totalPercentage;
      }
      return discount;
    } else {
      let discount = orderData.discountAmount.toFixed(2);
      return discount;
    }
  } else {
    return 0;
  }
};
