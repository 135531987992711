import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../common/api/hooks/useAxios';
import { URL } from '../common/api/urls';
import { CircularProgress } from '@mui/material';
import { Image } from 'react-bootstrap';
import RestaurantCard from './ReusableComponent/restaurantCard';
import Services from './ReusableComponent/services';
import DownloadBar from './ReusableComponent/downloadar';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { AnalyticPage } from '../common/enums/GlobalEnums';
import UserRegistration from './ReusableComponent/UserRegistration';
import DineInModal from './ordering/modals/dineInModal';
//import UserRegistration from "./ReusableComponent/UserRegistration";

const RestaurantHome = () => {
  const { ID } = useParams();
  const navigate = useNavigate();
  const fingerprint = getBrowserFingerprint();
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [showDineInModal, setshowDineInModal] = useState(false);
  const [state, setState] = useState({
    isLoading: true,
    restData: undefined,
    restId: ID,
  });
  const { isLoading, restId } = state;

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  // eslint-disable-next-line
  const [
    pageStatscancel,
    pageStatsresponseData,
    pageStatserror,
    pageStatsloaded,
    pageStatsreset,
    pageStatsexecuteAPI,
  ] = useAxios();

  const bottomFeatures = [
    {
      id: 1,
      icon: require('../assets/images/landingPageAssets/directions.png'),
      title: 'Directions',
      link: '#',
      opacity: 'opacity-50',
    },
    {
      id: 2,
      icon: require('../assets/images/landingPageAssets/healthAndSanitation.png'),
      title: 'Health & Safety',
      link: `/restaurant/${ID}/healthandsanitation`,
    },
    {
      id: 3,
      icon: require('../assets/images/IconsandImages/viewsquare.png'),
      title: 'View Details',
      link: `/restaurant/${ID}/restaurantdetails`,
    },

    {
      id: 4,
      icon: require('../assets/images/landingPageAssets/viewMenu.png'),
      title: 'View Menu',
      link: `/restaurant/${ID}/viewmenu`,
    },
  ];

  /**
   * On component mount fetch the restaurant details from the server
   */
  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        'GET',
        null
      );
    }
    return () => {
      //clear any async handler, timer, listener if used
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fingerprint) {
      sessionStorage.setItem('BroserId', fingerprint);
    }
  }, []);

  useEffect(() => {
    if (
      fingerprint &&
      restId !== undefined &&
      restId !== null &&
      pageStatsresponseData !== 'Captured'
    ) {
      const params = {
        deviceType: '',
        page: AnalyticPage.QR_SCAN,
        deviceId: fingerprint,
        app: 'WEB_APP',
      };
      pageStatsexecuteAPI(
        `${URL.PAGESTATS}?restaurantId=${restId}`,
        'POST',
        params
      );
    }
  }, []);

  //api response handler for get rest details
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        //do any preprocessing if required using preprocessor.js functions
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
        sessionStorage.setItem('restaurantData', JSON.stringify(responseData));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset();
      if (responseData?.orderSessionSettings?.dine_in_order) {
        sessionStorage.setItem('defaultOrderType', 'dine_in');
        sessionStorage.setItem('mainDefaultOrderType', 'dine_in');
      } else if (responseData?.orderSessionSettings?.curb_side) {
        sessionStorage.setItem('defaultOrderType', 'curb_side');
        sessionStorage.setItem('mainDefaultOrderType', 'curb_side');
      } else {
        sessionStorage.setItem('defaultOrderType', 'take_away');
        sessionStorage.setItem('mainDefaultOrderType', 'take_away');
      }
      sessionStorage.setItem('DineInSkip', 'intial');
      sessionStorage.setItem('CurbSideSkip', 'intial');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  const hanldeClicksCount = (Idvalue) => {
    if (Idvalue == 3 || Idvalue == 4) {
      if (fingerprint && restId !== undefined && restId !== null) {
        const params = {
          deviceType: '',
          page:
            Idvalue === 3
              ? AnalyticPage.RESTAURANT_VIEW
              : Idvalue === 4
              ? AnalyticPage.MENU_VIEW
              : null,
          deviceId: fingerprint,
          app: 'WEB_APP',
        };
        pageStatsexecuteAPI(
          `${URL.PAGESTATS}?restaurantId=${restId}`,
          'POST',
          params
        );
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <CircularProgress sx={{ marginTop: '20%' }} />
        </div>
      ) : (
        <>
          <div className="w-100">
            {showOtpModal ? (
              <UserRegistration
                open={showOtpModal}
                setshowOtpModal={(val) => setshowOtpModal(val)}
                setshowDineInModal={(val) => setshowDineInModal(val)}
              />
            ) : null}
            {/* {showDineInModal ? (
              <DineInModal
                open={showDineInModal}
                setshowDineInModal={(val) => setshowDineInModal(val)}
              />
            ) : null} */}
            <div className="restaurant_landingPage">
              <DownloadBar />
            </div>
            <div className="restaurant_card_main">
              <div className="row w-100 m-0">
                <div
                  className="col-12 col-md-6 col-lg-4 p-0"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '20px 20px 0 0',
                  }}
                >
                  <RestaurantCard restData={state.restData} />
                  <Services
                    id={ID}
                    setshowOtpModal={(val) => setshowOtpModal(val)}
                    setshowDineInModal={(val) => setshowDineInModal(val)}
                  />
                  <div className="bottom-features">
                    {bottomFeatures.map((data) => {
                      return (
                        <Link
                          key={data.id}
                          to={data?.link || '#'}
                          state={{ data: state.restData }}
                          onClick={() => hanldeClicksCount(data.id)}
                        >
                          <div
                            className={`text-center my-4 mx-2 ${data?.opacity}`}
                          >
                            <Image
                              alt="image"
                              title="image"
                              width="60px"
                              height="52px"
                              src={data?.icon}
                            />
                            <div
                              className="f10-400 my-1"
                              style={{ opacity: '0.8' }}
                            >
                              {data?.title}
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RestaurantHome;
