const DONATION_AMOUNT = [
  { id: 0, name: 'None', value: 0 },
  { id: 1, name: '₹ 2', value: 2 },
  { id: 2, name: '₹ 5', value: 5 },
  { id: 3, name: '₹ 10', value: 10 },
  { id: 6, name: 'Custom', value: 0 },
];

const TIP_AMOUNT = [
  { id: 0, name: 'None', value: 0 },
  { id: 1, name: '₹ 5', value: 5 },
  { id: 2, name: '₹ 10', value: 10 },
  { id: 3, name: '₹ 20', value: 20 },
  { id: 6, name: 'Custom', value: 0 },
];

const PREVIOUSLY_USED = [
  {
    id: 0,
    cardNumber: '5105105105105100',
    cardExpYear: 2027,
    cardExpMonth: 7,
    cardType: 'MASTER_CARD',
  },
  {
    id: 1,
    cardNumber: '4111111111111111',
    cardExpYear: 2026,
    cardExpMonth: 5,
    cardType: 'VISA_CARD',
  },
  {
    id: 2,
    cardNumber: '3714496353984310',
    cardExpYear: 2025,
    cardExpMonth: 3,
    cardType: 'American_Express',
  },
];

const WALLETS_ARR = [
  {
    id: 0,
    title: 'PhonePe',
    // iconName: Images.Pay_new,
    isOpenModal: false,
    linkUrl: '',
    rightButtonText: true,
    display: true,
  },
  {
    id: 1,
    title: 'Paytm',
    // iconName: Images.payment,
    isOpenModal: false,
    linkUrl: '',
    rightButtonText: true,
    display: true,
  },
  {
    id: 2,
    title: 'Amazon Pay',
    // iconName: Images.Pay_new,
    isOpenModal: false,
    linkUrl: '',
    rightButtonText: true,
    display: true,
  },
];

const PAY_WITH_ARR = [
  {
    id: 0,
    title: 'Debit Card',
    //  iconName: Images.debitCard,
    isOpenModal: false,
    linkType: 'NOLINK',
    linkUrl: 'DebitAndCreditCardScreen',
    rightButtonText: false,
    display: true,
  },
  {
    id: 1,
    title: 'Credit Card',
    // iconName: Images.creditCard2,
    isOpenModal: false,
    linkType: 'NOLINK',
    linkUrl: 'DebitAndCreditCardScreen',
    rightButtonText: false,
    display: true,
  },
  {
    id: 2,
    title: 'Net Banking',
    // iconName: Images.netBanking,
    isOpenModal: true,
    linkType: 'MODAL',
    linkUrl: '',
    rightButtonText: false,
    display: true,
  },
];

const UPI_ARR = [
  {
    id: 0,
    title: 'BHIM UPI',
    //   iconName: Images.bhimupi,
    isOpenModal: false,
    linkType: 'NOLINK',
    linkUrl: '',
    rightButtonText: false,
    display: true,
  },
];

const PAY_LATER_ARR = [
  {
    id: 0,
    title: 'Pay At Site',
    // iconName: Images.payLater,
    isOpenModal: false,
    linkType: 'NOLINK',
    linkUrl: '',
    rightButtonText: false,
    display: true,
  },
  // {
  //   id: 1,
  //   title: "LazyPay",
  //   iconName: Images.weekendSpl,
  //   linkUrl: "",
  //   rightButtonText: true,
  //   display: true,
  // },
  // {
  //   id: 2,
  //   title: "Paytm Postpaid",
  //   iconName: Images.happyBirthday,
  //   linkUrl: "",
  //   rightButtonText: true,
  //   display: true,
  // },
  // {
  //   id: 3,
  //   title: "ICICI PayLater",
  //   iconName: Images.newExpThisWeek,
  //   linkUrl: "",
  //   rightButtonText: true,
  //   display: true,
  // },
];

const NET_BANKING_OPTIONS = [
  { key: 'AUBL', value: 'AU Small Finance Bank' },
  { key: 'AIRP', value: 'Airtel Payments Bank' },
  { key: 'ALLA', value: 'Allahabad Bank' },
  { key: 'ANDB', value: 'Andhra Bank' },
  { key: 'ANDB_C', value: 'Andhra Bank - Corporate Banking' },
  { key: 'UTIB', value: 'Axis Bank' },
  { key: 'BDBL', value: 'Bandhan Bank' },
  { key: 'BBKM', value: 'Bank of Bahrein and Kuwait' },
  { key: 'BARB_R', value: 'Bank of Baroda - Retail Banking' },
  { key: 'BKID', value: 'Bank of India' },
  { key: 'MAHB', value: 'Bank of Maharashtra' },
  { key: 'BACB', value: 'Bassein Catholic Co-operative Bank' },
  { key: 'CNRB', value: 'Canara Bank' },
  { key: 'CSBK', value: 'Catholic Syrian Bank' },
  { key: 'CBIN', value: 'Central Bank of India' },
  { key: 'CIUB', value: 'City Union Bank' },
  { key: 'COSB', value: 'Cosmos Co-operative Bank' },
  { key: 'DCBL', value: 'DCB Bank' },
  { key: 'BKDN', value: 'Dena Bank' },
  { key: 'DEUT', value: 'Deutsche Bank' },
  { key: 'DBSS', value: 'Development Bank of Singapore' },
  { key: 'DLXB', value: 'Dhanlaxmi Bank' },
  { key: 'DLXB_C', value: 'Dhanlaxmi Bank - Corporate Banking' },
  { key: 'ESAF', value: 'ESAF Small Finance Bank' },
  { key: 'ESFB', value: 'Equitas Small Finance Bank' },
  { key: 'FDRL', value: 'Federal Bank' },
  { key: 'FSFB', value: 'Fincare Small Finance Bank' },
  { key: 'HDFC', value: 'HDFC Bank' },
  { key: 'ICIC', value: 'ICICI Bank' },
  { key: 'IBKL', value: 'IDBI' },
  { key: 'IBKL_C', value: 'IDBI - Corporate Banking' },
  { key: 'IDFB', value: 'IDFC FIRST Bank' },
  { key: 'IDIB', value: 'Indian Bank' },
  { key: 'IOBA', value: 'Indian Overseas Bank' },
  { key: 'INDB', value: 'Indusind Bank' },
  { key: 'JAKA', value: 'Jammu and Kashmir Bank' },
  { key: 'JSFB', value: 'Jana Small Finance Bank' },
  { key: 'JSBP', value: 'Janata Sahakari Bank (Pune)' },
  { key: 'KCCB', value: 'Kalupur Commercial Co-operative Bank' },
  { key: 'KJSB', value: 'Kalyan Janata Sahakari Bank' },
  { key: 'KARB', value: 'Karnataka Bank' },
  { key: 'KVBL', value: 'Karur Vysya Bank' },
  { key: 'KKBK', value: 'Kotak Mahindra Bank' },
  { key: 'LAVB_C', value: 'Lakshmi Vilas Bank - Corporate Banking' },
  { key: 'LAVB_R', value: 'Lakshmi Vilas Bank - Retail Banking' },
  { key: 'MSNU', value: 'Mehsana Urban Co-operative Bank' },
  { key: 'NKGS', value: 'NKGSB Co-operative Bank' },
  { key: 'NESF', value: 'North East Small Finance Bank' },
  { key: 'ORBC', value: 'PNB (Erstwhile-Oriental Bank of Commerce)' },
  { key: 'UTBI', value: 'PNB (Erstwhile-United Bank of India)' },
  { key: 'PSIB', value: 'Punjab & Sind Bank' },
  { key: 'PUNB_R', value: 'Punjab National Bank - Retail Banking' },
  { key: 'RATN', value: 'RBL Bank' },
  { key: 'RATN_C', value: 'RBL Bank - Corporate Banking' },
  { key: 'SRCB', value: 'Saraswat Co-operative Bank' },
  { key: 'SVCB_C', value: 'Shamrao Vithal Bank - Corporate Banking' },
  { key: 'SVCB', value: 'Shamrao Vithal Co-operative Bank' },
  { key: 'SIBL', value: 'South Indian Bank' },
  { key: 'SCBL', value: 'Standard Chartered Bank' },
  { key: 'SBBJ', value: 'State Bank of Bikaner and Jaipur' },
  { key: 'SBHY', value: 'State Bank of Hyderabad' },
  { key: 'SBIN', value: 'State Bank of India' },
  { key: 'SBMY', value: 'State Bank of Mysore' },
  { key: 'STBP', value: 'State Bank of Patiala' },
  { key: 'SBTR', value: 'State Bank of Travancore' },
  { key: 'SURY', value: 'Suryoday Small Finance Bank' },
  { key: 'SYNB', value: 'Syndicate Bank' },
  { key: 'TMBL', value: 'Tamilnadu Mercantile Bank' },
  { key: 'TNSC', value: 'Tamilnadu State Apex Co-operative Bank' },
  { key: 'TBSB', value: 'Thane Bharat Sahakari Bank' },
  { key: 'TJSB', value: 'Thane Janata Sahakari Bank' },
  { key: 'UCBA', value: 'UCO Bank' },
  { key: 'UBIN', value: 'Union Bank of India' },
  { key: 'CORP', value: 'Union Bank of India (Erstwhile Corporation Bank)' },
  { key: 'VARA', value: 'Varachha Co-operative Bank' },
  { key: 'YESB', value: 'Yes Bank' },
  { key: 'YESB_C', value: 'Yes Bank - Corporate Banking' },
  { key: 'ZCBL', value: 'Zoroastrian Co-operative Bank' },
];

export {
  DONATION_AMOUNT,
  TIP_AMOUNT,
  PREVIOUSLY_USED,
  WALLETS_ARR,
  PAY_WITH_ARR,
  PAY_LATER_ARR,
  UPI_ARR,
  NET_BANKING_OPTIONS,
};

const LIVE_RAZOR = process.env.LIVE_RAZOR ? true : false; //
export const ORDER_RAZORPAY_KEY = LIVE_RAZOR
  ? process.env.RAZORPAY_LIVE_KEY
  : process.env.RAZORPAY_TEST_KEY;
