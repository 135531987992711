import axios from '../axios';
import { useState, useRef } from 'react';

/**
 * Hook for all the api calls to be made before user looged in to the home page i.e dashboard
 * @param {*} url
 * @param {*} method
 * @param {*} payload
 * @returns
 */

export const useAxios = (url, method, payload) => {
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const controllerRef = useRef(new AbortController());

  //this will be used to abort the previous request if any
  const cancel = () => {
    controllerRef.current.abort();
  };

  const reset = () => {
    setLoaded(false);
    setError(null);
    setResponseData(null);
  };

  const executeAPI = async (
    url,
    method,
    payload,
    headers,
    callBack = () => {}
  ) => {
    try {
      const response = await axios.request({
        data: payload,
        signal: controllerRef.current.signal,
        method,
        url,
        headers,
      });
      setResponseData(response.data);
      callBack(response);
      return response;
    } catch (error) {
      setResponseData(null);
      setError(error);
      setTimeout(() => {
        setError(null);
      }, 5000);
    } finally {
      setLoaded(true);
    }
  };

  return [cancel, responseData, error, loaded, reset, executeAPI];
};
