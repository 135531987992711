import React from "react";
import { Image } from "react-bootstrap";
import { screenSetup } from "./ScreenSetup";

const PassDetails = ({ data }) => {
  return (
    <div className="p-2">
      <div className="d-flex align-items-center">
        <Image
          src={require("../../assets/images/ExperiencePageAssets/concert-ticket.png")}
          alt={"Ticket"}
          title="Ticket"
          width={50}
        />
        <div className="ml-2 f18-500">Pass Details</div>
      </div>
      <div
        className="px-3 py-2 single-pass-card mt-4"
        style={{
          backgroundImage: `url('${
            screenSetup[data?.expType]?.PASS?.BACKGROUND
          }')`,
        }}
      >
        <div className="row">
          <div
            className={`col-${
              screenSetup[data?.expType]?.AVAILABLE_BOOKED ? "8" : "12"
            }`}
          >
            <div className="text-indigo">Single ticket</div>
            <div className="row mt-2">
              <div
                className="col-6 p-0"
                style={{ borderRight: data?.kidsPrice ? "1px solid #A28AE4" : "none" }}
              >
                <div className="text-center">
                  <Image
                    src={require("../../assets/images/ExperiencePageAssets/family.png")}
                    width="30"
                    alt={"Package"}
                    title={"Package"}
                  />
                  <div className="f12-400">Adult</div>
                  <div>
                    <div>
                      <span className="f16-500">
                        ₹{data?.adultPrice || "0"}
                      </span>
                      <span className="f10-400"> / per person</span>
                    </div>
                  </div>
                </div>
              </div>
              {data?.kidsPrice ? (
                <div className="col-6 p-0">
                  <div className="text-center">
                    <Image
                      src={require("../../assets/images/ExperiencePageAssets/kids.png")}
                      width="30"
                      alt={"Package"}
                      title={"Package"}
                    />
                    <div className="f12-400">Kids</div>
                    <div>
                      <div>
                        <span className="f16-500">
                          ₹{data?.kidsPrice || "0"}
                        </span>
                        <span className="f10-400"> / per Child</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {screenSetup[data?.expType]?.AVAILABLE_BOOKED && (
            <>
              <div
                className="col-1 pl-2"
                style={{ maxHeight: "110px", width: "10px" }}
              >
                <Image
                  src={require("../../assets/images/ExperiencePageAssets/indigo-divider.png")}
                  height={"100%"}
                />
              </div>
              <div className="col-3 p-0 text-center d-flex justify-content-center align-items-center">
                <div>
                  <div className="f9-500 text-indigo">Available Seats</div>
                  <div className="f12-500">
                    {data?.seatsLeft || 0} <span className="f9-400">Left</span>
                  </div>
                  <div className="f9-500 text-indigo mt-2">Booked Seats</div>
                  <div className="f12-500">
                    {data?.seatsBooked || 0}{" "}
                    <span className="f9-400">Booked</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PassDetails;
