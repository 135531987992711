import React, { useEffect, useState } from 'react';
import TitleSection from '../../ReusableComponent/TitleSection';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Image } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CAR_COLORS, ORDER_TYPES } from '../../../common/enums/OrderEnums';
import { URL } from '../../../common/api/urls';
import { useAxios } from '../../../common/api/hooks/useAxios';
import { assets } from '../../../assets/assets';

const vechileTypes = [
  {
    id: 4,
    title: '4-wheeler',
    icon: require('../../../assets/images/ordericon/car.png'),
    subType: ['SUV', 'Sedan', 'Hatchback', 'Pickup'],
  },
  {
    id: 2,
    title: '2-wheeler',
    icon: require('../../../assets/images/ordericon/scooter.png'),
    subType: ['Scooter', 'Motor Cycle', 'Super Bike', 'Bicycle'],
  },
  {
    id: 3,
    title: '3-wheeler',
    icon: require('../../../assets/images/ordericon/rikshaw.png'),
    subType: ['Rikshaw', 'Piaggio APE', 'Other Tempos'],
  },
  {
    id: 0,
    title: 'Others',
    icon: require('../../../assets/images/ordericon/taxi.png'),
    subType: ['Cab', 'Bus', 'Taxi'],
  },
];

function CurbSideDetails() {
  const { ID } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [restaurantData, setRestaurantData] = useState({});
  const [selectedVehicle, setSelectedvehicle] = useState('');
  const [selectedvechileInfo, setSelectedVechileInfo] = useState('');

  const [
    // eslint-disable-next-line
    restCancel,
    restResponseData,
    // eslint-disable-next-line
    restError,
    // eslint-disable-next-line
    restLoaded,
    // eslint-disable-next-line
    restReset,
    restExecuteAPI,
  ] = useAxios();
  const [userData, setUserData] = useState(
    JSON.parse(sessionStorage.getItem('userDetails')) || []
  );
  const [userVechileInfo, setVechileInfo] = useState(
    userData?.vihicle_info || []
  );

  const defaultOrderType = sessionStorage.getItem('defaultOrderType');
  // const UserData = JSON.parse(sessionStorage.getItem('userDetails'));
  const subscriptionData = restaurantData?.subscription?.casual_dining
    ? restaurantData?.subscription?.casual_dining_status
    : restaurantData?.subscription?.fast_food_dining
    ? restaurantData?.subscription?.fast_food_dining_status
    : restaurantData?.subscription?.multi_service_business
    ? restaurantData?.subscription?.multi_service_business_status
    : {};
  const pickupPointData =
    subscriptionData?.ordering?.cubside_delivery?.pickup_location;
  const [selectedPickUpPoint, setselectedPickUpPoint] = useState(
    pickupPointData && pickupPointData.length > 0
      ? pickupPointData[0].address
      : ''
  );

  useEffect(() => {
    setselectedPickUpPoint(
      pickupPointData && pickupPointData.length > 0
        ? pickupPointData[0].address
        : ''
    );
  }, [pickupPointData]);
  useEffect(() => {
    if (
      sessionStorage.getItem('restaurantData') &&
      ID == JSON.parse(sessionStorage.getItem('restaurantData'))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem('restaurantData')));
    } else {
      restExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${ID}?lat=true&long=true`,
        'GET',
        null
      );
    }
  }, []);

  const handleskip = () => {
    navigate(-1);
    sessionStorage.setItem('CurbSideSkip', 'skipped');
  };

  const [initialValues, setInitialValues] = useState({
    user_details: {
      first_name: userData?.first_name || '',
      last_name: userData?.last_name || '',
      country_code: userData?.country_code || '',
      mobile_number: userData?.mobile_number || '',
    },
    order_type: ORDER_TYPES.indexOf(defaultOrderType),
    orderSelected: defaultOrderType,
    now: true,
    nowDate: new Date(),
    later_date: '',
    later_time: '',
    actual_date: '',
    later_formDated_date: '',
    delivery_partner: 1,
    dine_in_details: {
      table_number: '',
    },
    totalDinePax: '',
  });

  const selectVechile = (vechile, index) => {
    setSelectedvehicle(index);
    setSelectedVechileInfo(vechile);
  };

  const handleSave = () => {
    const curb_side_details = {
      vehical_type: selectedvechileInfo?.vehical_type,
      vehical_type_value: selectedvechileInfo?.vehical_type_value,
      number: selectedvechileInfo?.number,
      make: selectedvechileInfo?.make,
      model: selectedvechileInfo?.model,
      year: '',
      color: selectedvechileInfo?.color,
      pickUpPoint: pickupPointData.filter(
        (data) => data.address === selectedPickUpPoint
      )[0].name,
    };
    navigate(-1);
    sessionStorage.setItem(
      'order_details',
      JSON.stringify({ ...initialValues, curb_side_details: curb_side_details })
    );
    sessionStorage.setItem('CurbSideSkip', 'proceed');
  };
  return (
    <div>
      <TitleSection
        bg={'#FFFFFF'}
        title={restaurantData?.restaurant_name}
        backRoute={`/restaurant/${ID}`}
        className={'box-shadow-down'}
        resticon={restaurantData?.logo_url_thumbnails}
      />
      <div style={{ backgroundColor: '#EEEAFE' }} className="p-3">
        <div className="flex-even">
          <div className="flexCenter">
            {' '}
            <Image
              src={require('../../../assets/images/ordermodalassets/streering.png')}
              width={36}
            />
          </div>
          <div className="px-3">
            <div className="f20-700 my-1">Vehicle Information</div>

            <div className="f14-500 my-1">
              Please enter your vehicle number to get your order delivered near
              by your vehicle.
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '-10px' }}>
        <Image
          src={require('../../../assets/images/ordermodalassets/blueStrip.png')}
          width={'100%'}
          className="m-0 p-0"
        />
      </div>
      <Box className="p-3">
        <div className="f20-700">Select Vehicle</div>
        <Box
          className="d-flex hidescrollbar"
          gap={2}
          sx={{ width: '100%', overflow: 'auto' }}
        >
          {userVechileInfo &&
            userVechileInfo?.map((vechile, index) => {
              return (
                <div
                  className="col-8 p-2 my-2 flexCenter"
                  style={{
                    border: `1px solid ${
                      selectedVehicle === index
                        ? assets.colors.primary
                        : assets.colors.borderIndigo
                    }`,
                    borderRadius: '12px',
                  }}
                  key={vechile._id}
                  onClick={() => selectVechile(vechile, index)}
                >
                  <div className="col-8 d-flex">
                    <div className="flexCenter">
                      <Image
                        src={
                          vechileTypes.filter(
                            (data) => data.id === vechile.vehical_type
                          )[0].icon
                        }
                      />
                    </div>
                    <div className="p-2">
                      <div className="f14-700">{vechile?.number}</div>
                      <div className="d-flex justify-content-between">
                        <div className="f12-500">{vechile?.make}</div>
                        <div className="f12-500">
                          {vechile?.vehical_type_value}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 mx-1">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setOpen(true)}
                      style={{
                        borderRadius: '5px',
                        color: 'black',
                        border: '1px solid #96ade1',
                        textTransform: 'capitalize',
                      }}
                      className="bg-white mr-2"
                    >
                      Edit
                    </Button>
                  </div>
                </div>
              );
            })}
        </Box>
        <div
          className="f14-700 my-2"
          style={{ color: '#fc5a47' }}
          onClick={() => setOpen(true)}
        >
          {' '}
          + Add {userVechileInfo?.length ? 'another' : 'a'} vehicle
        </div>
      </Box>
      <Divider />
      <div className="p-3">
        <div></div>
        <div className="f20-700 my-1 d-flex">
          <div className="px-2">
            <Image
              src={require('../../../assets/images/ordermodalassets/pickUpSpot.png')}
              width={36}
            />
          </div>
          <div>
            <div> Pickup Spot</div>
            <div className="f14-500 my-1">
              Select a location for food pickup. Our team will meet you there
              with your order
            </div>
          </div>
        </div>
        <div className="my-2">
          <FormControl variant="outlined" className={`w-100`}>
            <Select
              id="curb_side_details.pickup"
              label="pickup"
              labelId="curb_side_details.pickup"
              name="curb_side_details.pickup"
              // defaultValue={
              //   pickupPointData && pickupPointData.length > 0
              //     ? pickupPointData[0].address
              //     : 'asas'
              // }
              value={selectedPickUpPoint}
              onChange={(e) => {
                setselectedPickUpPoint(e.target.value);
              }}
            >
              {pickupPointData &&
                pickupPointData.map((data, index) => (
                  <MenuItem key={index} value={data.address}>
                    {data.address}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          position: 'fixed',
          bottom: 0,
          width: '-webkit-fill-available',
        }}
        my={1}
        px={2}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={handleskip}
          style={{
            borderRadius: '15px',
            color: 'black',
            border: '1px solid #C4C4C4',
            textTransform: 'capitalize',
          }}
          className="bg-white mr-2"
          fullWidth
        >
          Skip
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: '15px',
            border: '1px solid #C4C4C4',
            color: 'white',
            backgroundColor: selectedvechileInfo
              ? assets.colors.primary
              : 'grey',
            textTransform: 'capitalize',
          }}
          disabled={!selectedvechileInfo}
          fullWidth
          onClick={handleSave}
        >
          Done
        </Button>
      </Box>
      <AddVechileDrawer
        open={open}
        setOpen={setOpen}
        userData={userData}
        setUserData={setUserData}
        setVechileInfo={setVechileInfo}
        userVechileInfo={userVechileInfo}
      />
    </div>
  );
}

export default CurbSideDetails;

const AddVechileDrawer = ({
  open,
  setOpen,
  userData,
  setUserData,
  setVechileInfo,
  userVechileInfo,
}) => {
  const [activeVechile, setActiveVechile] = useState(vechileTypes[0]);
  const [saveFuture, handleFutureSave] = useState(false);

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const validationschema = yup.object({
    number: yup
      .string()
      .matches(
        /^[A-Z]{2}\s[0-9]{1,2}\s[A-Z,0-9]{1,2}\s[0-9]{4}$/,
        'EX: AA 00 BB 0000'
      )
      .required('Vechile number is required'),
    make: yup.string().required('vechile make  is required'),
    color: yup.string().required('color  is required'),
  });

  const formik = useFormik({
    initialValues: {
      vehical_type: activeVechile.id,
      vehical_type_value: activeVechile.title,
      number: '',
      make: '',
      model: activeVechile.subType[0],
      year: '',
      color: '',
      pickUpPoint: null,
    },
    validationSchema: validationschema,
    onSubmit: (values) => {
      const vihicle_info = [...(userVechileInfo || []), values];
      if (sessionStorage.getItem('accessToken') && saveFuture) {
        executeAPI(
          `${URL.USERSERICE}/${userData?._id}`,
          'PATCH',
          {
            vihicle_info: vihicle_info,
          },
          {
            Authorization: sessionStorage.getItem('accessToken'),
          }
        );
      }
      setVechileInfo(vihicle_info);
      if (sessionStorage.getItem('accessToken')) {
        const updatedUserDetails = { ...userData, vihicle_info: vihicle_info };
        sessionStorage.setItem(
          'userDetails',
          JSON.stringify(updatedUserDetails)
        );
        setUserData(JSON.parse(sessionStorage.getItem('userDetails')));
      }
      setOpen(false);
      formik.resetForm();
    },
  });

  const handleVechileChange = (vechile) => {
    formik.setFieldValue('vehical_type', vechile.id);
    formik.setFieldValue('vehical_type_value', vechile.title);
    setActiveVechile(vechile);
    formik.setFieldValue('model', vechile.subType[0]);
  };

  const handleCancel = () => {
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Drawer anchor={'bottom'} open={open}>
      <Box className="p-3">
        <div className="f20-700 my-2 d-flex">Vehicle Information</div>
        <div className="f16-500 my-2 text-grey">
          Please enter your vehicle number to get your order delivered near by
          your vehicle.
        </div>
        <div className="d-flex my-2">
          {vechileTypes.map((vechile) => (
            <div
              onClick={() => handleVechileChange(vechile)}
              className={`p-2`}
              key={vechile.id}
            >
              <div
                className={`${
                  activeVechile.id == vechile.id ? 'selected-indigo' : null
                }`}
                style={{ borderRadius: '50%' }}
              >
                <Image
                  style={{ padding: '5px' }}
                  src={vechile.icon}
                  alt={vechile.icon}
                />
              </div>
              <div
                className={`f12-500 ${
                  activeVechile.id == vechile.id ? 'color-indigo' : null
                }`}
              >
                {vechile.title}
              </div>
            </div>
          ))}
        </div>
      </Box>
      <Box p={1}>
        <div className="my-2">
          <TextField
            id="outlined-basic"
            placeholder="Vechile Number"
            variant="outlined"
            size="small"
            name="number"
            fullWidth
            className="customTexfeild"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={formik.errors.number}
            helperText={
              formik.errors.number && formik.touched.number
                ? formik.errors.number
                : 'EX: AA 00 BB 0000'
            }
          />
        </div>
        <div className="row justify-content-between my-2">
          <div className="col-5">
            <TextField
              id="outlined-basic"
              placeholder="Vechile Make"
              variant="outlined"
              size="small"
              fullWidth
              name="make"
              className="customTexfeild"
              value={formik.values.make}
              onChange={formik.handleChange}
              error={formik.errors.make && formik.touched.make}
              helperText={
                formik.errors.make && formik.touched.make
                  ? formik.errors.make
                  : ''
              }
            />
          </div>
          <div className="col-5">
            <FormControl variant="outlined" className="w-100">
              {/* <InputLabel id="make-label">Vehicle Make</InputLabel> */}
              <Select
                labelId="make-label"
                id="make"
                placeholder="vechile make"
                label="Vehicle Make"
                name="model"
                fullWidth
                value={formik.values.model}
                onChange={formik.handleChange}
                error={formik.errors.model}
              >
                {activeVechile.subType.map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
              </Select>
              {formik.errors.model && formik.touched.model && (
                <FormHelperText error>{formik.errors.model}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
        <div>
          <div className="my-2">
            <FormControl variant="outlined" className={`w-100`}>
              <InputLabel id="curb_side_details.color">Color</InputLabel>
              <Select
                id="curb_side_details.color"
                label="Color"
                labelId="curb_side_details.color"
                name="color"
                value={formik.values.color}
                onChange={formik.handleChange}
                error={formik.errors.color && formik.touched.color}
              >
                {CAR_COLORS &&
                  CAR_COLORS.map((color) => (
                    <MenuItem value={`${color.value}`}>{color.name}</MenuItem>
                  ))}
              </Select>
              {formik.errors.color && formik.touched.color && (
                <FormHelperText error>{formik.errors.color}</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
        <div className=" d-flex align-items-center">
          <div className="px-2">
            <Checkbox
              sx={{ color: '#fc5a47' }}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={saveFuture}
              onChange={() => handleFutureSave(!saveFuture)}
            />
          </div>
          <div className="f14-500">save this vechile for future orders</div>
        </div>
      </Box>
      <hr />
      <Box
        sx={{ display: 'flex', justifyContent: 'space-evenly' }}
        my={1}
        px={2}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={() => handleCancel()}
          style={{
            borderRadius: '15px',
            color: 'black',
            border: '1px solid #C4C4C4',
            textTransform: 'capitalize',
          }}
          className="bg-white mr-2"
          fullWidth
        >
          cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: '15px',
            border: '1px solid #C4C4C4',
            color: 'white',
            backgroundColor: assets.colors.primary,
            textTransform: 'capitalize',
          }}
          fullWidth
          onClick={formik.handleSubmit}
        >
          Done
        </Button>
      </Box>
    </Drawer>
  );
};
