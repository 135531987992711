import React from 'react'
import { Image } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
    <section>
        <div className="container">
            <div className='py-4'>
                <div>
                    <Image src={require("../../assets/images/IconsandImages/amealiologowithtext.png")} width="140px" />
                </div>
            </div>
          <div className="policy-heading">
            <h2>
              <b>PRIVACY POLICY</b>
            </h2>
          </div>
          <div className="policy-paragraph">
            <p>
              This Privacy Policy (“Policy”) describes the policies and
              procedures on the collection, use, disclosure and protection of
              your information when you use our website located atAmealio.com,
              or the Amealio mobile application (collectively, “Amealio
              Platform”) made available by Envisionard Ads and Marketing Private
              Limited (“Amealio”, “Company”, “we”, “us” and “our”), a private
              company established under the laws of India having its registered
              office at T-Hub, IIIT Campus, Gacchibowli Hyderabad, Telangana
              -500032 <br /> The terms “you” and “your” refer to the user of the
              Amealio Platform. The term “Services” refers to any services
              offered by Amealio whether on the Amealio Platform or otherwise.
              Please read this Policy before using the Amealio Platform or
              submitting any personal information to Amealio. This Policy is a
              part of and incorporated within, and is to be read along with, the
              Terms of Use.
            </p>
            <p>
              <b>YOUR CONSENT</b>
            </p>
            <p>
              By using the Amealio Platform and the Services, you agree and
              consent to the collection, transfer, use, storage, disclosure and
              sharing of your information as described and collected by us in
              accordance with this Policy. If you do not agree with the Policy,
              please do not use or access the Amealio Platform.
            </p>
            <p>
              <b>POLICY CHANGES</b>
            </p>
            <p>
              We may occasionally update this Policy and such changes will be
              posted on this page. If we make any significant changes to this
              Policy we will endeavor to provide you with reasonable notice of
              such changes, such as via prominent notice on the Amealio Platform
              or to your email address on record and where required by
              applicable law, we will obtain your consent. To the extent
              permitted under the applicable law, your continued use of our
              Services after we publish or send a notice about our changes to
              this Policy shall constitute your consent to the updated Policy.
            </p>
            <p>
              <b>LINKS TO OTHER WEBSITES</b>
            </p>
            <p>
              The Amealio Platform may contain links to other websites. Any
              personal information about you collected whilst visiting such
              websites is not governed by this Policy. Amealio shall not be
              responsible for and has no control over the practices and content
              of any website accessed using the links contained on the Amealio
              Platform. This Policy shall not apply to any information you may
              disclose to any of our service providers/service personnel which
              we do not require you to disclose to us or any of our service
              providers under this Policy.
            </p>
            <p>
              <b>INFORMATION WE COLLECT FROM YOU</b>
            </p>
            <p>
              We will collect and process the following information about you:
              Information you give us - This includes information submitted when
              you: Create or update your Amealio account, which may include your
              name, email, phone number, login name and password, address,
              payment or banking information, date of birth and profile picture.
              If you sign in to the Amealio Platform through third-party sign-in
              services such as Facebook, Google Plus or Gmail or any other
              social networking or similar site (collectively, “SNS”), an option
              of which may be provided to you by Amealio at its sole discretion,
              you will be allowing us to pass through and receive from the SNS
              your log-in information and other user data; or Provide content to
              us, which may include reviews, ordering details and history,
              favorite vendors, special merchant requests, contact information
              of people you refer to us and other information you provide on the
              Amealio Platform (“Your Content”). Use our Services, we may
              collect and store information about you to process your requests
              and automatically complete forms for future transactions,
              including (but not limited to) your phone number, address, email,
              billing information and credit or payment card information.
              Correspond with Amealio for customer support;
            </p>
            <p>
              Participate in the interactive services offered by the Amealio
              Platform such as discussion boards, competitions, promotions or
              surveys, other social media functions or make payments etc., or
              Enable features that require Amealio's access to your address book
              or calendar; Report problems for troubleshooting. <br />
              If you sign up to use our Services as a merchant or a partner, we
              may collect location details, copies of government identification
              documents and other details (KYC), call and SMS details.
              Information we collect about you - With regard to each of your
              visits to the Amealio Platform, we will automatically collect and
              analyze the following demographic and other information: When you
              communicate with us (via email, phone, through the Amealio
              Platform or otherwise), we may maintain a record of your
              communication; <br />
              Location information: Depending on the Services that you use, and
              your app settings or device permissions, we may collect your real
              time information, or approximate location information as
              determined through data such as GPS, IP address; Usage and
              Preference Information: We collect information as to how you
              interact with our Services, preferences expressed, and settings
              chosen. Amealio Platform includes the Amealio advertising services
              (“Ad Services”), which may collect user activity and browsing
              history within the Amealio Platform and across third-party sites
              and online services, including those sites and services that
              include our ad pixels (“Pixels”), widgets, plug-ins, buttons, or
              related services or through the use of cookies. Our Ad Services
              collect browsing information including without limitation your
              Internet protocol (IP) address and location, your login
              information, browser type and version, date and time stamp, user
              agent, Amealio cookie ID (if applicable), time zone setting,
              browser plug-in types and versions, operating system and platform,
              and other information about user activities on the Amealio
              Platform, as well as on third party sites and services that have
              embedded our Pixels, widgets, plug-ins, buttons, or related
              services; Transaction Information: We collect transaction details
              related to your use of our Services, and information about your
              activity on the Services, including the full Uniform Resource
              Locators (URL), the type of Services you requested or provided,
              comments, domain names, search results selected, number of clicks,
              information and pages viewed and searched for, the order of those
              pages, length of your visit to our Services, the date and time you
              used the Services, amount charged, details regarding application
              of promotional code, methods used to browse away from the page and
              any phone number used to call our customer service number and
              other related transaction details; Device Information: We may
              collect information about the devices you use to access our
              Services, including the hardware models, operating systems and
              versions, software, file names and versions, preferred languages,
              unique device identifiers, advertising identifiers, serial
              numbers, device motion information and mobile network information.
              Analytics companies may use mobile device IDs to track your usage
              of the Amealio Platform; Stored information and files: Amealio
              mobile application (Amealio app) may also access metadata and
              other information associated with other files stored on your
              mobile device. This may include, for example, photographs, audio
              and video clips, personal contacts and address book information.
              If you permit the Amealio app to access the address book on your
              device, we may collect names and contact information from your
              address book to facilitate social interactions through our
              services and for other purposes described in this Policy or at the
              time of consent or collection. If you permit the Amealio app to
              access the calendar on your device, we collect calendar
              information such as event title and description, your response
              (Yes, No, Maybe), date and time, location and number of attendees.
              If you are a partner restaurant, merchant or a partner, we will,
              additionally, record your calls with us made from the device used
              to provide Services, related call details, SMS details location
              and address details.
              <br />
              Information we receive from other sources -
            </p>
            <p>
              We may receive information about you from third parties, such as
              other users, partners (including ad partners, analytics providers,
              search information providers), or our affiliated companies or if
              you use any of the other websites/apps we operate or the other
              Services we provide. Users of our Ad Services and other
              third-parties may share information with us such as the cookie ID,
              device ID, or demographic or interest data, and information about
              content viewed or actions taken on a third-party website, online
              services or apps. For example, users of our Ad Services may also
              be able to share customer list information (e.g., email or phone
              number) with us to create customized audience segments for their
              ad campaigns. When you sign in to Amealio Platform with your SNS
              account, or otherwise connect to your SNS account with the
              Services, you consent to our collection, storage, and use, in
              accordance with this Policy, of the information that you make
              available to us through the social media interface. This could
              include, without limitation, any information that you have made
              public through your social media account, information that the
              social media service shares with us, or information that is
              disclosed during the sign-in process. Please see your social media
              provider’s privacy policy and help center for more information
              about how they share information when you choose to connect your
              account. If you are partner restaurant, merchant or a partner, we
              may, additionally, receive feedback and rating from other users.
            </p>
            <p>
              <b>COOKIES</b>
            </p>
            <p>
              Our Amealio Platform and third parties with whom we partner, may
              use cookies, pixel tags, web beacons, mobile device IDs, “flash
              cookies” and similar files or technologies to collect and store
              information with respect to your use of the Services and
              third-party websites. <br />
              Cookies are small files that are stored on your browser or device
              by websites, apps, online media and advertisements. We use cookies
              and similar technologies for purposes such as: Authenticating
              users; Remembering user preferences and settings; <br />
              Determining the popularity of content; <br />
              Delivering and measuring the effectiveness of advertising
              campaigns; <br />
              Analyzing site traffic and trends, and generally understanding the
              online behaviors and interests of people who interact with our
              services. <br />
              A pixel tag (also called a web beacon or clear GIF) is a tiny
              graphic with a unique identifier, embedded invisibly on a webpage
              (or an online ad or email), and is used to count or track things
              like activity on a webpage or ad impressions or clicks, as well as
              to access cookies stored on users’ computers. We use pixel tags to
              measure the popularity of our various pages, features and
              services. We also may include web beacons in e-mail messages or
              newsletters to determine whether the message has been opened and
              for other analytics. <br />
              To modify your cookie settings, please visit your browser’s
              settings. By using our Services with your browser settings to
              accept cookies, you are consenting to our use of cookies in the
              manner described in this section. We may also allow third parties
              to provide audience measurement and analytics services for us, to
              serve advertisements on our behalf across the Internet, and to
              track and report on the performance of those advertisements. These
              entities may use cookies, web beacons, SDKs and other technologies
              to identify your device when you visit the Amealio Platform and
              use our Services, as well as when you visit other online sites and
              services. <br />
              Please see our Cookie Policy above for more information regarding
              the use of cookies and other technologies described in this
              section, including regarding your choices relating to such
              technologies. <br />
            </p>
            <p>
              <b>USES OF YOUR INFORMATION</b>
            </p>
            <p>
              We use the information we collect for following purposes,
              including:
            </p>
            <p>
              To provide, personalize, maintain and improve our products and
              services, such as to enable deliveries and other services, enable
              features to personalize your Amealio account;
            </p>
            <p>
              To carry out our obligations arising from any contracts entered
              into between you and us and to provide you with the relevant
              information and services;
            </p>
            <p>
              To administer and enhance the security of our Amealio Platform and
              for internal operations, including troubleshooting, data analysis,
              testing, research, statistical and survey purposes;
            </p>
            <p>
              To provide you with information about services we consider similar
              to those that you are already using, or have enquired about, or
              may interest you. If you are a registered user, we will contact
              you by electronic means (e-mail or SMS or telephone) with
              information about these services;
            </p>
            <p>
              To understand our users (what they do on our Services, what
              features they like, how they use them, etc.), improve the content
              and features of our Services (such as by personalizing content to
              your interests), process and complete your transactions, make
              special offers, provide customer support, process and respond to
              your queries; To generate and review reports and data about, and
              to conduct research on, our user base and Service usage patterns;
              To allow you to participate in interactive features of our
              Services, if any; or To measure or understand the effectiveness of
              advertising we serve to you and others, and to deliver relevant
              advertising to you.
            </p>
            <p>
              If you are a partner restaurant or merchant or partner, to track
              the progress of food prep or status of the order placed by our
              customers.
            </p>
            <p>
              We may combine the information that we receive from third parties
              with the information you give to us and information we collect
              about you for the purposes set out above. Further, we may
              anonymize and/or de-identify information collected from you
              through the Services or via other means, including via the use of
              third-party web analytic tools. As a result, our use and
              disclosure of aggregated and/or de-identified information is not
              restricted by this Policy, and it may be used and disclosed to
              others without limitation. <br />
              We analyze the log files of our Amealio Platform that may contain
              Internet Protocol (IP) addresses, browser type and language,
              Internet service provider (ISP), referring, app crashes, page
              viewed and exit websites and applications, operating system,
              date/time stamp, and clickstream data. This helps us to administer
              the website, to learn about user behavior on the site, to improve
              our product and services, and to gather demographic information
              about our user base as a whole.
            </p>
            <p>
              <b>DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</b>
            </p>
            <p>
              We may share your information that we collect for following
              purposes: <br />
              With Service Providers: We may share your information with our
              vendors, consultants, marketing partners, research firms and other
              service providers or business partners, such as Payment processing
              companies, to support our business. For example, your information
              may be shared with outside vendors to send you emails and messages
              or push notifications to your devices in relation to our Services,
              to help us analyze and improve the use of our Services, to process
              and collect payments. We also may use vendors for other projects,
              such as conducting surveys or organizing sweepstakes for us. With
              Partner Restaurants/Merchant: While you place a request to order
              food through the Amealio Platform, your information is provided to
              us and to the restaurants/merchants with whom you may choose to
              order. In order to facilitate your online food order processing,
              we provide your information to that restaurant/merchant in a
              similar manner as if you had made a food order directly with the
              restaurant. If you provide a mobile phone number, Amealio may send
              you text messages regarding the order’s status.
            </p>
            <p>
              With Other Users: If you are a partner, we may share your name,
              phone number and/or profile picture (if applicable), tracking
              details with other users to provide them the Services. For Crime
              Prevention or Investigation: We may share this information with
              governmental agencies or other companies assisting us, when we
              are: <br />
              Obligated under the applicable laws or in good faith to respond to
              court orders and processes; or Detecting and preventing against
              actual or potential occurrence of identity theft, fraud, abuse of
              Services and other illegal acts;
            </p>
            <p>
              Responding to claims that an advertisement, posting or other
              content violates the intellectual property rights of a third
              party; <br />
              Under a duty to disclose or share your personal data in order to
              enforce our Terms of Use and other agreements, policies or to
              protect the rights, property, or safety of the Company, our
              customers, or others, or in the event of a claim or dispute
              relating to your use of our Services. This includes exchanging
              information with other companies and organizations for the
              purposes of fraud detection and credit risk reduction. For
              Internal Use: We may share your information with any present or
              future member of our “Group” (as defined below)or affiliates for
              our internal business purposes The term “Group” means, with
              respect to any person, any entity that is controlled by such
              person, or any entity that controls such person, or any entity
              that is under common control with such person, whether directly or
              indirectly, or, in the case of a natural person, any Relative (as
              such term is defined in the Companies Act, 1956 and Companies Act,
              2013 to the extent applicable) of such person.
            </p>
            <p>
              With Advertisers and advertising networks: We may work with third
              parties such as network advertisers to serve advertisements on the
              Amealio Platform and on third-party websites or other media (e.g.,
              social networking platforms). These third parties may use cookies,
              JavaScript, web beacons (including clear GIFs), Flash LSOs and
              other tracking technologies to measure the effectiveness of their
              ads and to personalize advertising content to you. While you
              cannot opt out of advertising on the Amealio Platform, you may opt
              out of much interest-based advertising on third party sites and
              through third party ad networks (including DoubleClick Ad
              Exchange, Facebook Audience Network and Google AdSense). For more
              information, visit www.aboutads.info/choices. Opting out means
              that you will no longer receive personalized ads by third party’s
              ad networks from which you have opted out, which is based on your
              browsing information across multiple sites and online services. If
              you delete cookies or change devices, you opt out may no longer be
              effective. <br />
              To fulfill the purpose for which you provide it. We may share your
              information other than as described in this Policy if we notify
              you and you consent to the sharing.
            </p>
            <p>
              <b>DATA SECURITY PRECAUTIONS</b>
            </p>
            <p>
              We have in place appropriate technical and security measures to
              secure the information collected by us. We use vault and
              tokenization services from third party service providers to
              protect the sensitive personal information provided by you. The
              third-party service providers with respect to our vault and
              tokenization services and our payment gateway and payment
              processing are compliant with the payment card industry standard
              (generally referred to as PCI compliant service providers). You
              are advised not to send your full credit/debit card details
              through unencrypted electronic platforms. Where we have given you
              (or where you have chosen) a username and password which enables
              you to access certain parts of the Amealio Platform, you are
              responsible for keeping these details confidential. We ask you not
              to share your password with anyone. <br />
              Please we aware that the transmission of information via the
              internet is not completely secure. Although we will do our best to
              protect your personal data, we cannot guarantee the security of
              your data transmitted through the Amealio Platform. Once we have
              received your information, we will use strict physical,
              electronic, and procedural safeguards to try to prevent
              unauthorized access.
            </p>
            <p>
              <b>OPT-OUT</b> <br />
              When you sign up for an account, you are opting in to receive
              emails and communications fromAmealio. You can log in to manage
              your communication and email preferences under notifications or
              you can follow the "unsubscribe" instructions in commercial email
              messages but note that you cannot opt out of receiving certain
              administrative notices, service notices, or legal notices
              fromAmealio. Restricting the Collection of your Personal Data
            </p>
            <p>
              At some point, you might wish to restrict the use and collection
              of your personal data. When you are filling the details on the
              website/app, make sure to check if there is a box which you can
              leave unchecked, if you don't want to disclose your personal
              information.If you wish to withdraw your consent for the use and
              disclosure of your personal information in the manner provided in
              this Policy, please write to us at [_________________ (mail/phone
              no.)]. Please note that we may take time to process such requests,
              and your request shall take effect no later than 5 (Five) business
              days from the receipt of such request, after which we will not use
              your personal data for any processing unless required by us to
              comply with our legal obligations. We may not be able offer you
              any or all Services upon such withdrawal of your consent. <br />
              Amealio will not lease, sell or distribute your personal
              information to any third parties, unless we have your permission.
              We might do so if the law forces us. Your personal information
              will be used when we need to send you promotional materials if you
              agree to this privacy policy.
            </p>
            <p>
              <b>GRIEVANCE OFFICER AND AMEALIO PLATFORM SECURITY</b> <br />
              If you have any queries relating to the processing or usage of
              information provided by you in connection with this Policy, please
              email us at support@amealio.com or write to our Grievance Officer
              at the following address:AmealioGrievance Officer
            </p>
            <p>
              If you come across any abuse or violation of the Policy, please
              report to grievances@amealio.com
            </p>
            <p>
              <b>GRIEVANCE OFFICER</b> <br />
              In accordance with the Information Technology Act, 2000 and Rules
              made thereunder, the name andcontact details of the Grievance
              Officer are as follows:
            </p>
            <p>
              Name: Raghav Mamidipalli <br />
              Address: Registered office at No. T-Hub, Gachibowli, Hyderabad,
              Telangana 500032 <br />
              Mobile number: 949085808 <br />
              E-mail address support@amealio.com <br />
            </p>
          </div>
        </div>
        <hr  className="mt-4"/>
        <div className="container">
        <div className=" mt-2 mb-4">
            <div  className="text-center" >
            Hiranandani Fortune City, Panvel, Navi Mumbai, Maharashtra 410221, India
            </div>
            <div className="text-center primary-color">
            Copyright 2022 Envisionard Corporation
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy