import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
// import OtpInput from "react-otp-input";
import { useFormik } from 'formik';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import SeatingRequest from './SeatingRequest';
import moment from 'moment';
import OTPInput from './OTPInput';
import { AnalyticPage } from '../../common/enums/GlobalEnums';

const userDetailsValidationSchema = yup.object({
  country_code: yup.string().required('Country Code is required.'),
  mobile_number: yup
    .string()
    .required('Mobile Number is required')
    .length(10, 'Mobile Number should be 10 digits'),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .required('email id is required')
    .email('Invalid email address'),
});

const otpValidationSchema = yup.object({
  otp: yup
    .string()
    .required('OTP is required.')
    .length(6, 'Otp should be 6 digits'),
});

const reservationValidationSchema = yup.object({
  reservationTime: yup.string().required('Reservation Time is required'),
  adults: yup
    .number()
    .required('Minimum 1 adult is required.')
    .min(1, 'Minimum 1 adult is required.'),
});
const seatingValidationSchema = yup.object({
  adults: yup
    .number()
    .required('Minimum 1 adult is required.')
    .min(1, 'Minimum 1 adult is required.'),
});

const SeatingUserInfo = () => {
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const location = useLocation();
  const requestType = location?.pathname?.includes('reservation')
    ? 'RESERVATION'
    : 'SEATING';
  const [restaurantData, setRestaurantData] = useState({});

  // eslint-disable-next-line
  const [
    restCancel,
    restResponseData,
    restError,
    restLoaded,
    restReset,
    restExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (
      sessionStorage.getItem('restaurantData') &&
      restaurantId == JSON.parse(sessionStorage.getItem('restaurantData'))?._id
    ) {
      setRestaurantData(JSON.parse(sessionStorage.getItem('restaurantData')));
    } else {
      restExecuteAPI(
        `${URL.RESTAURANT_DETAILS}/${restaurantId}?lat=true&long=true`,
        'GET',
        null
      );
    }
  }, []);

  useEffect(() => {
    if (
      restaurantId !=
        JSON.parse(sessionStorage.getItem('restaurantData'))?._id &&
      restResponseData
    ) {
      sessionStorage.setItem(
        'restaurantData',
        JSON.stringify(restResponseData)
      );
      setRestaurantData(restResponseData);
    }
  }, [restResponseData]);

  const [stage, setStage] = React.useState(0);

  const goBack = () => {
    navigate(-1);
  };

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();
  // eslint-disable-next-line
  const [
    pageStatscancel,
    pageStatsresponseData,
    pageStatserror,
    pageStatsloaded,
    pageStatsreset,
    pageStatsexecuteAPI,
  ] = useAxios();
  const [
    // eslint-disable-next-line
    optCancel,
    otpResponseData,
    otpError,
    // eslint-disable-next-line
    otpLoaded,
    // eslint-disable-next-line
    otpReset,
    otpExecuteAPI,
  ] = useAxios();

  const [
    // eslint-disable-next-line
    dinerCancel,
    dinerResponseData,
    // eslint-disable-next-line
    dinerError,
    // eslint-disable-next-line
    dinerLoaded,
    // eslint-disable-next-line
    dinerReset,
    dinerExecuteAPI,
  ] = useAxios();

  const [
    userDetailsCancel,
    userDetailsResponseData,
    userDetailsError,
    userDetailsLoaded,
    userDetailsReset,
    userDetailsExecuteAPI,
  ] = useAxios();

  const [prevMobile, setPrevMobile] = useState();
  const [prevCountryCode, setPrevCountryCode] = useState();

  const formik = useFormik({
    initialValues: {
      country_code: '+91',
      mobile_number: '',
      first_name: '',
      last_name: '',
      otp: '',

      adults: 1,
      kids: 0,
      HandicapSwitch: false,
      handicap: 0,
      highchair: 0,
      seating_preference: 'N/A',
      occasion: '',
      roomNumber: '',

      reservationDate: moment().format('YYYY-MM-DD'),
      timings: [],
      reservationTime: '',
    },
    validationSchema:
      stage === 0 || stage === 2
        ? userDetailsValidationSchema
        : stage === 1
        ? otpValidationSchema
        : stage === 3 && requestType === 'SEATING'
        ? seatingValidationSchema
        : stage === 3 && requestType === 'RESERVATION'
        ? reservationValidationSchema
        : null,
    onSubmit: async (value) => {
      var formData = {
        country_code: value.country_code,
        mobile_number: value.mobile_number,
        first_name: value.first_name,
        last_name: value.last_name,
        email: value.email,
      };

      if (sessionStorage.getItem('userDetails')) {
        let userData = JSON.parse(sessionStorage.getItem('userDetails'));
        formData.country_code = userData?.country_code || '';
        formData.mobile_number = userData?.mobile_number || '';
        formData.first_name = userData?.first_name || '';
        formData.last_name = userData?.last_name || '';
        formData.email = userData?.email || '';
      }

      if (
        (stage === 2 && prevMobile == value?.mobile_number) ||
        (stage === 2 && prevCountryCode == value?.country_code)
      ) {
        setStage(1);
      } else if (stage === 1) {
        otpExecuteAPI(
          `${URL.OTP_AUTHENTICATION}?user_id=${responseData?.user_id}&OTP=${value?.otp}`,
          'GET'
        );
      } else if (stage === 0) {
        await executeAPI(`${URL.OTP_AUTHENTICATION}`, 'POST', formData);
      } else if (stage === 3) {
        let data = {
          vendor_id: restaurantData?.vendorId || restaurantData?.vendor_id?._id,
          kids_count: value.kids ? value.kids : 0,
          adult_count: value.adults ? value.adults : 0,
          handicap_chair: value.handicap ? value.handicap : 0,
          high_chair: value.highchair ? value.highchair : 0,

          seating_preference: value.seating_preference,
          occasion: value.occasion.replace(/[\r\n]+/gm, ' '),
          user_details: {
            country_code: formData.country_code,
            mobile_number: formData.mobile_number,
            name: formData.first_name + ' ' + formData.last_name,
          },
          service_type: requestType,
          same_party_size: false,
          pointOfEntry: 'USER',
          table_number: '',
          noLocationData: true,
        };
        if (requestType === 'RESERVATION') {
          let date = value?.reservationDate;
          let time = value?.reservationTime;
          let dateTime = moment(`${date} ${time}`).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          let timestamp = moment(dateTime).format('x');
          data = {
            ...data,
            reservationTime: timestamp,
          };
        }
        await dinerExecuteAPI(`${URL.DINER}`, 'POST', data);
      }
    },
  });

  useEffect(() => {
    if (otpResponseData?._id && otpResponseData?.accessToken) {
      clearTimer(getDeadTime());
      setStage(3);
      sessionStorage.setItem('accessToken', otpResponseData?.accessToken);
      sessionStorage.setItem('userDetails', JSON.stringify(otpResponseData));
    }
  }, [otpResponseData]);

  useEffect(() => {
    if (dinerResponseData?._id) {
      navigate(
        `/restaurant/${restaurantId}/seating/track/${dinerResponseData?._id}`,
        {
          state: { data: dinerResponseData, restData: restaurantData },
          replace: true,
        }
      );
    }
  }, [dinerResponseData]);

  useEffect(() => {
    if (responseData?.user_id && responseData?.smsResponse) {
      clearTimer(getDeadTime());
      setStage(1);
    }
    if (!responseData?.userExists) {
      if (sessionStorage.getItem('BroserId')) {
        const params = {
          deviceType: '',
          page: AnalyticPage.REGISTER_WEBAPP,
          deviceId: sessionStorage.getItem('BroserId'),
          app: 'WEB_APP',
        };
        if (pageStatsresponseData !== 'Captured') {
          pageStatsexecuteAPI(
            `${URL.PAGESTATS}?restaurantId=${restaurantId}`,
            'POST',
            params
          );
        }
      }
    }
  }, [responseData]);

  useEffect(() => {
    if (sessionStorage.getItem('accessToken')) {
      setStage(3);
    }
  }, []);

  const { values, handleChange, handleSubmit, errors, touched, setFieldValue } =
    formik;

  const nextStage = () => {
    handleSubmit();
  };

  const handleNumber = (val) => {
    userDetailsExecuteAPI(
      `${URL.USERDETAILS}?mobile_number=${val}&country_code=${values?.country_code}`,
      'GET'
    );
    if (userDetailsResponseData) {
      setFieldValue('first_name', userDetailsResponseData.first_name);
      setFieldValue('last_name', userDetailsResponseData.last_name);
      setFieldValue('email', userDetailsResponseData.email);
    }
  };

  useEffect(() => {
    if (userDetailsResponseData) {
      setFieldValue('first_name', userDetailsResponseData.first_name);
      setFieldValue('last_name', userDetailsResponseData.last_name);
      setFieldValue('email', userDetailsResponseData.email);
    }
  }, [userDetailsResponseData]);

  const Ref = React.useRef(null);
  const [timer, setTimer] = useState('00');
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(seconds > 9 ? seconds : '0' + seconds);
    }
  };
  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const editScreen = (e) => {
    setStage(2);
    setPrevMobile(values?.mobile_number);
    setPrevCountryCode(values?.country_code);
  };

  if (stage !== 3)
    return (
      <div style={{ backgroundColor: '#867F7F' }}>
        <Dialog open={true}>
          <DialogTitle>
            <div className="f18-500 font-family">
              {stage === 0 || stage === 2
                ? 'Enter your Information'
                : 'Enter OTP'}
            </div>
          </DialogTitle>
          <DialogContent>
            {stage === 0 || stage === 2 ? (
              <div>
                <div></div>
                <div className="my-2">
                  <div className="row">
                    <div className="col-3 pr-0">
                      <div className="input-phone-country-code-reusable w-100 select-input">
                        <select
                          className="form-control"
                          title="Country code"
                          name="country_code"
                          onChange={handleChange}
                          value={values.country_code}
                        >
                          <option value={values.country_code}>
                            {values.country_code}
                          </option>
                          {getCountries().map((country) => (
                            <option
                              key={country}
                              value={'+' + getCountryCallingCode(country)}
                            >
                              {en[country]} (+
                              {getCountryCallingCode(country)})
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors?.country_code && touched?.country_code && (
                        <div className="text-danger">
                          {errors?.country_code}
                        </div>
                      )}
                    </div>
                    <div className="col-9">
                      <TextField
                        id="mobile_number"
                        label="Phone Number"
                        variant="outlined"
                        size="small"
                        className="input"
                        type="number"
                        value={values?.mobile_number}
                        inputProps={{
                          maxLength: 10,
                          inputMode: 'numeric',
                        }}
                        onChange={(e) => {
                          if (e.target.value.length <= e.target.maxLength) {
                            handleChange(e);
                            if (e.target.value.length === 10) {
                              handleNumber(e.target.value);
                            }
                          }
                        }}
                        fullWidth
                        //  onBlur={values?.mobile_number.length === 10 ? handleNumber : null}
                        error={errors?.mobile_number && touched?.mobile_number}
                      />
                      {errors?.mobile_number && touched?.mobile_number && (
                        <div className="text-danger f9-500">
                          {errors?.mobile_number}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 py-2">
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    className="input"
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    fullWidth
                    error={errors?.email && touched?.email}
                  />
                  {errors?.email && touched?.email && (
                    <div className="text-danger f9-500">{errors?.email}</div>
                  )}
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <TextField
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      className="input"
                      value={values.first_name}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z]+$/;
                        if (regex.test(e.target.value)) {
                          handleChange(e);
                        } else if (!e.target.value) {
                          handleChange(e);
                        }
                      }}
                      fullWidth
                      error={errors?.first_name && touched?.first_name}
                    />
                    {errors?.first_name && touched?.first_name && (
                      <div className="text-danger f9-500">
                        {errors?.first_name}
                      </div>
                    )}
                  </div>
                  <div className="col-6">
                    <TextField
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      fullWidth
                      className="input"
                      value={values.last_name}
                      onChange={(e) => {
                        const regex = /^[a-zA-Z]+$/;
                        if (regex.test(e.target.value)) {
                          handleChange(e);
                        } else if (!e.target.value) {
                          handleChange(e);
                        }
                      }}
                      error={errors?.last_name && touched?.last_name}
                    />
                    {errors?.last_name && touched?.last_name && (
                      <div className="text-danger f9-500">
                        {errors?.last_name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="py-2 f12-500 my-2">
                  You will receive a OTP to verify your mobile number
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Button
                    onClick={goBack}
                    variant="outlined"
                    style={{
                      borderRadius: '15px',
                      color: 'black',
                      border: '1px solid #C4C4C4',
                      textTransform: 'capitalize',
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={nextStage}
                    variant="contained"
                    style={{
                      borderRadius: '15px',
                      backgroundColor: 'rgb(252, 90, 71) !important',
                      textTransform: 'capitalize',
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : stage === 1 ? (
              <div className="text-center">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="f12-500">
                    OTP has been sent to {values?.mobile_number}
                  </div>
                  <div
                    className="text-primary ml-2 f12-500"
                    onClick={() => {
                      editScreen();
                    }}
                  >
                    Edit
                  </div>
                </div>
                <div className="my-2 text-secondary f12-400">
                  One time password
                </div>
                <OTPInput
                  inputStyle={'send-otp-input-filed'}
                  separator={<span className="mr-2"></span>}
                  value={values?.otp}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: 'otp',
                        value: e,
                      },
                    });
                  }}
                  numInputs={6}
                />
                {otpError && !otpResponseData ? (
                  <div className="f12-500 mt-2 text-danger">
                    {otpError?.response?.data?.message}
                  </div>
                ) : null}

                <div className="col-12 d-flex justify-content-center f12-500 mt-3">
                  <div>00:{timer?.toString() || '00'}</div>
                  &nbsp;|&nbsp;
                  <div
                    className={
                      timer !== '00' ? 'text-disabled' : 'text-primary'
                    }
                    onClick={() => {
                      if (timer == '00') {
                        executeAPI(`${URL.OTP_AUTHENTICATION}`, 'POST', values);
                      }
                    }}
                  >
                    Resend OTP
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    className="btn btn-danger px-5"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
          </DialogContent>
        </Dialog>
      </div>
    );
  else
    return (
      <SeatingRequest
        backButton={() =>
          sessionStorage.getItem('accessToken')
            ? navigate(`/restaurant/${restaurantId}`)
            : editScreen()
        }
        noCost
        formik={formik}
        restaurantData={restaurantData}
        alert={dinerError?.response?.data?.message}
        variant={'error'}
        requestType={requestType}
        restaurantId={restaurantId}
        navigate={navigate}
      />
    );
};

export default SeatingUserInfo;
