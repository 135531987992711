import React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import WaitListDetails from "./WaitListDetails";
import QRCode from "react-qr-code";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrackScreenQr = ({ open, handleCloseQr, data }) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseQr}
      TransitionComponent={Transition}
      className="hidescrollbar"
    >
      <div className="hidescrollbar">
        <div className="d-flex justify-content-end pr-4 my-3">
          {" "}
          <CloseIcon onClick={handleCloseQr} />
        </div>
        <div
          className="f16-700 mt-4 mb-4 text-center"
          style={{ color: "black" }}
        >
          {data?.restaurantDetails?.name}{" "}
        </div>

        <div className="my-4 flexCenter">
          <QRCode
            value={JSON.stringify({
              diner_id: data?.diner_id,
              name: data?.user_details?.name,
            })}
            size={140}
          />
        </div>
        <div className="m-2">
          <WaitListDetails data={data} />
        </div>
        <div className="m-2">
          <div className="f14-700">Terms & Conditions</div>
          <div className="f12-400 text-grey">
            <br />
            1. QR Code" refers to the unique two-dimensional barcode generated
            by us for the purpose of identifying the Customer who has placed the
            request. <br />
            <br /> 2. Once the request is accepted by the Merchant, the QR Code
            is generated and can be viewed in the Upcoming Seating requests.
            <br />
            <br /> 3. When the Customer arrives at the restaurant, he/ she will
            show the QR Code to the merchant, who shall then scan the QR Code to
            confirm the booking request. <br />
            <br />
            4. When the QR Code is validated, the Customer will be provided the
            seat and the User App status will change to 'seated.' <br />
            <br />
            5. Once the status is changed to 'seated' the Customer cannot make
            cancellation request.
            <br />
            <br /> 6. The Customer shall be responsible for keeping the QR code
            confidential in order to prevent fraudulent usage.
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TrackScreenQr;
