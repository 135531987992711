import React, { useState, useRef } from "react";

const OTPInput = ({ value, onChange }) => {
  const inputRef = useRef([]);
  const [focus, setFocus] = useState(false);
  const [otp, setOTP] = useState(value);

  const handleOnChange = (e, index) => {
    const val = e.target.value;
    if (isNaN(val)) {
      return;
    }
    const newOTP = [...otp.slice(0, index), val, ...otp.slice(index + 1)];
    setOTP(newOTP.join(""));
    if (val && index < inputRef.current.length - 1) {
      inputRef.current[index + 1].focus();
    }
    if (onChange) {
      onChange(newOTP.join(""));
    }
  };

  const handleOnKeyDown = (e, index) => {
    if (e.keyCode === 8 && !otp[index] && index > 0) {
      const newOTP = [...otp.slice(0, index - 1), "", ...otp.slice(index)];
      setOTP(newOTP.join(""));
      inputRef.current[index - 1].focus();
      if (onChange) {
        onChange(newOTP.join(""));
      }
    }
  };

  const handleOnFocus = () => {
    setFocus(true);
  };

  const handleOnBlur = () => {
    setFocus(false);
  };

  return (
    <div className="otp-input">
      {Array(6)
        .fill("")
        .map((_, index) => (
          <div className="pr-2">
            <input
              key={index}
              type="text"
              maxLength="1"
              value={otp[index]}
              onChange={(e) => handleOnChange(e, index)}
              onKeyDown={(e) => handleOnKeyDown(e, index)}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              inputMode="numeric"
              ref={(ref) => (inputRef.current[index] = ref)}
              className={`otp-input__box ${
                focus && index === otp.length ? "active" : ""
              }`}
            />
          </div>
        ))}
    </div>
  );
};

export default OTPInput;
