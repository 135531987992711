import moment from 'moment';
import React, { useState } from 'react';
import {
  NEW_PAYMENT_TYPE,
  ORDER_STATUS,
  PAYMENT_STATUS,
} from '../../common/enums/OrderEnums';
import { Image } from 'react-bootstrap';
import { NOTAVAILABLEIMAGE } from '../../common/enums/GlobalEnums';
import { ArrowDropDown } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, Drawer } from '@mui/material';

function ExpTrackDetails({ data, fontsize, open, setOpen }) {
  const [showcharges, setShowcharges] = useState(false);
  const [showTax, setShowtax] = useState(false);
  const [showsurCharges, SetShowcharges] = useState(false);
  const [selected, setselected] = useState(1);
  const [reason, setReason] = useState('');

  const handleCancel = () => {
    setOpen(false);
  };

  const handleProceed = () => {
    setOpen(false);
  };

  return (
    <div className="px-3">
      <div className="waitlist-card my-3">
        <div className="f16-700 my-3">Experience Details</div>
        <div className="row mx-0">
          <div className="col">
            <div className="my-2">
              <div className="f14-700">
                <div>₹ {data?.paymentData?.totalAmount.toFixed(2) || 0}</div>
              </div>
              <div className="f12-700 opacity-50">Total Amount</div>
            </div>
            <div className="my-2">
              <div className="f14-700">
                {data?.seatingPreference?.[0] || 'N/A'}
              </div>
              <div className="f12-500 opacity-50">Seatig preference</div>
            </div>
            <div className="my-2">
              <div className="f14-700">{'N/A'}</div>
              <div className="f12-500 opacity-50">special Occasion</div>
            </div>

            <div className="my-2">
              <div className="f14-700">
                {moment(data?.updatedAt).format('DD MMM YYYY')}
              </div>
              <div className="f12-500 opacity-50">Last change date</div>
            </div>
          </div>
          <div className="col">
            <div className="my-2">
              <div className="flex-between">
                <div className="f14-700">
                  {PAYMENT_STATUS[data?.paymentStatus]}
                </div>
              </div>
              <div className="f12-500 opacity-50">Payment status </div>
            </div>
            <div className="my-2">
              <div className="f14-700 nowrap">
                {NEW_PAYMENT_TYPE[data?.paymentData?.paymentMethod]}
              </div>
              <div className="f12-500 opacity-50 ">payment Method</div>
            </div>
            <div className="my-2">
              <div className="f14-700 nowrap">
                {(data?.adultCount || 0) +
                  (data?.kidsCount || 0) +
                  data?.package?.count *
                    (data?.package?.adults + data?.package?.kids)}
              </div>
              <div className="f12-500 opacity-50 ">party size</div>
            </div>

            <div className="my-2">
              <div className="f14-700">
                {moment(data?.updatedAt).format('hh:mm A')}
              </div>
              <div className="f12-500 opacity-50">Last Change Time</div>
            </div>
          </div>
        </div>
        {data?.order_status === 0 ? (
          <div className="flexCenter">
            <div className="my-2 text-center p-3" onClick={() => setOpen(true)}>
              <CancelOutlinedIcon
                className="color-indigo  shadow p-1"
                sx={{ borderRadius: '50%', fontSize: 28 }}
              />
              <div className="f12-500">cancel</div>
            </div>
          </div>
        ) : null}

        <div className="my-4">
          {data?.order_items?.map((item) => (
            <div>
              <div className="row m-0">
                <div className="col-2 p-1 flexCenter">
                  <Image
                    src={item?.item?.images?.[0] || NOTAVAILABLEIMAGE}
                    alt="Image"
                    width="60px"
                    height="40px"
                    style={{
                      borderRadius: '12px',
                      maxHeight: '100px',
                      maxWidth: '100%',
                      objectFit: 'cover',
                    }}
                    className="text-center"
                  />
                </div>
                <div className="col-10 p-2 d-flex align-items-center justify-content-between px-2">
                  <div className={` ${fontsize ? 'f12-700' : 'f12-700'} my-2"`}>
                    {item?.item?.name} x {item?.quantity}
                  </div>

                  <div className="f14-700 my-2 px-2 nowrap">
                    <div>₹ {item?.size?.price || 0}</div>
                    <div> </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!showcharges && (
          <div
            style={{ color: '#2196f3' }}
            className="f14-700"
            onClick={() => setShowcharges(true)}
          >
            View More
          </div>
        )}

        <div>
          {showcharges ? (
            <>
              <div className="p-3 f14-700">
                <div className="f16-700 my-3">Order Summary</div>
                <div className="flex-between my-2">
                  <div>Sub Total</div>
                  <div>₹{data?.paymentData?.amount}</div>
                </div>
                <div className="my-2">
                  <div className="flex-between">
                    <div onClick={() => setShowtax(!showTax)}>
                      {' '}
                      {showTax ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                      Taxes
                    </div>
                    <div>
                      ₹{parseFloat(data?.taxesTotal || 0).toFixed(2) || 0}
                    </div>
                  </div>
                  {console.log(data.taxes)}
                  {showTax && (
                    <div className="pl-4 py-1 f14-500">
                      {data?.taxes
                        ? Object.entries(data?.taxes).map(([key, value]) => (
                            <div className="flex-between" key={key}>
                              <div>{key}</div>
                              <div>₹{value.toFixed(2)}</div>
                            </div>
                          ))
                        : null}
                    </div>
                  )}
                </div>
                <div className="my-2">
                  <div className="flex-between">
                    <div onClick={() => SetShowcharges(!showsurCharges)}>
                      {' '}
                      {showsurCharges ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                      Surcharges
                    </div>
                    <div>
                      ₹{parseFloat(data?.surChargesTotal || 0).toFixed(2) || 0}
                    </div>
                  </div>
                  {showsurCharges && (
                    <div className="pl-4 py-1 f14-500">
                      {data?.surCharges
                        ? Object.entries(data?.surCharges).map(
                            ([key, value]) => (
                              <div className="flex-between" key={key}>
                                <div>{key}</div>
                                <div>₹{value}</div>
                              </div>
                            )
                          )
                        : null}
                    </div>
                  )}
                </div>
                {data?.paymentData?.donationAmount ? (
                  <div className="my-2">
                    <div className="flex-between">
                      <div>Donation</div>
                      <div>
                        ₹
                        {parseFloat(
                          data?.paymentData?.donationAmount || 0
                        ).toFixed(2) || 0}
                      </div>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className="flex-between f16-700">
                  <div> Total Amount Paid</div>
                  <div>₹{data?.paymentData?.totalAmount || 0}</div>
                </div>
                <hr />
              </div>
              <div
                style={{ color: '#2196f3' }}
                onClick={() => setShowcharges(false)}
                className="f14-700"
              >
                View Less
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div>
        <Drawer anchor={'bottom'} open={open} sx={{ zIndex: '1400' }}>
          <div className="p-3">
            <div className="f18-700"> Reason for Cancellation</div>
            <div className="f14-500 text-grey mb-3">
              Select your reaso for cancellation
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 1 ? 'border-active' : ''
              }`}
              onClick={() => setselected(1)}
            >
              Not Available
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 2 ? 'border-active' : ''
              }`}
              onClick={() => setselected(2)}
            >
              Don't Like the place
            </div>
            <div
              className={`f16-700 shadow p-3 border-radius my-3 ${
                selected === 3 ? 'border-active' : ''
              }`}
              onClick={() => setselected(3)}
            >
              Other
            </div>
          </div>
          <hr />
          <Box
            sx={{ display: 'flex', justifyContent: 'space-evenly' }}
            my={1}
            px={2}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={handleCancel}
              style={{
                borderRadius: '15px',
                color: 'black',
                border: '1px solid #C4C4C4',
                textTransform: 'capitalize',
              }}
              className="bg-white mr-2"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{
                borderRadius: '15px',
                border: '1px solid #C4C4C4',
                color: 'white',
                backgroundColor: '#fc5a47',
                textTransform: 'capitalize',
              }}
              // disabled={!(tableNumber || paxNum || paxNumber)}
              fullWidth
              onClick={handleProceed}
            >
              Submit
            </Button>
          </Box>
        </Drawer>
      </div>
    </div>
  );
}

export default ExpTrackDetails;
