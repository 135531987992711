import React from "react";
import { Image } from "react-bootstrap";

const PaxCard = ({
  noCost,
  adultsPaxIncrease,
  kidsPaxIncrease,
  adultsPaxDecrease,
  kidsPaxDecrease,
  values,
}) => {
  return (
    <div className="container">
      {/* <div className="f14-500">No. of people allowed per ticket : 4</div> */}
      <div className="mt-3">
        <div className="paxCard py-2 px-1">
          <div className="row m-0 align-items-center">
            <div className="col-6 p-0 py-2 border-right">
              <div className="row m-0 align-items-center">
                <div className="col-3 p-0">
                  <div className="rounded-circle bg-white p-2">
                    <Image
                      src={require("../../assets/images/IconsandImages/Group 90253.png")}
                      width={"100%"}
                    />
                  </div>
                  {/*  */}
                </div>
                <div className="col-9 p-0">
                  <div className="ml-2">
                    <div className="f16-700">Adult</div>
                    {!noCost && (
                      <div className="f14-500">
                        ₹ 1000/<span className="f12-500">per person</span>
                      </div>
                    )}
                    <div className="paxButton row align-items-center justify-content-between m-0 px-2">
                      <div
                        className="col-4 f16-500 p-0"
                        onClick={() => adultsPaxDecrease()}
                      >
                        -
                      </div>
                      <div className="col-4 f16-500 p-0 text-center">
                        {values?.adults}
                      </div>
                      <div
                        className="col-4 f16-500 p-0 row justify-content-end m-0"
                        onClick={() => adultsPaxIncrease()}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 p-0 py-2">
              <div className="row m-0 px-1 align-items-center">
                <div className="col-3 p-0">
                  <div className="rounded-circle bg-white p-2">
                    <Image
                      src={require("../../assets/images/IconsandImages/kids.png")}
                      width={"100%"}
                    />
                  </div>
                </div>
                <div className="col-9 p-0">
                  <div className="ml-2">
                    <div className="f16-700">Kids</div>
                    {!noCost && (
                      <div className="f14-500">
                        ₹ 1000/<span className="f12-500">per person</span>
                      </div>
                    )}
                    <div className="paxButton row align-items-center justify-content-between m-0 px-2">
                      <div
                        className="col-4 f16-500 p-0"
                        onClick={() => kidsPaxDecrease()}
                      >
                        -
                      </div>
                      <div className="col-4 f16-500 p-0 text-center">
                        {values?.kids}
                      </div>
                      <div
                        className="col-4 f16-500 p-0 row justify-content-end m-0"
                        onClick={() => kidsPaxIncrease()}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaxCard;

export const PaxCardDynamic = ({
  subHeading,
  titleLeft,
  valueLeft,
  imageLeft,
  paxDecreaseLeft,
  paxIncreaseLeft,
  titleRight,
  valueRight,
  imageRight,
  paxDecreaseRight,
  paxIncreaseRight,
  left,
  right,
}) => {
  return (
    <div className="container">
      <div className="f14-500">{subHeading}</div>
      <div className="mt-3">
        <div className="paxCard py-2 px-1">
          <div className="row m-0 align-items-center">
            {left ? (
              <div className="col-6 p-0 py-2 border-right">
                <div className="row m-0 align-items-center">
                  <div className="col-3 p-0">
                    <div
                      className="rounded-circle bg-white"
                      style={{ padding: ".60rem" }}
                    >
                      <Image src={imageLeft} width={"100%"} />
                    </div>
                    {/*  */}
                  </div>
                  <div className="col-9 p-0">
                    <div className="ml-2">
                      <div className="f16-700">{titleLeft}</div>
                      <div className="paxButton row align-items-center justify-content-between m-0 px-2">
                        <div
                          className="col-4 f16-500 p-0"
                          onClick={() => paxDecreaseLeft()}
                        >
                          -
                        </div>
                        <div className="col-4 f16-500 p-0 text-center">
                          {valueLeft}
                        </div>
                        <div
                          className="col-4 f16-500 p-0 row justify-content-end m-0"
                          onClick={() => paxIncreaseLeft()}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {right ? (
              <div className="col-6 p-0 py-2">
                <div className="row m-0 align-items-center">
                  <div className="col-3 p-0">
                    <div
                      className="rounded-circle bg-white"
                      style={{ padding: ".60rem" }}
                    >
                      <center>
                        <Image src={imageRight} width={"80%"} />
                      </center>
                    </div>
                    {/*  */}
                  </div>
                  <div className="col-9 p-0">
                    <div className="ml-2">
                      <div className="f16-700">{titleRight}</div>
                      <div className="paxButton row align-items-center justify-content-between m-0 px-2">
                        <div
                          className="col-4 f16-500 p-0"
                          onClick={() => paxDecreaseRight()}
                        >
                          -
                        </div>
                        <div className="col-4 f16-500 p-0 text-center">
                          {valueRight}
                        </div>
                        <div
                          className="col-4 f16-500 p-0 row justify-content-end m-0"
                          onClick={() => paxIncreaseRight()}
                        >
                          +
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
