import React from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

function CartBar({ intialOrderCall, setshowOtpModal, setdineInSkip, summary }) {
  const { ID } = useParams();

  const navigate = useNavigate();

  const cartData = JSON.parse(sessionStorage.getItem('cartItems')) || [];

  const orderType = sessionStorage.getItem('cartOrderType');
  const skipStatus = sessionStorage.getItem('DineInSkip');
  const CurSideSkipStatus = sessionStorage.getItem('CurbSideSkip');

  const path = window.location.pathname;

  const totalSum = cartData.reduce((sum, currentObject) => {
    return sum + currentObject.finalPrice * currentObject.quantity;
  }, 0);
  const totalItems = cartData.reduce((sum, currentObject) => {
    return sum + currentObject.quantity;
  }, 0);

  const viewcart = () => {
    const isCartPage = path.includes('cartpage');
    const hasAccessToken = sessionStorage.getItem('accessToken');

    if (isCartPage) {
      if (hasAccessToken) {
        if (orderType === 'dine_in' && skipStatus === 'skipped') {
          setdineInSkip(true);
        } else if (
          orderType === 'curb_side' &&
          CurSideSkipStatus === 'skipped'
        ) {
          navigate(`/restaurant/${ID}/curbside-details`);
        } else if (intialOrderCall) {
          intialOrderCall();
        }
      } else if (setshowOtpModal) {
        setshowOtpModal(true);
      }
    } else {
      navigate(`/restaurant/${ID}/cartpage`);
    }
  };

  return (
    <div className="cartbar f16-700">
      <div className="d-flex align-items-center my-2">
        <div className="px-1" style={{ borderRight: '2px solid white' }}>
          ₹ {path.includes('cartpage') ? summary?.total || 0 : totalSum}
          {path.includes('cartpage') ? (
            <p className="f10-500 m-0">plus Taxes</p>
          ) : null}
        </div>
        <div className="px-1">
          {totalItems}
          <span className="px-1"> {totalItems > 1 ? 'Items' : 'Item'}</span>
        </div>
      </div>
      <div
        onClick={viewcart}
        className="flexCenter"
        style={{ cursor: 'pointer' }}
      >
        <Image
          src={require('../../assets/images/ordermodalassets/cart_bag.png')}
          width={20}
          alt="logo"
          className="mx-2"
        />
        {path.includes('cartpage') ? 'Check Out' : 'View Cart'}
      </div>
    </div>
  );
}

export default CartBar;
