import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import TitleSection from '../ReusableComponent/TitleSection';
import MenuCard from '../ReusableComponent/menuCard';
import { Image } from 'react-bootstrap';
import { CircularProgress, Button } from '@mui/material';
import { Link } from 'react-scroll';
import { MenuFilterSection } from './MenuFilterSection';
import { SearchWithFilterAndSort } from '../experience/SearchWithFilterAndSort';
import { AnalyticPage, ITEM_TAGS } from '../../common/enums/GlobalEnums';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import { MenuCardLarge } from '../ReusableComponent/menuCard';
import { FullcardView } from '../ReusableComponent/menuCard';
import CloseIcon from '@mui/icons-material/Close';
import DownloadBar from '../ReusableComponent/downloadar';
import VideoCard from './VideoCard';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import OrderTypes from './OrderTypes';
import CartBar from '../ReusableComponent/CartBar';
import DineInModal from '../ordering/modals/dineInModal';
import DineInDrawer from '../ordering/modals/DineInDrawer';
import NowLaterBar from './NowLaterBar';
import UserRegistration from '../ReusableComponent/UserRegistration';
import PriceAlert from '../ordering/modals/PriceAlert';

const ViewMenu = (props) => {
  const { ID } = useParams();
  const navigate = useNavigate();
  const [ShowSearch, setshowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [itemTag, setItemTag] = useState('All');
  const [vegOnlyFilter, setVegOnlyFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [payloadData, setpayloadData] = useState(null);
  const default_order_type = sessionStorage.getItem('defaultOrderType');
  const DineInSkip = sessionStorage.getItem('DineInSkip');
  const CurbSideSkip = sessionStorage.getItem('CurbSideSkip');
  const [defaultorderType, setDefaultordertype] = useState(default_order_type);
  const [dineInskip, setdineInSkip] = useState(false);
  const storedItemsFromSession = sessionStorage.getItem('cartItems');
  const [storedItems, setStoredItems] = useState(
    storedItemsFromSession ? JSON.parse(storedItemsFromSession) : []
  );
  const [PriceAlertModal, setPriceAlertModal] = useState(false);
  const [state, setState] = useState({
    isLoading: true,
    menutData: undefined,
    restData: undefined,
    restId: ID,
    loadingMenu: true,
  });

  // useEffect(() => {
  //   sessionStorage.setItem(
  //     'defaultOrderType',
  //     sessionStorage.getItem('mainDefaultOrderType')
  //   );
  // }, []);

  const { isLoading, restId } = state;
  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  // eslint-disable-next-line
  const [
    cancelMenu,
    responseDataMenu,
    errorMenu,
    loadedMenu,
    resetMenu,
    executeMenuAPI,
  ] = useAxios();

  useEffect(() => {
    if (restId !== undefined && restId !== null) {
      executeAPI(
        `${URL.RESTAURANT_DETAILS}/${restId}?lat=true&long=true`,
        'GET',
        null
      );
    }

    if (!DineInSkip) {
      sessionStorage.setItem('DineInSkip', 'intial');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //api response handler for get rest details
  useEffect(() => {
    if (loaded) {
      if (responseData != null) {
        //do any preprocessing if required using preprocessor.js functions
        setState((prevState) => ({
          ...prevState,
          restData: responseData,
          isLoading: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
      reset(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, responseData]);

  useEffect(() => {
    if (storedItems.length)
      sessionStorage.setItem('cartOrderType', storedItems[0].selectedOrder);
  }, [storedItems]);

  useEffect(() => {
    if (state?.restData != null) {
      executeMenuAPI(
        `${URL.RESTAURANT_ITEMS}?vendor_id=${state?.restData?.vendorId}&orderType=${defaultorderType}`,
        'GET',
        null
      );
    }
    if (!defaultorderType && state?.restData) {
      if (state?.restData?.orderSessionSettings?.dine_in_order) {
        sessionStorage.setItem('defaultOrderType', 'dine_in');
        sessionStorage.setItem('mainDefaultOrderType', 'dine_in');
        setDefaultordertype('dine_in');
      } else if (state?.restData?.orderSessionSettings?.curb_side) {
        sessionStorage.setItem('defaultOrderType', 'curb_side');
        sessionStorage.setItem('mainDefaultOrderType', 'curb_side');
        setDefaultordertype('curb_side');
      } else {
        sessionStorage.setItem('defaultOrderType', 'take_away');
        sessionStorage.setItem('mainDefaultOrderType', 'take_away');
        setDefaultordertype('take_away');
      }
    }
  }, [state?.restData, defaultorderType]);

  useEffect(() => {
    if (loadedMenu) {
      if (responseDataMenu != null) {
        setState((prevState) => ({
          ...prevState,
          menutData: responseDataMenu,
          loadingMenu: false,
        }));
      } else if (error !== null) {
        setState((prevState) => ({
          ...prevState,
          loadingMenu: false,
        }));
      }
      reset(); //this is required to make any further api call to get rest details
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMenu, responseDataMenu]);

  // eslint-disable-next-line
  const [
    filterCancel,
    filterResponseData,
    filterError,
    filterLoaded,
    filterReset,
    filterExecuteAPI,
  ] = useAxios();

  const applyFilter = (filterState) => {
    filterState.vendor_id = state?.restData?.vendorId;
    filterState.sortBy = +filterState.sortBy;
    if (restId !== undefined && restId !== null) {
      filterExecuteAPI(`${URL.MENU_FILTER}?filter=true`, 'POST', filterState);
    }
    setpayloadData(filterState);
  };
  useEffect(() => {
    if (filterResponseData?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        menutData: filterResponseData,
        loadingMenu: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        menutData: [],
      }));
    }
  }, [filterResponseData]);

  useEffect(() => {
    if (defaultorderType === 'curb_side' && CurbSideSkip === 'intial') {
      navigate(`/restaurant/${restId}/curbside-details`);
    }
  }, [defaultorderType]);

  useEffect(() => {
    setdineInSkip(default_order_type === 'dine_in' && DineInSkip === 'intial');
  }, [default_order_type]);
  const handleClose = () => {
    setOpen(false);
  };

  const openCatModal = () => {
    setOpen(true);
  };
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    applyFilter({ search: e.target.value });
  };

  const SearchFun = () => {
    if (ShowSearch === true) {
      setState((prevState) => ({
        ...prevState,
        menutData: responseDataMenu,
      }));
      // setState(({...state,menutData:responseDataMenu}))
      setSearch('');
    }
    setshowSearch(!ShowSearch);
  };

  let filterdata = state.menutData;
  if (itemTag) {
    if (itemTag === 'All') {
      filterdata = state.menutData;
    } else {
      filterdata = filterdata.filter((tag) => {
        const filteritems = tag.items.filter(
          (item) => item.primary_tag[0]?.title === itemTag
        );
        if (filteritems.length > 0) {
          return true;
        }
        return false;
      });
    }
  }

  const filteredDataArray = filterdata?.flatMap((menu) => {
    if (menu.items) {
      return menu.items.filter(
        (item) => item.videos && item.videos.length > 0 && item.videos[0] !== ''
      );
    }
    return [];
  });

  useEffect(() => {
    if (vegOnlyFilter) {
      applyFilter({ vegOnly: true });
    } else {
      setState((prevState) => ({
        ...prevState,
        menutData: responseDataMenu,
      }));
    }
  }, [vegOnlyFilter]);

  const [currentVideoUrl, setCurrentVideoUrl] = useState(null);

  const handleVideoClick = (url) => {
    if (currentVideoUrl === url) {
      // If the clicked video is already playing, pause it
      setCurrentVideoUrl(null);
    } else {
      // Set the new video to play and pause the previously playing video
      setCurrentVideoUrl(url);
    }
  };

  const [viewType, setViewType] = useState(filteredDataArray?.length ? 1 : 1);

  useEffect(() => {
    if (filteredDataArray?.length) {
      setViewType(1);
    } else {
      setViewType(1);
    }
  }, [filteredDataArray?.length]);
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [showDineInModal, setshowDineInModal] = useState(false);
  const openPriceAlert = () => {
    setPriceAlertModal(true);
  };
  const [proceedStatus, setProceedStatus] = useState(false);

  const closePriceAlert = (status) => {
    setProceedStatus(status);
    setPriceAlertModal(false);
  };

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: 'center', width: '100%', height: '100%' }}>
          <CircularProgress sx={{ marginTop: '20%' }} />
        </div>
      ) : (
        <div>
          <div className="floatingBtn">
            <Image
              src={require('../../assets/images/IconsandImages/categoriesbtn.png')}
              alt="icon"
              width="132px"
              style={{ maxWidth: '100%' }}
              onClick={() => setOpen(true)}
            />
          </div>

          <TitleSection
            bg={'#FFFFFF'}
            title={state?.restData?.restaurant_name}
            backRoute={`/restaurant/${ID}`}
            className={'box-shadow-down'}
            resticon={state?.restData?.logo_url_thumbnails}
            icon={require('../../assets/images/IconsandImages/search.png')}
            onclicksearch={SearchFun}
          />
          {ShowSearch && (
            <div className="p-4">
              <SearchWithFilterAndSort
                onChange={onChangeSearch}
                search={search}
                placeHolderText={'Search by items'}
                clearbtn={true}
                onclicksearch={SearchFun}
              />
            </div>
          )}

          {showOtpModal ? (
            <UserRegistration
              open={showOtpModal}
              setshowOtpModal={(val) => setshowOtpModal(val)}
              //  setshowDineInModal={(val) => setshowDineInModal(val)}
            />
          ) : null}

          {PriceAlertModal ? (
            <PriceAlert
              closePriceAlert={closePriceAlert}
              openModal={PriceAlertModal}
            />
          ) : null}
          {/* {showDineInModal ? (
            <DineInModal
              open={showDineInModal}
              setshowDineInModal={(val) => setshowDineInModal(val)}
            />
          ) : null} */}
          <div className="f14-500 p-2 mt-2">
            <OrderTypes
              defaultorderType={defaultorderType}
              setDefaultordertype={(e) => setDefaultordertype(e)}
              restData={state?.restData}
              openPriceAlert={openPriceAlert}
              proceedStatus={proceedStatus}
              storedItems={storedItems}
            />
            <NowLaterBar />
            <HorizontalScrollMenu
              data={ITEM_TAGS}
              onClick={(e) => setItemTag(e)}
              selected={itemTag}
            />
          </div>
          <hr className="greyhr" />
          <div id="filter">
            <MenuFilterSection
              vegOnly={vegOnlyFilter}
              vegonlyfun={setVegOnlyFilter}
              payloadData={payloadData}
              restData={state?.restData}
              applyFilter={applyFilter}
            />
          </div>
          <hr className="greyhr" />
          <div className="d-flex justify-content-between align-items-center px-3">
            <div>
              <div className="f18-700">Browse by Category</div>
            </div>
            <div className="d-flex">
              <div className="mx-2" onClick={() => setViewType(1)}>
                <Image
                  alt="Filter Icon"
                  src={require(`../../assets/images/IconsandImages/${
                    viewType === 1 ? 'listviewon' : 'listviewoff'
                  }.png`)}
                  width={18}
                />
              </div>
              <div className="mx-2" onClick={() => setViewType(2)}>
                <Image
                  alt="Icon"
                  src={require(`../../assets/images/IconsandImages/${
                    viewType === 2 ? 'cardviewon' : 'cardviewoff'
                  }.png`)}
                  width={18}
                />
              </div>
              {filteredDataArray?.length > 0 ? (
                <div className="mx-2" onClick={() => setViewType(3)}>
                  <PlayCircleOutlineIcon
                    style={{
                      width: '24px',
                      color: `${viewType === 3 ? '#ee3a23' : 'grey'}`,
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {filteredDataArray?.length > 0 && viewType === 3 && (
            <div className="video-container my-4" id="video-container">
              {filteredDataArray?.map((list, i) => (
                <VideoCard
                  key={i}
                  url={list.videos[0]}
                  imageurl={list.images[0]}
                  item={list}
                  id={i}
                  isPlaying={currentVideoUrl === list.videos[0]}
                  onVideoClick={() => handleVideoClick(list.videos[0])}
                />
              ))}
            </div>
          )}

          {filterdata?.map((menu) => {
            return (
              <Accordion
                className="my-1"
                key={menu?._id}
                defaultExpanded={true}
                id={menu?.name}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="my-1 mx-3 p-0"
                >
                  <div className="f18-700">
                    {menu?.name}
                    {'   '}{' '}
                    <span className="f12-700">
                      ({menu?.items?.length} items)
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {menu?.items?.map((items) => {
                    const display = filteredDataArray.some(
                      (item) => item._id === items._id
                    );
                    return (
                      <>
                        {viewType === 1 || viewType === 3 ? (
                          <MenuCard
                            viewType={viewType}
                            display={display}
                            item={items}
                            key={items?._id}
                            storedItems={storedItems}
                            setStoredItems={setStoredItems}
                            orderSelected={defaultorderType}
                            restData={state?.restData}
                            setPriceAlertModal={setPriceAlertModal}
                          />
                        ) : (
                          <MenuCardLarge item={items} key={items?._id} />
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
          <Dialog open={open} onClose={handleClose}>
            <div>
              {filterdata?.map((cat) => {
                return (
                  <div className="m-4 f16-700" key={cat?._id}>
                    <Link
                      activeClass="active"
                      to={cat.name || 'filter'}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={handleClose}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div> {cat.name} </div>
                        <div className="f12-700 mx-1">
                          {' '}
                          ({cat?.items?.length} items)
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
              <div className="flexCenter my-3">
                {' '}
                <CloseIcon
                  style={{ border: '0.5px solid black', borderRadius: '50%' }}
                  onClick={handleClose}
                />
              </div>
            </div>
          </Dialog>
          <DineInDrawer open={dineInskip} setOpen={setdineInSkip} />
          <div className="px-3 mb-4" style={{ paddingBottom: '80px' }}>
            <div>
              <Image
                src={require('../../assets/images/IconsandImages/fssai.png')}
                height="40px"
                width="70px"
              />
            </div>
            <div className="f12-400 greycolor">{`Lic. No.${
              state.restData.fssai_code.length != 0
                ? state.restData.fssai_code
                : 'No Available'
            } `}</div>
          </div>

          <div
            style={{
              backgroundColor: 'white',
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: '100',
            }}
          >
            <div className="row w-100 m-0">
              <div className="col-12 col-md-6 col-lg-4 p-0">
                {/* <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/Union 88.png')}
                    style={{ width: '100%' }}
                  />
                </div> */}
                <div>
                  {/* <DownloadBar
                    text="Online ordering is only available on Amealio app"
                    buttonText={'Download App'}
                    title=" "
                    noImage
                  /> */}
                  {storedItems.length ? (
                    <CartBar setshowOtpModal={setshowOtpModal} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewMenu;

export const HorizontalScrollMenu = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick('');
    } else {
      if (onClick) onClick(e);
    }
  };
  return (
    <div className="d-flex overflow-scroll hidescrollbar">
      {data?.map((e) => (
        <div className="p-1" key={e?.title}>
          <div
            onClick={() => onClicked(e?.title)}
            className={`py-2 px-4 f14-400 badge-item text-center py-1 ${
              selected === e?.title ? 'badge-item-selected' : null
            }`}
            style={{
              backgroundColor: e?.code || 'none',
              color: e?.code ? 'black' : 'none',
            }}
          >
            {e?.title || ''}
          </div>
        </div>
      ))}
    </div>
  );
};
