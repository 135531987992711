import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

function PriceAlert({ open, setOpenModal, addItemsAfterPriceAlertAccept }) {
  const { ID } = useParams();
  const navigate = useNavigate();

  const handleYes = () => {
    setOpenModal(false);
    addItemsAfterPriceAlertAccept();
  };

  return (
    <div>
      <Modal show={open} centered>
        <div className="f20-700 px-2 py-2">
          Prices and Availability may vary according to the order type.
        </div>
        <div className="f14-400 px-2 py-2">
          This may affect your total order price. Are you sure you want to
          confirm the changes?
        </div>
        <DialogContent sx={{ textAlign: 'center', p: 0 }}>
          <div className="d-flex justify-content-between p-2">
            <button
              className="btn btn-outline-secondary px-1"
              style={{ minWidth: '49%', borderRadius: '12px' }}
              onClick={() => setOpenModal(false)}
            >
              No
            </button>
            <button
              className="btn btn-danger px-1"
              style={{ minWidth: '49%', borderRadius: '12px' }}
              onClick={() => handleYes()}
            >
              Yes
            </button>
          </div>
        </DialogContent>
      </Modal>
    </div>
  );
}

export default PriceAlert;
