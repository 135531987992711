import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { assets } from '../../../assets/assets';
import CloseIcon from '@mui/icons-material/Close';

function OfferSuccessPopup(props) {
  return (
    <div>
      <Dialog
        open={props?.isVisible}
        aria-labelledby="order-success-dialog-title"
        aria-describedby="order-success-dialog-description"
      >
        <DialogTitle
          id="order-success-dialog-title"
          sx={{ textAlign: 'center' }}
          className="my-0"
        >
          <div>
            <Image
              src={require('../../../assets/images/IconsandImages/rightSuccess.png')}
              width={48}
              className="mx-2"
              //style={{ marginTop: '-40px' }}
            />
          </div>
          <div className="text-end" style={{ marginTop: '-50px' }}>
            <CloseIcon onClick={props?.cancelTrigger} />
          </div>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center', pX: 4, my: 0, mx: 4 }}>
          <div className=" p-1">
            <div
              className="f16-700 text-center my-3"
              style={{ color: assets.colors.primary }}
            >
              Hurray!
            </div>
            <div className="f18-700 text-center my-2">{props?.promoCode}</div>
            <div className="f18-700 text-center my-2">
              is successfully applied!
            </div>
            <div className="f14-700 text-center my-4">Enjoy Your Moments!</div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OfferSuccessPopup;
