import { Image } from "react-bootstrap";
import { TopTitle } from "../experience/TopTitle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PaxCard, { PaxCardDynamic } from "./PaxCard";
import { Switch, TextField } from "@mui/material";
import NotificationAlert from "../ReusableComponent/Alert";
import DatePicker from "./DatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import { URL } from "../../common/api/urls";
import { useAxios } from "../../common/api/hooks/useAxios";
import { HorizontalScrollMenuTimings } from "../experience/HorizontalScrollMenu";
import { rest } from "@feathersjs/client";

export default function SeatingRequest({
  backButton,
  noCost,
  formik,
  restaurantData,
  alert,
  variant,
  requestType,
  restaurantId,
  navigate,
}) {
  const { values, handleChange, handleSubmit, errors, touched } = formik;

  const restData = JSON.parse(sessionStorage.getItem("restaurantData"));

  // eslint-disable-next-line
  const [
    activeReqCancel,
    activeReqResponseData,
    activeReqError,
    activeReqLoaded,
    activeReqReset,
    activeReqExecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (requestType === "SEATING" && sessionStorage.getItem("accessToken")) {
      activeReqExecuteAPI(
        `${URL.USER_DINER}?vendor_id=${
          JSON.parse(sessionStorage.getItem("restaurantData"))?.vendorId
        }`,
        "GET",
        undefined,
        {
          Authorization: sessionStorage.getItem("accessToken"),
        }
      );
    }
  }, [requestType]);
  
  useEffect(() => {
    if (activeReqResponseData && !sessionStorage.getItem("TrackScreen")) {
      if (activeReqResponseData?.status && activeReqResponseData?.data?._id) {
        navigate(
          `/restaurant/${restaurantId}/seating/track/${activeReqResponseData?.data?._id}`
        );
      }
    } else if (
      activeReqResponseData &&
      sessionStorage.getItem("TrackScreen")
    ) {
      sessionStorage.removeItem("TrackScreen");
      navigate(`/restaurant/${restaurantId}`);
    }
  }, [activeReqResponseData]);

  const increase = (key) => {
    handleChange({
      target: {
        name: key,
        value: values[key] + 1,
      },
    });
  };
  const decrease = (key) => {
    if (values[key] != 0) {
      handleChange({
        target: {
          name: key,
          value: values[key] - 1,
        },
      });
    }
  };

  // eslint-disable-next-line
  const [
    timingsCancel,
    timingsResponseData,
    timingsError,
    timingsLoaded,
    timingsReset,
    timingsExecuteAPI,
  ] = useAxios();
  const [subscriptionData, setSubscriptionData] = useState();

  const handleDateChange = (e) => {
    formik.setFieldValue(
      "reservationDate",
      moment(e?.$d?.toISOString()).format("YYYY-MM-DD")
    );
    timingsExecuteAPI(
      `${URL.RESTAURANT_AVAILABILITY}/${restaurantId}?date=${new Date(
        moment(e?.$d?.toISOString())
      ).getTime()}`
    );
  };

  useEffect(() => {
    if (timingsResponseData?.length) {
      formik.setFieldValue("reservationTime", "");
      formik.setFieldValue("timings", timingsResponseData);
    }
  }, [timingsResponseData]);

  useEffect(() => {
    const _subscriptionData = restaurantData?.subscription?.fast_food_dining
      ? restaurantData?.subscription?.fast_food_dining_status
      : restaurantData?.subscription?.casual_dining
      ? restaurantData?.subscription?.casual_dining_status
      : restaurantData?.subscription?.multi_service_business
      ? restaurantData?.subscription?.multi_service_business_status
      : {};
    setSubscriptionData(_subscriptionData);
    timingsExecuteAPI(
      `${
        URL.RESTAURANT_AVAILABILITY
      }/${restaurantId}?date=${new Date().getTime()}`
    );
  }, []);

  const onChangeTimings = (e) => {
    formik.setFieldValue("reservationTime", e?.toString());
  };

  useEffect(() => {
    if (restData) {
      if (!restData.openStatus?.open && requestType === "SEATING") {
        navigate(`/restaurant/${restaurantId}`);
      }
    }
  }, [restData]);

  return (
    <div className="seating" style={{ marginBottom: "8rem" }}>
      <NotificationAlert alert={alert} variant={variant} />
      <TopTitle
        title={restaurantData?.restaurant_name || ""}
        subTitle={
          requestType === "SEATING" ? restaurantData?.seatingWaitingTime : ""
        }
        subTitleWithIcon={
          requestType === "SEATING" &&
          require("../../assets/images/IconsandImages/waittime.png")
        }
        backButton={backButton}
        mainIcon={restaurantData?.logo_url_thumbnails}
      />
      {requestType === "RESERVATION" ? (
        <div className="container py-4" style={{ backgroundColor: "#D9DEFF" }}>
          <div className="f16-700 mb-2">Select Date</div>
          <DatePicker
            minDate={moment()}
            maxDate={moment(new Date(), "DD-MM-YYYY").add(
              "days",
              subscriptionData?.seating?.reservation?.max_day_ahead
            )}
            handleAccept={handleDateChange}
          />
          <div className="f16-700 mb-2 mt-3">Select Time</div>
          {values?.timings.length > 0 && values?.timings[0] === "closed" ? (
            <div className="f10-400 mb-2">
              Restaurant is closed today. Select next available time slot.
            </div>
          ) : (
            <div className="f10-400 mb-2">
              {values?.timings.length > 0 && values?.timings[0] !== "closed"
                ? `The displayed time slots depend upon the availability of the restaurant.`
                : "There are no time slots available for today. Select a different date for available time slots"}
            </div>
          )}
          {values?.timings?.length > 0 && values?.timings?.[0] !== "closed" ? (
            <HorizontalScrollMenuTimings
              data={values?.timings}
              selected={values?.reservationTime}
              onClick={onChangeTimings}
            />
          ) : null}
          {errors?.reservationTime && touched?.reservationTime ? (
            <div className="text-danger f12-500">{errors?.reservationTime}</div>
          ) : null}
        </div>
      ) : null}

      <div
        className="p-4 m-0"
        style={{
          backgroundColor:
            requestType === "RESERVATION" ? "inherit" : "#D9DEFF",
        }}
      >
        <div className="d-flex align-items-center f16-700">
          <Image
            src={require("../../assets/images/IconsandImages/celebration-cap.png")}
            width={30}
          />

          <div className="ml-2">Party Size</div>
        </div>
        <div className="f12-500 d-flex align-items-center mt-2">
          <InfoOutlinedIcon style={{ height: "15px", color: "#5C5C5C" }} />
          The kids ranging below 6 years will be kept under the kids category.
        </div>
      </div>
      {requestType === "RESERVATION" ? null : (
        <Image
          src={require("../../assets/images/IconsandImages/Union 88.png")}
          width={"100%"}
          style={{ marginTop: "-20px" }}
        />
      )}
      <PaxCard
        noCost={noCost}
        adultsPaxIncrease={(e) => increase("adults")}
        kidsPaxIncrease={(e) => increase("kids")}
        adultsPaxDecrease={(e) => decrease("adults")}
        kidsPaxDecrease={(e) => decrease("kids")}
        values={values}
      />
      {errors?.adults && touched?.adults ? (
        <div className="text-danger ml-3 f14-500">{errors?.adults}</div>
      ) : null}
      <hr />
      {(subscriptionData?.seating?.general_seating?.handicap ||
        subscriptionData?.seating?.general_seating?.highchair) && (
        <>
          <div className="container mt-3">
            <div className="f14-500">Need Handicap Access or a Highchair?</div>
            <div className="d-flex align-items-center">
              <div className="f14-400 mr-2">No</div>
              <Switch
                value={values?.HandicapSwitch}
                color="success"
                name="HandicapSwitch"
                onChange={handleChange}
              />
              <div className="f14-400 ml-2">Yes</div>
            </div>

            {(values?.HandicapSwitch &&
              subscriptionData?.seating?.general_seating?.handicap) ||
            (values?.HandicapSwitch &&
              subscriptionData?.seating?.general_seating?.highchair) ? (
              <div>
                <PaxCardDynamic
                  // subHeading={"Need Handicap access or a Highchair"}
                  titleLeft="Handicap"
                  valueLeft={values?.handicap}
                  paxDecreaseLeft={() => decrease("handicap")}
                  paxIncreaseLeft={() => {
                    if (
                      values?.adults + values?.kids >
                      values?.handicap + values?.highchair
                    )
                      increase("handicap");
                  }}
                  imageLeft={require("../../assets/images/IconsandImages/wheelchair.png")}
                  left={
                    values?.HandicapSwitch &&
                    subscriptionData?.seating?.general_seating?.handicap
                  }
                  titleRight="Highchair"
                  valueRight={values?.highchair}
                  imageRight={require("../../assets/images/IconsandImages/highchair.png")}
                  paxDecreaseRight={() => decrease("highchair")}
                  paxIncreaseRight={() => {
                    if (
                      values?.adults + values?.kids >
                      values?.handicap + values?.highchair
                    )
                      increase("highchair");
                  }}
                  right={
                    values?.HandicapSwitch &&
                    subscriptionData?.seating?.general_seating?.highchair
                  }
                />
              </div>
            ) : null}
          </div>
          <hr />
        </>
      )}
      {restaurantData?.seating?.length > 0 ? (
        <>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-1">
                <Image
                  src={require("../../assets/images/IconsandImages/chair.png")}
                  width="25px"
                />
              </div>
              <div className="col-11 f14-500">Seating Preference</div>
            </div>
            <div className="col-10 f12-400 mt-2">
              Your preference will be considered. Seating is a subject to
              availability.*
            </div>
            <div className="container mt-3">
              <div className="row align-items-center">
                {restaurantData?.seating?.map((pref) => (
                  <div className="col-4 mb-3" key={pref?._id}>
                    <div
                      key={pref?._id}
                      onClick={() =>
                        handleChange({
                          target: {
                            name: "seating_preference",
                            value: pref?.title,
                          },
                        })
                      }
                    >
                      <div
                        className={`${
                          pref?.title === values?.seating_preference &&
                          "select-button"
                        } f12-400 button px-2 py-1 text-center`}
                      >
                        {pref?.title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <hr />
        </>
      ) : null}

      <div className="container mt-3">
        <div className="f14-500">Special Occasion</div>
        <div className="mt-2">
          <TextField
            multiline
            rows={3}
            label=""
            name="occasion"
            value={values?.occasion}
            onChange={handleChange}
            placeholder="Write Here e.g. Wedding Anniversary"
            fullWidth
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: "100",
        }}
      >
        <div className="row w-100 m-0">
          <div className="col-12 col-md-6 col-lg-4 p-0">
            <div className="d-flex justify-content-between m-4">
              <button
                className="btn btn-outline-secondary btn-lg"
                style={{ minWidth: "150px", borderRadius: "18px" }}
                onClick={backButton}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger btn-lg"
                style={{ minWidth: "150px", borderRadius: "18px" }}
                onClick={handleSubmit}
                type={"submit"}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
