import SearchIcon from "@mui/icons-material/Search";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import TuneIcon from "@mui/icons-material/Tune";

export const SearchWithFilterAndSort = ({
  onChange,
  search,
  placeHolderText,
  micIcon,
  clearbtn,
  onclicksearch,
}) => {
  return (
    <div className="row align-items-center">
      <div className="col-12 p-0">
        <div className="search d-flex align-items-center">
          <span className="input-group-left">
            <SearchIcon />
          </span>
          <input
            className="form-control"
            id="search"
            placeholder={placeHolderText}
            onChange={onChange}
            value={search}
          />
          {clearbtn && (
             <span  onClick={() => onclicksearch()} className="input-group-right">
             <span  style={{color:"#4848bc"}}>Close</span>
           </span>

          )}
          {micIcon && (
            <span className="input-group-right">
              <MicNoneIcon />
            </span>
          )}
        </div>
      </div>
      {/* <div className="col-2 p-2">
        <div className="d-flex align-items-center icon-color justify-content-between">
          <div className="border-right">
            <SwapVertIcon />
          </div>
          <TuneIcon />
        </div>
      </div> */}
    </div>
  );
};
