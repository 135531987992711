import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Accordion from "@mui/material/Accordion";
import moment from "moment";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TimingsAvailability = (props) => {
  const [showView, setShowView] = useState(false);
  const handleCloseView = () => setShowView(false);
  const handleShowView = () => {
    setShowView(true);
  };
  const sessions = (dayData) => {
    const timings = props?.exp?.timings;
    let day = timings[dayData];
    if (day?.open) {
      return day?.multipleHours?.map((e) => {
        return (
          <>
            <div className="col-6">
              <div className="f12-500 opacity5 px-3">{e?.description}</div>
              <div className="f12-700 py-2 grey-text px-3">
                {moment(e?.openingTime).format("LT")}
                {"-"}
                {moment(e?.closingTime).format("LT")}
              </div>
            </div>
          </>
        );
      });
    } else {
      return (
        <>
          <div className="f12-500 grey-text ml-4">closed</div>
        </>
      );
    }
  };

  return (
    <>
      <Button
        variant="button"
        onClick={() => handleShowView()}
        className={props.btnStyle}
      >
        <div className="f10-500 text-danger">Check Availability</div>
      </Button>
      <Modal
        show={showView}
        onHide={() => handleCloseView()}
        centered
        size="md"
        backdrop="static"
        keyboard="false"
      >
        <Modal.Header className="border-0 px-md-4" closeButton>
          <Modal.Title className="f24-700">
            Experience Date and Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="exp-name-id">
            <div className="f14-500 bg-grey my-4 py-3 px-2">
              Experience ID : <span> #{props?.exp?.expId}</span>
            </div>
            <div
              className="f14-500 bg-grey my-4 py-3 px-2"
              style={{ border: "1px solid #acacac", borderRadius: "10px" }}
            >
              {props?.exp?.name}
            </div>
          </div>
          <div>
            {props?.exp?.timings?.days?.map((e) => {
              const timings = props?.exp?.timings;
              let day = timings[e];
              return (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={day?.open ? <ExpandMoreIcon /> : <></>}
                      disabled={!day?.open}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex justify-content-between w-100">
                        <div className="f14-500">{e}</div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="row w-100">{sessions(e)}</div>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TimingsAvailability;
