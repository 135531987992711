import React from 'react';
import { Image } from 'react-bootstrap';
import { screenSetup } from './ScreenSetup';
import { isEmpty } from '../../common/utility/helperFunc';

const PackageDetails = ({ data }) => {
  return (
    <div className="p-2">
      <div className="mb-4">
        <div className="ml-2 f18-500">Discounted Group Bookings</div>
      </div>
      {data?.packages?.map((_package, index) => {
        return (
          <div
            className="pl-4 py-2 package-pass-card"
            style={{
              backgroundImage: `url('${
                screenSetup[data?.expType]?.PACKAGE?.BACKGROUND
              }')`,
            }}
            key={_package?.name + index}
          >
            <div className="row">
              <div className="col-9">
                <div className="f12-500">{_package?.name || ''}</div>
                <div className="f10-400">
                  No. of people allowed per package :{' '}
                  {_package?.adults + ' Adults'},{' '}
                  {_package?.kids > 0 ? _package?.kids + ' Kids' : ''}
                </div>
                <div className="mt-2 d-flex align-items-center justify-content-center">
                  <Image
                    src={require('../../assets/images/ExperiencePageAssets/family.png')}
                    width="40"
                    alt={'Package'}
                    title={'Package'}
                  />
                  <div className="ml-4">
                    {!isEmpty(_package?.discountedPrice) &&
                      _package?.discountedPrice !== 0 && (
                        <div className="f16-400 text-decoration-line-through">
                          ₹
                          {_package?.discountedPrice === 0
                            ? _package?.discountedPrice
                            : _package?.price}
                        </div>
                      )}
                    <div>
                      <span className="f16-500">
                        ₹
                        {!isEmpty(_package?.discountedPrice)
                          ? _package?.discountedPrice === 0
                            ? _package?.price
                            : _package?.discountedPrice
                          : _package?.price}
                      </span>
                      <span className="f12-400"> / per package</span>
                    </div>
                  </div>
                </div>
              </div>
              {screenSetup[data?.expType]?.AVAILABLE_BOOKED && (
                <>
                  <div
                    className="col-1 p-0"
                    style={{ maxHeight: '110px', width: '10px' }}
                  >
                    <Image
                      src={require('../../assets/images/ExperiencePageAssets/indigo-divider.png')}
                      height={'100%'}
                    />
                  </div>
                  <div className="col-3 p-0 text-center d-flex justify-content-center align-items-center">
                    <div>
                      <div className="f9-500 text-indigo">
                        Available Packages
                      </div>
                      <div className="f12-500">
                        {data?.seatsLeft || 0}{' '}
                        <span className="f9-400">Left</span>
                      </div>
                      <div className="f9-500 text-indigo mt-2">
                        Booked Packages
                      </div>
                      <div className="f12-500">
                        {data?.seatsBooked || 0}{' '}
                        <span className="f9-400">Booked</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PackageDetails;
