import React from "react";
import { Image } from "react-bootstrap";

const FoodSection = (props) => {
  return (
    <div className="my-2 food-section px-2">
      <div className="f20-700 py-4">Food Type</div>
      <div className="d-flex justify-content-even py-2 overflow-scroll hidescrollbar">
        {props?.restdata?.selected_food_type ? 
         props?.restdata?.selected_food_type.map((icon) => {
          return(
            <div className="mx-4 text-center" key={icon?._id}>
            <Image
              src={icon?.icon}
              alt="Image"
              width="30px"
              
            />
            <div className="f12-500 d-flex py-2 nowrap" >{icon?.title} </div>
          </div>
          )
         }) : null}
      </div>
      <hr className="mx-2 opacity-25" />
      {props?.restdata?.serve_liquor && (
        <>
          <div className="f20-700 py-4">Liquor</div>
          <div className="d-flex justify-content-even overflow-scoll mx-0 my-2 hidescrollbar">
            {" "}
            {props?.restdata?.selected_liquor_category.length &&
              props?.restdata?.selected_liquor_category.map((data) => {
                return (
                  <div className="col-3 text-center">
                    <Image
                      src={data?.icon}
                      alt="Image"
                      width="50px"
                      fluid
                      className="rounndborder"
                    />
                    <div className="f12-500 my-2 nowrap">{data?.title} </div>
                  </div>
                );
              })}
               <hr className="mx-2 opacity-25" />
          </div>
        </>
      )}

     
      <div className="my-2">
        <div className="d-flex">
          <div className="px-3">
            <Image
              src={require(`../../assets/images/IconsandImages/${
                props?.restdata?.allergic_information
                  ? "greenright.png"
                  : "greyline.png"
              }`)}
              alt="Image"
              width="14px"
            />
          </div>
          <div className="my-2">
            <div className="f14-700">Allergy Info</div>
            <div className="f12-500">
              {props?.restdata?.allergic_information &&
                props?.restdata?.allergicInfoText}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="px-3">
            <Image
              src={require(`../../assets/images/IconsandImages/${
                props?.restdata?.nutrition_information
                  ? "greenright.png"
                  : "greyline.png"
              }`)}
              alt="Image"
              width="14px"
            />
          </div>
          <div className="my-2">
            <div className="f14-700">Nutrition Info</div>
            <div className="f12-500">
              {props?.restdata?.nutrition_information &&
                props?.restdata?.nutritionInfoText}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="px-3">
            <Image
              src={require(`../../assets/images/IconsandImages/${
                props?.restdata?.personalization_information
                  ? "greenright.png"
                  : "greyline.png"
              }`)}
              alt="Image"
              width="14px"
            />
          </div>
          <div className="my-2">
            <div className="f14-700">Personalization</div>
            <div className="f12-500">
              {props?.restdata?.personalization_information &&
                props?.restdata?.personalization_information_description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FoodSection;
