import { Image, Table } from "react-bootstrap";

const ReuseCountPriceEdit = (props) => {
  const { count, onClickEdit, totalPrice, displayEdit } = props;
  return (
    <div className="reuse_count_price_edit d-flex">
      <div className="d-flex direction_row align-items-center">
        <div className="pr-2 f14-400 " style={{ color: "#ACACAC" }}>
          {count}
        </div>
        {displayEdit ? (
          <button className="shareAndHeart" onClick={onClickEdit}>
            <Image
              src={require("../../assets/images/IconsandImages/pencilGrey.png")}
              width={12}
            />
          </button>
        ) : (
          <div></div>
        )}
      </div>
      <div className="f20-400">{`₹ ${totalPrice}`}</div>
    </div>
  );
};
export default ReuseCountPriceEdit;
