import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Image } from "react-bootstrap";
import MenuFilter from "./MenuFilter";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MenuDialog({ applyFilter,payloadData, ...props }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="mr-2 d-flex" onClick={handleClickOpen}>
        <Image
          alt="Filter Icon"
          title="Filter Icon"
          src={require("../../assets/images/IconsandImages/filter.png")}
          width={18}
        />
        <div className="f14-500 mx-2">Filters</div>
      </div>
      <Dialog
        fullScreen={fullScreen}
       // maxWidth={"md"}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <MenuFilter
          handleClose={handleClose}
          payloadData={payloadData} 
          applyFilter={(e) => {
            applyFilter(e);
            handleClose();
          }}
          {...props}
        />
      </Dialog>
    </div>
  );
}
