import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CAR_COLORS,
  ORDER_TYPES,
  ORDER_TYPES_MAPPING,
} from "../../../common/enums/OrderEnums";
import { Image } from "react-bootstrap";

const vechileTypes = [
  {
    id: 4,
    title: "4-wheeler",
    icon: require("../../../assets/images/ordericon/car.png"),
  },
  {
    id: 2,
    title: "2-wheeler",
    icon: require("../../../assets/images/ordericon/scooter.png"),
  },
  {
    id: 3,
    title: "3-wheeler",
    icon: require("../../../assets/images/ordericon/rikshaw.png"),
  },
  {
    id: 0,
    title: "Others",
    icon: require("../../../assets/images/ordericon/taxi.png"),
  },
];

function DineInModal(props) {
  const navigate = useNavigate();
  const [selectedTable, setSelectedTable] = useState("");
  const [selectedPax, setSelectedPax] = useState(0);
  const [activeVechile, setActiveVechile] = useState(4);

  const restData = JSON.parse(sessionStorage.getItem("restaurantData"));
  const UserData = JSON.parse(sessionStorage.getItem("userDetails"));
  const defaultOrderType = sessionStorage.getItem("defaultOrderType");
  const subscriptionData = restData?.subscription?.casual_dining
    ? restData?.subscription?.casual_dining_status
    : restData?.subscription?.fast_food_dining
    ? restData?.subscription?.fast_food_dining_status
    : restData?.subscription?.multi_service_business
    ? restData?.subscription?.multi_service_business_status
    : {};
  const maxPaxValue = subscriptionData?.ordering?.dine_in_order?.maximum_person;
  const pickupPointData =
    subscriptionData?.ordering?.cubside_delivery?.pickup_location;
  const [initialValues, setInitialValues] = useState({
    user_details: {
      first_name: UserData?.first_name,
      last_name: UserData?.last_name,
      country_code: UserData?.country_code,
      mobile_number: UserData?.mobile_number,
    },
    order_type: ORDER_TYPES.indexOf(defaultOrderType),
    orderSelected: defaultOrderType,
    now: true,
    nowDate: new Date(),
    later_date: "",
    later_time: "",
    actual_date: "",
    later_formDated_date: "",
    delivery_partner: 1,
    dine_in_details: {
      table_number: selectedTable,
    },
    totalDinePax: selectedPax,
    curb_side_details: {
      vehical_type: 4,
      vehical_type_value: "",
      number: "",
      make: "",
      model: "",
      year: "",
      color: "",
      pickUpPoint: null,
    },
  });

  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  };

  const handlePaxChange = (event) => {
    const value = event.target.value;
    setSelectedPax(value);
  };

  const handlePaxButtonRangeClick = (value) => {
    setSelectedPax(value);

    setInitialValues((prevValues) => ({
      ...prevValues,
      totalDinePax: value,
    }));
  };
  const handleVechileChange = (selectedVechile) => {
    setActiveVechile(selectedVechile.id);
    setInitialValues((prevValues) => ({
      ...prevValues,
      curb_side_details: {
        ...prevValues.curb_side_details,
        vehical_type: selectedVechile.id,
        vehical_type_value: selectedVechile.title,
      },
    }));
  };

  const handleSkip = () => {
    props?.setshowDineInModal(false);
    // sessionStorage.setItem("order_details", JSON.stringify(initialValues));
    navigate(`/restaurant/${restData._id}/viewmenu`);
  };

  const [errors, setErrors] = useState("");

  const handleSubmit = () => {
    let next = false;
    setErrors(false);
    if (initialValues.order_type === ORDER_TYPES_MAPPING.DINEIN) {
      if (
        initialValues.dine_in_details.table_number ||
        initialValues.totalDinePax
      ) {
        next = true;
      } else {
        setErrors("Required Fields missing.");
      }
    } else if (initialValues.order_type === ORDER_TYPES_MAPPING.CURBSIDE) {
      if (
        initialValues.curb_side_details.vehical_type_value &&
        initialValues.curb_side_details.number &&
        initialValues.curb_side_details.make &&
        initialValues.curb_side_details.model &&
        initialValues.curb_side_details.year &&
        initialValues.curb_side_details.color &&
        initialValues.curb_side_details.pickUpPoint
      ) {
        next = true;
      } else {
        setErrors("Required Fields missing.");
      }
    } else {
      next = true;
    }
    if (next) {
      initialValues["proceed"] = true;
      sessionStorage.setItem("order_details", JSON.stringify(initialValues));
      props?.setshowDineInModal(false);
      // navigate(`/restaurant/${restData._id}/viewmenu`);
    }
  };

  return (
    <div>
      <Dialog open={props?.open}>
        <DialogTitle mt={2}>
          {defaultOrderType === "dine_in"
            ? "Enter Table Number"
            : defaultOrderType === "take_away"
            ? "Please select the option for your Take Away order"
            : defaultOrderType === "curb_side"
            ? "Vehicle Information"
            : defaultOrderType === "skip_line"
            ? "Please select the option for your Skip the line order"
            : null}
        </DialogTitle>
        <DialogContent>
          {defaultOrderType === "dine_in" && (
            <>
              <Box>
                <div className="f12-500">
                  Enter table or Seat No available at you table
                </div>
                <div className="my-3">
                  <TextField
                    label="Table /Seat Number"
                    value={selectedTable}
                    onChange={(e) => setSelectedTable(e.target.value)}
                    sx={{ width: "100%" }}
                  />
                </div>
              </Box>

              <Typography fontSize={18} fontWeight={500} color="initial">
                {" Party Size"}
              </Typography>
              <div className="f12-500">
                Please enter the number of Guests dining in.
              </div>
              <Box my={2}>
                {[1, 2, 3, 4, 5, 6].map((value) => (
                  <Button
                    key={value}
                    onClick={() => handlePaxButtonRangeClick(value)}
                    variant={selectedPax === value ? "contained" : "outlined"}
                    color={selectedPax === value ? "error" : "info"}
                    sx={{ m: 1 }}
                  >
                    {value}
                  </Button>
                ))}
              </Box>
              <div className="f14-500 my-3"> Or </div>
              <TextField
                label="Enter Pax"
                type="number"
                value={selectedPax}
                onChange={handlePaxChange}
                inputProps={{ min: 1, max: 6 }}
                sx={{ width: "100%" }}
                error={selectedPax > maxPaxValue}
                helperText={
                  selectedPax > maxPaxValue
                    ? `Maximum party size is ${maxPaxValue}  PAX.`
                    : ""
                }
              />
              <div style={{ color: "red" }}>{errors}</div>
            </>
          )}
          {defaultOrderType === "take_away" && (
            <>
              <Box sx={{ my: 4 }}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "none" }}
                >
                  Now
                </Button>
              </Box>
            </>
          )}
          {defaultOrderType === "skip_line" && (
            <>
              <Box sx={{ my: 4 }}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "none" }}
                >
                  Now
                </Button>
              </Box>
            </>
          )}
          {defaultOrderType === "curb_side" && (
            <>
              <div>
                <div className="f12-500">
                  Please enter your vehicle number to get your order delivered
                  near by your vehicle. Do ensure that you are in or near by
                  your vehicle.
                </div>
                <div className="d-flex my-2">
                  {vechileTypes.map((vechile) => (
                    <div
                      onClick={() => handleVechileChange(vechile)}
                      className={`px-1`}
                      key={vechile.id}
                    >
                      <div
                        className={`${
                          activeVechile == vechile.id
                            ? "selected-rounded"
                            : null
                        }`}
                        style={{ borderRadius: "50%" }}
                      >
                        <Image
                          style={{ padding: "5px" }}
                          src={vechile.icon}
                          alt={vechile.icon}
                        />
                      </div>
                      <div className="f12-500">{vechile.title}</div>
                    </div>
                  ))}
                </div>
                <div className="my-2">
                  <TextField
                    label="Vehicle Number"
                    variant="outlined"
                    type="text"
                    className={`w-100`}
                    inputProps={{ maxLength: 13 }}
                    name="curb_side_details.number"
                    value={initialValues.curb_side_details.number}
                    onChange={(e) =>
                      setInitialValues((prevValues) => ({
                        ...prevValues,
                        curb_side_details: {
                          ...prevValues.curb_side_details,
                          number: e.target.value,
                        },
                      }))
                    }
                    helperText={"EX:AB 00 AB 0000"}
                  />
                </div>
                <div className="my-2">
                  <TextField
                    label="Brand"
                    variant="outlined"
                    className={`w-100`}
                    name="curb_side_details.make"
                    value={initialValues.curb_side_details.make}
                    onChange={(e) =>
                      setInitialValues((prevValues) => ({
                        ...prevValues,
                        curb_side_details: {
                          ...prevValues.curb_side_details,
                          make: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="my-2">
                  <FormControl variant="outlined" className={`w-100`}>
                    <InputLabel id="curb_side_details.color">Color</InputLabel>
                    <Select
                      id="curb_side_details.color"
                      label="Color"
                      labelId="curb_side_details.color"
                      name="curb_side_details.color"
                      value={initialValues.curb_side_details.color}
                      onChange={(e) => {
                        const newColor = e.target.value;
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          curb_side_details: {
                            ...prevValues.curb_side_details,
                            color: newColor,
                          },
                        }));
                      }}
                    >
                      {CAR_COLORS &&
                        CAR_COLORS.map((color) => (
                          <MenuItem value={`${color.value}`}>
                            {color.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <div className="f14-700 my-1">Select Pickup Location</div>
                  <div className="f12-500">
                    Please select a convenient pickup location where you will be
                    arriving
                  </div>
                  <div className="my-2">
                    <FormControl variant="outlined" className={`w-100`}>
                      <InputLabel id="curb_side_details.color">
                        Pickup Point
                      </InputLabel>
                      <Select
                        id="curb_side_details.pickup"
                        label="pickup"
                        labelId="curb_side_details.pickup"
                        name="curb_side_details.pickup"
                        value={
                          initialValues.curb_side_details.pickUpPoint?.name
                        }
                        onChange={(e) => {
                          const pickupPoint = e.target.value;
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            curb_side_details: {
                              ...prevValues.curb_side_details,
                              pickUpPoint: pickupPointData[pickupPoint],
                            },
                          }));
                        }}
                      >
                        {pickupPointData &&
                          pickupPointData.map((data, index) => (
                            <MenuItem key={index} value={index}>
                              {data.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div style={{ color: "red" }}>{errors}</div>
            </>
          )}
          <Box>
            <div className="d-flex justify-content-between align-items-center mt-3">
              {!props?.paxRequired && (
                <Button
                  onClick={handleSkip}
                  variant="outlined"
                  style={{
                    borderRadius: "15px",
                    color: "black",
                    border: "1px solid #C4C4C4",
                    textTransform: "capitalize",
                  }}
                  className="bg-white mr-2"
                  fullWidth
                >
                  Skip
                </Button>
              )}
              <Button
                onClick={handleSubmit}
                disabled={selectedPax > maxPaxValue}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgb(252, 90, 71) !important",
                  textTransform: "capitalize",
                }}
                className="bg-danger ml-2"
                fullWidth
              >
                Submit
              </Button>
              {/* )} */}
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DineInModal;
