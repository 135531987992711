import React from 'react';
import Divider from '@mui/material/Divider';

const DottedHr = () => {
  return (
    <Divider
      variant="middle"
      style={{
        backgroundColor: 'transparent',
        borderTop: '2px dashed  black',
      }}
    />
  );
};

export default DottedHr;
